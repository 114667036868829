import { genUUID } from "helpers/helpfulUtilities";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "helpers/firebase";

 const useUploadFileRequest = () => {
  const uploadFile = async (file) => {
    try {
      const storageRef = ref(storage, `uploads/${genUUID()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      return await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => { },
          (error) => {
            reject(error); // Reject the promise if there's an error during upload
          },
          async () => {
            // Upload completed successfully
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              console.log(url)
              resolve(url); // Resolve the promise with the file URL
            } catch (error) {
              reject(error); // Reject the promise if getting the URL fails
            }
          }
        );
      });
    } catch (error) {
      console.log(error);
      throw new Error("Error uploading file: " + error);
    }
  };
  return {
    uploadFile
  };
};

export default useUploadFileRequest;

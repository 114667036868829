import { useState, useEffect } from "react";
import { AuthContext } from "../contexts/Auth";
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import useUserRequest from "hooks/requests/useUserRequest";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";

function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentOrganisation, setCurrentOrganisation] = useState(null)
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authClaims, setAuthClaims] = useState(null);
  const apiRequest = useApiRequest();

  useEffect(() => {
    if (user) {
      fetchUserOrganisation()
    }
  }, [user])

  const fetchUserOrganisation = async () => {
    setLoading(true);
    try {
      const response = await apiRequest({
        method: 'get',
        url: `${BASE_URL}/api/user/organisation`,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      setCurrentOrganisation(response.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log(user)
      setCurrentUser(user);
      setUser(user);

      console.log('user status changed: ', user);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setUser(user);

      console.log('user status changed: ', user);
    });

    // Set up a token refresh interval
    const refreshInterval = setInterval(() => {
      if (currentUser) {
        refreshAccessToken(currentUser);
      }
    }, 20 * 60 * 1000); // 20 minutes in milliseconds

    return () => {
      clearInterval(refreshInterval);
      unsubscribe();
    };
  }, [currentUser]);

  const refreshAccessToken = async (user) => {
    try {
      const auth = getAuth();
      const idTokenResult = await user.getIdTokenResult();
      const refreshedTokenResult = await user.getIdTokenResult(true);
      const newClaims = refreshedTokenResult.claims;

      // Update the authClaims state with the new claims
      setAuthClaims(newClaims);

      console.log('ID token refreshed:', newClaims);
    } catch (error) {
      console.error('Error refreshing ID token:', error);
    }
  };

  const auth = getAuth();
  const logout = () => {
    localStorage.removeItem("onRefresh");
    signOut(auth);
  };

  const value = {
    loading,
    currentUser,
    authClaims,
    user,
    setUser,
    logout,
    setAuthClaims,
    setCurrentUser,
    setLoading,
    currentOrganisation
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

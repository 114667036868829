import { db } from "helpers/firebase";
import  { addDoc, collection } from "firebase/firestore"
import { now } from "moment";

export const ERROR_LOG="failed"
export const SUCCESS_LOG ="successful"

export const logAction = async (status, type, message, data=null) => {
    try {
        const timeCreated = new Date().toISOString().slice(0, 19).replace("T", " ");
        await addDoc(collection(db, "logs"), {
            timeCreated,
            type,
            status,
            message,
            createdAt:now(),
            data
        });
    } catch (error) {
        console.error("Error logging action:", error);
        // Handle the error here if needed.
    }
}

import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import { useState } from "react";

const useDependentsRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [dependentDetails, setDependentDetails] = useState(null);
    const { authClaims } = useAuth();
    const [pageSize, setPageSize] = useState(10)
    const [hasMore, setHasMore] = useState(true)
    const [nextCursor, setNextCursor] = useState(undefined)
    const [dependents, setDependents] = useState([])
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const [loadingMore, setLoadingMore] = useState(true)
    const [timeoutRef, setTimeoutRef] = useState(null);
    const [search, setSearch] = useState("");
    const [customerDependents, setCustomerDependents] = useState([])
    
    const getCustomerDependents = async(customerId)=>{
        setIsLoading(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/dependents/customer-dependents/${customerId}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setCustomerDependents(response.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }


    const searchDependents = (value) => {
        setDependents([]);
        setNextCursor(undefined)

        setSearch(value)
        // Clear the previous timeout
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        // Set a new timeout
        const newTimeout = setTimeout(() => {
            // Here, you can call your API with the search value (value)
            
            fetchDependents(value)
        }, 500);

        // Store the timeout reference
        setTimeoutRef(newTimeout);
    }

    const loadMore = async () => {
        setLoadingMore(true);
        fetchDependents()
    }

    const fetchDependents = async (query="") => {
        setLoadingMore(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/dependents?search=${query}&nextCursor=${nextCursor ? nextCursor : ''}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            if (query) {
                setDependents([...dependents, ...response.data.items]);
            }else{
                setDependents([...dependents, ...response.data.items]);
                setNextCursor(response.data.nextCursor)
            }
            if (!response.data.nextCursor) {
                setHasMore(false)
            }
            setIsLoading(false);
            setLoadingMore(false)
        } catch (error) {
            setIsLoading(false);
            setLoadingMore(false)
        }
    }


    const getDependentDetails = async(dependentId)=>{
        setLoadingDetails(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/dependents/details/${dependentId}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setDependentDetails(response.data)
           
            setLoadingDetails(false)
        } catch (error) {
            setLoadingDetails(false)
        }
    }

    return {
        getDependentDetails,
        loadingDetails,
        dependentDetails,
        customerDependents,
        isLoading,
        getCustomerDependents
    }
}

export default useDependentsRequest;
import { useState } from "react";
import { db as firestore } from "../../helpers/firebase";
import {
    getDocs,
    collection,
    query,
    where,
} from "firebase/firestore";
const usePolicyProductsRequest = () => {
    const [policyProducts, setPolicies] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const fetchPolicyProducts = async (name = "all") => {
        setIsLoading(true)
        try {
            let request = null;
            if (name == "all") {
                request = query(collection(firestore, "policy_products"));
            } else {
                request = query(collection(firestore, "policy_products"), where("policyName", "==", name));
            }
            const querySnapshot = await getDocs(request);
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id, // Include the document ID
                ...doc.data(), // Spread the document data
            }));
            setPolicies(data.sort((item) => item.createdAt))
            setIsLoading(false)
        } catch (error) {
            console.log("Error getting documents: ", error);
            setIsLoading(false)
        }
    };

    return {
        policyProducts, isLoading, fetchPolicyProducts
    }
}

export default usePolicyProductsRequest;
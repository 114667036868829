import axios, { AxiosError } from 'axios';
const useApiRequest = () => {
    const fetchData=(config)=>{
        return axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          const errorResponse = error;
          if (errorResponse.response?.request.status === 401) {
            // dispatch(sessionExpired("error"));
          }
          throw error;
        });
    }
    return fetchData;
};

export default useApiRequest;

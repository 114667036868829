import { faPaperclip, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomLoader from 'components/CustomLoader'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import PolicyModal from './PolicyModal';
import { toast } from 'react-toastify';
import useUploadFileRequest from 'hooks/uploadFileRequest';
import useApiRequest from 'hooks/apiRequest';
import { BASE_URL } from 'helpers/constants';
import { ERROR_LOG, SUCCESS_LOG, logAction } from 'helpers/utils/logsHelper';
import useAuth from 'contexts/Auth';
import { authentication } from 'helpers/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import useCustomerClaimRequest from 'hooks/requests/useClaimsRequest';
import { getFileNameFromUrl } from 'helpers/helpfulUtilities';
import { FaCheck } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';

export default function NewClaim() {
    const [isLoading, setIsLoading] = useState(false);
    const [policyModalOpen, setPolicyModalOpen] = useState(false);
    const [claimAttachments, setClaimAttachments] = useState([]);
    const [attachmentCount, setAttachmentCount] = useState(1);
    const [selectedPolicy, setSelectedPolicy] = useState(null)
    const [claimBeneficiaries, setClaimBeneficiaries] = useState([])
    const { uploadFile } = useUploadFileRequest()
    const apiRequest = useApiRequest();
    const { authClaims, currentUser } = useAuth();
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        amountOfClaim: null,
        dateOfClaim: null,
        description: "",
        medicalClaimDiagnosis: [],
        treatments: []
    });
    const { loadingDetails, getClaimDetails, claimDetails } = useCustomerClaimRequest()
    const { claimId } = useParams();
    useEffect(() => {
        if (claimId) {
            getClaimDetails(claimId);
        }
    }, [claimId])

    useEffect(() => {
        if (claimDetails) {
            console.log(claimDetails)
            setSelectedPolicy(claimDetails.Policy)
            setFormData({
                ...formData,
                amountOfClaim: claimDetails.amountOfClaim,
                dateOfClaim: claimDetails.dateOfClaim,
                description: claimDetails.description,
            })

            if (claimDetails.ClaimAttachment) {
                if (claimDetails.ClaimAttachment.length > 0) {
                    setAttachmentCount(claimDetails.ClaimAttachment.length)
                    for (let index = 0; index < claimDetails.ClaimAttachment.length; index++) {
                        const attachment = claimDetails.ClaimAttachment[index];
                        setClaimAttachments((prevAttachments) => {
                            const newAttachments = [...prevAttachments];
                            newAttachments[index] = { ...newAttachments[index], name: attachment.nameOfFile, filePath: attachment.filePath };
                            return newAttachments;
                        });
                    }
                }
            }
        }
    }, [claimDetails])


    const saveClaimDetails = async (data) => {
        setIsLoading(true);
        let url = BASE_URL + "/api/claims/create"
        if (claimId) {
            url = BASE_URL + "/api/claims/update/" + claimId
        }

        try {
            const response = await apiRequest({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setIsLoading(false);
            toast.success(`successfully ${claimId ? 'updated' : 'created'} a Claim`, { position: "top-center" });
            logAction(
                SUCCESS_LOG,
                "claim creation",
                `Successfully ${claimId ? 'updated' : 'created'} a claim for: ${selectedPolicy.Customer?.name} by ${authentication.currentUser.displayName}`
            );

            navigate(authClaims.supervisor ? "/supervisor/claims" : "/agent/claims");

        } catch (error) {
            setIsLoading(false)
            logAction(
                ERROR_LOG,
                "claim creation",
                `Failed to ${claimId ? 'update' : 'create'} a claim: ${selectedPolicy.Customer?.name} by ${authentication.currentUser.displayName}`,
                error.message
            );
            toast.error(`Error saving a claim: ${error.message}`, { position: "top-center" });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(claimAttachments)

        const attachments = claimAttachments.filter((item) => {

            return (item.name != "" && item.name) || item.file
        })

        const attachmentsValid = attachments.every(
            (attachment) => attachment.name && (attachment.file || attachment.filePath)
        );

        if (!selectedPolicy) {
            toast.error(`Please select a policy`, { position: "top-center" });
            return;
        }

        if (claimBeneficiaries.length == 0) {
            toast.error(`Please choose policy beneficiaries`, { position: "top-center" });
            return;
        }

        if (!attachmentsValid && claimAttachments.length > 0) {
            toast.error(`Please provide both attachment name and attachment file`, { position: "top-center" });
            return;
        }

        try {
            setIsLoading(true);

            const attachmentsData = []
            for (let index = 0; index < attachments.length; index++) {
                const element = attachments[index];
                let filePath = null;
                if (element.file) {
                    filePath = await uploadFile(element.file);
                } else {
                    if (claimDetails) {
                        filePath = element.filePath
                    }
                }
                attachmentsData.push({
                    "nameOfFile": element.name,
                    "filePath": filePath
                })
            }

            const data = {
                ...formData,
                "attachments": attachmentsData,
                "claimBeneficiaries": claimBeneficiaries,
                "policyId": selectedPolicy.id,
                "customerId": selectedPolicy.customerId
            }

            await saveClaimDetails(data);
            // console.log(data)
        } catch (error) {
            setIsLoading(false);

            if (!attachmentsValid && claimAttachments.length > 0) {
                toast.error(error.message, { position: "top-center" });
            }
        }
    }


    const handleRemoveAttachment = (index) => {
        setAttachmentCount((attachmentCount) => (attachmentCount === 1 ? 1 : attachmentCount - 1));
        setClaimAttachments((prevAttachments) => {
            const newAttachments = [...prevAttachments];
            newAttachments.splice(index, 1);
            return newAttachments;
        });
    };

    const handleAttachmentChange = (index, event) => {
        const { id, value, files } = event.target;

        setClaimAttachments((prevAttachments) => {
            const newAttachments = [...prevAttachments];
            if (id.startsWith('attachment-file-')) {
                newAttachments[index] = { ...newAttachments[index], file: files[0] };
            } else if (id.startsWith('attachment-name')) {
                newAttachments[index] = { ...newAttachments[index], name: value };
            }
            return newAttachments;
        });
    };

    return (
        <div className="components">
            <header className="tw-mx-5">
                <div className="nk-block-between">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            New Claim
                        </h1>
                        {/* <p className="tw-text-sm tw-text-gray-500">ADD A NEW POLICY</p> */}
                    </div>
                </div>
            </header>
            <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 px-4 py-4  mb-5 shadow-sm tw-relative">
                {(isLoading || loadingDetails) && (
                    <div className="loader-wrapper">
                        <CustomLoader message={"Please wait..."} />
                    </div>
                )}


                <Form className="px-0 py-0" name="form" onSubmit={handleSubmit}>

                    <div className='tw-flex c-sm:tw-flex-col c-md:tw-flex-col c-lg:tw-flex-col  tw-gap-6 '>

                        <div className='tw-w-[55%] c-sm:tw-w-full c-md:tw-w-full c-lg:tw-w-full'>
                            <div className=" mb-3">
                                <button
                                    onClick={() => setPolicyModalOpen(true)}
                                    type="button" className="tw-bg-gray-100 tw-shadow hover:tw-bg-gray-200 tw-px-5  tw-rounded-lg py-2">
                                    Get Policy Details
                                </button>
                            </div>
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Customer Names </Form.Label>
                                <Form.Control
                                    className=' tw-bg-white'
                                    value={selectedPolicy ? selectedPolicy.Customer?.name : ""}
                                    type="text"
                                    placeholder="names"
                                    readOnly={true}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Date of Claim </Form.Label>
                                <Form.Control
                                    value={formData.dateOfClaim ?? ""}
                                    onChange={(e) =>
                                        setFormData({ ...formData, dateOfClaim: e.target.value })
                                    }
                                    type="date"
                                    placeholder="Cost"
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Description  </Form.Label>
                                <Form.Control
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                    id="name"
                                    as="textarea" rows={3}
                                    required
                                    placeholder="Enter symptoms"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Amount of Claim </Form.Label>
                                <Form.Control
                                    value={formData.amountOfClaim ?? ""}
                                    onChange={(e) =>
                                        setFormData({ ...formData, amountOfClaim: e.target.value })
                                    }
                                    type="number"
                                    placeholder="Amount"
                                    required
                                />
                            </Form.Group>

                            <DiagnosisInput
                                claimDetails={claimDetails}
                                setClaimDiagnosisData={(value) => setFormData({ ...formData, medicalClaimDiagnosis: value })} />

                            <TreatmentsInputs
                                claimDetails={claimDetails}
                                treatmentsRecordsSet={(value) => setFormData({ ...formData, treatments: value })} />
                            <p>Attachments</p>
                            {
                                Array.from(Array(attachmentCount).keys()).map((i) => (
                                    <div key={i}>
                                        {
                                            (claimAttachments[i] && claimAttachments[i].filePath) &&
                                            <p className=" mt-2 tw-font-semibold  ">
                                                <FontAwesomeIcon icon={faPaperclip} /> &nbsp;
                                                <a className="" target="_blank" href={claimAttachments[i].filePath}>{getFileNameFromUrl(claimAttachments[i].filePath)}</a>
                                            </p>
                                        }
                                        <div className="tw-flex tw-gap-3" >
                                            <div className="tw-h-full tw-grid tw-grid-cols-2 tw-flex-grow tw-gap-3 tw-mt-3">
                                                <Form.Group className="mb-3 tw-w-full">
                                                    <Form.Control
                                                        type="file"
                                                        id={`attachment-file-${i}`}
                                                        placeholder="Attachment"

                                                        onChange={(event) => handleAttachmentChange(i, event)}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 tw-w-full">
                                                    <Form.Control
                                                        type="text"
                                                        id={`attachment-name-${i}`}
                                                        placeholder="Attachment name"
                                                        value={claimAttachments[i] ? claimAttachments[i].name ?? "" : ""}
                                                        onChange={(event) => handleAttachmentChange(i, event)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            {i === 0 ? (
                                                <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                                                    <input
                                                        onClick={() => setAttachmentCount(attachmentCount + 1)} type="button" value="+" className="btn cta"
                                                    />
                                                </Form.Group>
                                            ) : (
                                                i == attachmentCount - 1 &&
                                                <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                                                    <FontAwesomeIcon onClick={() => { handleRemoveAttachment(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                                                    />
                                                </Form.Group>
                                            )}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className=' tw-flex-grow'>
                            {
                                selectedPolicy &&
                                <BeneficiarySection
                                    claimDetails={claimDetails}
                                    setPolicyBeneficiaries={(value) => setClaimBeneficiaries(value)} selectedPolicy={selectedPolicy} />
                            }
                        </div>
                    </div>
                    <input type="submit" value="Submit Claim" className="btn cta mt-4" />
                    <PolicyModal
                        policySelected={(policy) => {
                            setSelectedPolicy(policy);
                            setPolicyModalOpen(false);
                        }}
                        isOpen={policyModalOpen} onClose={() => setPolicyModalOpen(false)} />
                </Form>
            </div>

        </div>
    )
}


const TreatmentsInputs = ({ treatmentsRecordsSet, claimDetails }) => {
    const [treatmentsInputCount, setTreatmentsInputCount] = useState(1);
    const [treatments, setTreatments] = useState([]);

    useEffect(() => {
        if (claimDetails) {
            if (claimDetails.TreatmentDetail) {
                if (claimDetails.TreatmentDetail.length > 0) {
                    setTreatments(claimDetails.TreatmentDetail)
                    setTreatmentsInputCount(claimDetails.TreatmentDetail.length)
                }
            }
        }
    }, [claimDetails])


    useEffect(() => {
        treatmentsRecordsSet(treatments)
    }, [treatments])

    const handleTreatmentChange = (index, field, value) => {
        const newTreatments = [...treatments];
        const treatment = newTreatments[index] || {};
        treatment[field] = value;
        newTreatments[index] = treatment;
        setTreatments(newTreatments);
    };

    const handleRemoveTreatments = (index) => {
        setTreatmentsInputCount((treatmentsInputCount == 1) ? 1 : treatmentsInputCount - 1)
        if (treatments.length > 1) {
            const newTreatments = treatments.filter((_, i) => i !== index);
            setTreatments(newTreatments);
        }
    };

    return (
        <>
            <p>Treatments</p>
            {
                Array.from(Array(treatmentsInputCount).keys()).map((i) => (
                    <div className="tw-flex tw-gap-3" key={i}>
                        <div className="tw-h-full tw-grid tw-grid-cols-5 tw-flex-grow tw-gap-3 tw-mt-3 mb-3">
                            <Form.Group className=" tw-w-full tw-col-span-2">
                                <Form.Control
                                    value={treatments[i]?.treatmentName}
                                    type="text"
                                    id={`treatmentName-${i}`}
                                    placeholder="Treatment Name"
                                    onChange={(e) => handleTreatmentChange(i, 'treatmentName', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className=" tw-col-span-2 tw-w-full">
                                <Form.Control
                                    value={treatments[i]?.treating}
                                    type="text"
                                    id={`treating-${i}`}
                                    placeholder="Treating"
                                    onChange={(e) => handleTreatmentChange(i, 'treating', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Control
                                    value={treatments[i]?.quantity}
                                    type="text"
                                    id={`quantity-${i}`}
                                    placeholder="Qty"
                                    onChange={(e) => handleTreatmentChange(i, 'quantity', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Control
                                    value={treatments[i]?.unitCost}
                                    type="text"
                                    id={`unitCost-${i}`}
                                    placeholder="Unit Cost"
                                    onChange={(e) => handleTreatmentChange(i, 'unitCost', e.target.value)}
                                />

                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Control
                                    value={treatments[i]?.total}
                                    type="text"
                                    id={`total-${i}`}
                                    placeholder="Total Amt"
                                    onChange={(e) => handleTreatmentChange(i, 'total', e.target.value)}
                                />

                            </Form.Group>
                        </div>
                        <div className='  tw-mt-3 '>
                            {i === 0 ? (
                                <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                                    <input
                                        onClick={() => setTreatmentsInputCount(treatmentsInputCount + 1)} type="button" value="+" className="btn cta"
                                    />
                                </Form.Group>
                            ) : (
                                <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                                    <FontAwesomeIcon onClick={() => { handleRemoveTreatments(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                                    />
                                </Form.Group>
                            )}
                        </div>
                    </div>
                ))
            }
        </>
    )
}



const DiagnosisInput = ({ setClaimDiagnosisData, claimDetails }) => {
    const DiagnosisList = ["Malaria", "Pregnancy Related", "Typhoid", "Others"]

    useEffect(() => {
        // MedicalClaimDiagnosis
        if (claimDetails) {
            const medicalClaimDiagnosis = claimDetails.MedicalClaimDiagnosis
            if (medicalClaimDiagnosis.length > 0) {
                setDiagnosisCount(medicalClaimDiagnosis.length)
                for (let index = 0; index < medicalClaimDiagnosis.length; index++) {
                    const element = medicalClaimDiagnosis[index];
                    if (DiagnosisList.includes(element.diagnosis)) {
                        setSelectedValue((prevValue) => {
                            const newValue = [...prevValue];
                            newValue[index] = element.diagnosis;
                            return newValue;
                        });
                    } else {
                        setShowDetailsInputFor((prevArray) => [...prevArray, index]);
                        setSelectedValue((prevValue) => {
                            const newValue = [...prevValue];
                            newValue[index] = "Others";
                            return newValue;
                        });
                    }
                    setClaimDiagnosis((prevDiagnosis) => {
                        const newDiagnosis = [...prevDiagnosis];
                        newDiagnosis[index] = element.diagnosis;
                        return newDiagnosis;
                    });
                }
            }
        }

    }, [claimDetails])

    const [claimDiagnosis, setClaimDiagnosis] = useState([])
    const [selectedValue, setSelectedValue] = useState([])

    const [diagnosisCount, setDiagnosisCount] = useState(1)
    const [showDetailsInputFor, setShowDetailsInputFor] = useState([]);

    useEffect(() => {
        setClaimDiagnosisData(claimDiagnosis)
    }, [claimDiagnosis])
    const handleRemoveDiagnosis = (index) => {
        setDiagnosisCount((diagnosisCount) => (diagnosisCount === 1 ? 1 : diagnosisCount - 1));
        setClaimDiagnosis((prevDiagnosis) => {
            const newDiagnosis = [...prevDiagnosis];
            newDiagnosis.splice(index, 1);
            return newDiagnosis;
        });
    };

    const handleDiagnosisInput = (index, event) => {
        const { id, value } = event.target;
        setClaimDiagnosis((prevDiagnosis) => {
            const newDiagnosis = [...prevDiagnosis];
            newDiagnosis[index] = value;
            return newDiagnosis;
        });
    };


    const handleDiagnosisChange = (index, event) => {
        const { id, value } = event.target;
        if (value === "Others") {
            setShowDetailsInputFor((prevArray) => [...prevArray, index]);
        } else {
            setShowDetailsInputFor((prevArray) => prevArray.filter(itemIndex => itemIndex !== index));
            setClaimDiagnosis((prevDiagnosis) => {
                const newDiagnosis = [...prevDiagnosis];
                newDiagnosis[index] = value;
                return newDiagnosis;
            });
        }
        setSelectedValue((prevValue) => {
            const newValue = [...prevValue];
            newValue[index] = value;
            return newValue;
        });
    };

    return (
        <>
            <p>Diagnosis</p>
            {
                Array.from(Array(diagnosisCount).keys()).map((i) => (
                    <div className="tw-flex tw-gap-3" key={i}>
                        <div className="tw-h-full tw-flex tw-flex-grow tw-gap-3 tw-mt-3">

                            <Form.Group className="mb-3 tw-flex-grow ">
                                <Form.Select
                                    value={selectedValue[i] || ''}
                                    onChange={(event) => handleDiagnosisChange(i, event)}
                                    className=" tw-w-full " aria-label="" id='policy_category' >
                                    <option value="">Select</option>
                                    <option value="Malaria">Malaria</option>
                                    <option value="Pregnancy Related">Pregnancy Related</option>
                                    <option value="Typhoid">Typhoid</option>
                                    <option value="Others">Others</option>
                                </Form.Select>
                            </Form.Group>
                            {
                                showDetailsInputFor.includes(i) &&
                                <Form.Group className="mb-3 tw-w-1/2">
                                    <Form.Control
                                        value={claimDiagnosis[i] || ''}
                                        type="text"
                                        placeholder="Specify"
                                        onChange={(event) => handleDiagnosisInput(i, event)}
                                    />
                                </Form.Group>
                            }
                        </div>
                        {i === 0 ? (
                            <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                                <input
                                    onClick={() => setDiagnosisCount(diagnosisCount + 1)} type="button" value="+" className="btn cta"
                                />
                            </Form.Group>
                        ) : (
                            i == diagnosisCount - 1 &&
                            <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                                <FontAwesomeIcon onClick={() => { handleRemoveDiagnosis(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                                />
                            </Form.Group>
                        )}
                    </div>
                ))
            }
        </>
    )
}


const BeneficiarySection = ({ selectedPolicy, setPolicyBeneficiaries, claimDetails }) => {
    const [claimBeneficiaries, setClaimBeneficiaries] = useState([])
    const [beneficiaries, setBeneficiaries] = useState([])

    useEffect(() => {
        if (claimDetails) {
            if (claimDetails.ClaimBeneficiary) {
                const policyBeneficiaryIds = []
                for (let index = 0; index < claimDetails.ClaimBeneficiary.length; index++) {
                    const claimBeneficiary = claimDetails.ClaimBeneficiary[index];
                    let policyBeneficiary = [];
                    if (claimBeneficiary.dependentId) {
                        policyBeneficiary = selectedPolicy.PolicyBeneficiary.filter((item) => item.dependentId == claimBeneficiary.dependentId)
                    } else {
                        policyBeneficiary = selectedPolicy.PolicyBeneficiary.filter((item) => item.customerId == claimBeneficiary.customerId)
                    }
                    if (policyBeneficiary.length > 0) {
                        policyBeneficiaryIds.push(policyBeneficiary[0].id)
                    }
                }
                setBeneficiaries(policyBeneficiaryIds)
            }
        }
    }, [claimDetails])

    const handleCheckboxChange = (item) => {
        if (beneficiaries.includes(item.id)) {
            setBeneficiaries(beneficiaries.filter(id => id !== item.id));
        } else {
            setBeneficiaries([...beneficiaries, item.id]);
        }
    };

    useEffect(() => {
        prepareBeneficiaryRecords()
    }, [beneficiaries])

    const prepareBeneficiaryRecords = () => {
        const selectedBeneficiaries = []
        for (let index = 0; index < beneficiaries.length; index++) {
            const selectedBeneficiaryId = beneficiaries[index];
            const beneficiary = selectedPolicy.PolicyBeneficiary.filter((item) => item.id == selectedBeneficiaryId)[0]
            if (beneficiary.dependentId) {
                selectedBeneficiaries.push({
                    "dependentId": beneficiary.dependentId,
                    "type": "dependent"
                })
            } else {
                selectedBeneficiaries.push({
                    "customerId": beneficiary.customerId,
                    "type": "customer"
                })
            }
        }
        setPolicyBeneficiaries(selectedBeneficiaries)
    }



    return (
        <>
            <p className=' tw-text-lg tw-font-semibold mb-3'>Choose the claim beneficiary;</p>
            <div className=' tw-flex tw-flex-col tw-gap-3'>


                {selectedPolicy.PolicyBeneficiary &&
                    selectedPolicy.PolicyBeneficiary.map((item, key) =>
                        <div key={key} className="tw-bg-gray-50 tw-border p-2 tw-flex tw-rounded-lg">
                            <div className="tw-flex tw-flex-col tw-gap-1 py-2 px-3 tw-flex-grow">
                                <span>
                                    <span className=" tw-font-semibold">Names</span>: {item.dependentId ? item.Dependent.name : item?.Customer?.name}
                                </span>
                                <span> <span className=" tw-font-semibold">REF NO</span>:{item.dependentId ? item.Dependent.dependentNumber : item?.Customer?.referenceNumber} </span>
                                <span> <span className=" tw-font-semibold">Owner</span>: {item.dependentId ? "Dependent" : "Customer"}</span>
                                <p className='tw-font-semibold'>What is the policy covers?</p>
                                <div className='tw-bg-gray-100  tw-bg-opacity-70  tw-rounded-md p-3 tw-flex tw-flex-col tw-gap-2'>
                                    {item.PolicyCover &&
                                        item.PolicyCover.map((cover, index) =>
                                            <p className=' tw-flex tw-gap-1' key={index}>
                                                <span className=' tw-text-sm tw-flex tw-flex-col tw-justify-center'> <FiCheck /></span> {cover.cover}, Cost:{cover.cost.toLocaleString()}, Limit:{cover.limit.toLocaleString()}
                                            </p>
                                        )
                                    }
                                </div>
                                {/* <span> <span className=" tw-font-semibold">Terms</span>:  {item.termsLabel} , Cost: {item.termsCost.toLocaleString()}, Limit: {item.termsCost.toLocaleString()}</span> */}
                            </div>

                            < Form.Group className="mb-3 custom-check" controlId="life">
                                <Form.Check
                                    type="checkbox"
                                    label=""
                                    checked={beneficiaries.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item)}
                                />
                            </Form.Group>
                        </div>

                    )
                }

            </div>
        </>
    )
}
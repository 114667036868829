import { useState, useEffect } from "react";
import useAuth from "../../contexts/Auth";
import "../../styles/dashboard.css";
import Header from "../../components/header/Header";
import "../../styles/ctas.css";
import MainChart from "./MainChart";
import useReportsRequest from "hooks/requests/useReportsRequest";
import CustomLoader from "components/CustomLoader";

function DashboardPage({ largeContentClass }) {
  const { authClaims } = useAuth();
  const [users, setUsers] = useState([]);
  const [policies, setPolicies] = useState([]);

  const [claims, setClaims] = useState([]);
  const [claimsSettled, setClaimsSettled] = useState([]);
  const { fetchDashboardStats, isLoading, dashboardStats } = useReportsRequest()

  useEffect(() => {
    document.title = "Dashboard | Core Insurance Management";
    fetchDashboardStats()

  }, []);

  return (
    <div className="components">
      <Header
        title="Core Insurance Management"
        subtitle="Simplifying the sale & distribution of insurance"
      />
      {isLoading && (
        <div className="loader-wrapper">
          <CustomLoader message={"Please wait..."} />
        </div>
      )}

      <div className="componentsData">
        <div
          id="first-row"
          className={`mb-5 my-2 first-row ${!largeContentClass
            ? "tw-overflow-y-hidden tw-overflow-x-hidden"
            : ""
            } ${users?.length > 0 ? "" : ""
            } tw-py-1 tw-px-1 tw-flex tw-flex-col lg:tw-flex-row tw-justify-between`}
        >
          <CardsSection
            dashboardStats={dashboardStats}

          />
          {/*<UsersContainer authClaims={authClaims} users={users} />*/}
        </div>

        <div className="shadow-sm p-3 mb-5 bg-body rounded graph-container mt-0">
          <div className="tw-flex tw-justify-between tw-px-3 mb-4">
            <h5 className="tw-text-lg tw-font-medium chart-ttl">Last 12 Months</h5>
          </div>
          {
            dashboardStats.claimAmountByMonths &&
            <MainChart
              yAxisTitle="Amount"
              series={[
                { name: "Total Amount", data: dashboardStats.claimAmountByMonths.values },
                { name: "Settled Claims", data: [0, 0, 0, 0] },
                { name: "Rejected Claims", data: [0, 0, 0, 0] },
              ]}
              categories={dashboardStats.claimAmountByMonths.keys}
            />
          }



        </div>
      </div>

    </div>
  );
}

export default DashboardPage;


function CardsSection({ dashboardStats }) {
  return (
    <div
      id="bin"
      className="cards-dashboard tw-pt-5 tw-flex tw-flex-col tw-gap-3
      tw-justify-between tw-w-full"
    >
      <div className="tw-w-full tw-h-1/4 tw-gap-3 tw-flex tw-justify-between">
        <div className="tw-w-1/4">
          <div className="tw-w-full tw-h-full tw-bg-[#84c347] tw-rounded-lg card-solo">
            <div className="px-3 py-2 tw-text-white">
              <div className="stat-title">Policies</div>
              <div className="stat-num tw-text-xl md:tw-text-3xl md:tw-mt-3">{`${dashboardStats.policiesCount??""}`}</div>
            </div>
          </div>
        </div>
        <div className="tw-w-1/4">
          <div className="tw-w-full tw-h-full tw-bg-[#a2ce72] tw-rounded-lg card-solo">
            <div className=" px-3 py-2 tw-text-white">
              <div className="stat-title">Total Claims</div>
              <div className="stat-num tw-text-xl md:tw-text-3xl md:tw-mt-3">{`${dashboardStats.claimsCount??""}`}</div>
            </div>
          </div>
        </div>
        <div className="tw-w-1/4">
          <div className="tw-w-full tw-h-full tw-rounded-lg tw-bg-[#71a03f] card-solo">
            <div className=" px-3 py-2 tw-text-white">
              <div className="stat-title">Settled Claims</div>
              <div className="stat-num tw-text-xl md:tw-text-3xl md:tw-mt-3">{`${0}`}</div>
            </div>
          </div>
        </div>
        <div className="tw-w-1/4">
          <div className="tw-w-full tw-h-full tw-bg-[#589b10] tw-rounded-lg card-solo">
            <div className="px-3 py-2 tw-text-white">
              <div className="stat-title">Rejected Claims</div>
              <div className="stat-num tw-text-xl md:tw-text-3xl md:tw-mt-3">{`${0}`}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

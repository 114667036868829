import { useState } from "react";
import { db, db as firestore } from "../../helpers/firebase";
import {
    doc,
    getDoc,
} from "firebase/firestore";
const useAuthorizedUserDetails = () => {
    const [userDetails, setUserDetails] = useState({})
    const [loadingUserDetails, setLoadingUserDetails] = useState(false);
    const getUserDetails = async (userId) => {
        setLoadingUserDetails(true)
        try {
            const userDocSnap = await getDoc(doc(db, "usermeta", userId));
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                setUserDetails(userData);
            }
            setLoadingUserDetails(false)
        } catch (error) {
            console.log("Error getting documents: ", error);
            setLoadingUserDetails(false)
        }
    };
    return {
        userDetails, loadingUserDetails, getUserDetails
    }
}

export default useAuthorizedUserDetails;
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Upload from "components/uploader/Upload";
import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import { authentication } from "helpers/firebase";
import { ERROR_LOG, SUCCESS_LOG, logAction } from "helpers/utils/logsHelper";
import useApiRequest from "hooks/apiRequest";
import useUploadFileRequest from "hooks/uploadFileRequest";
import { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useDependentsRequest from "hooks/requests/useDependentsRequest";
import AppButton from "components/AppButton";
import AppSpinner from "components/AppSpinner";

const DependentsModal = ({
    customerDetails,
    isOpen,
    onClose,
    dependentRecordSelected
}) => {
    const [showDependentsForm, setShowDependentsForm] = useState(false)
    const { isLoading, customerDependents, getCustomerDependents } = useDependentsRequest()
    const { customerId } = useParams();

    useEffect(() => {
        setShowDependentsForm(false)
        if (customerId) {
            getCustomerDependents(customerId)
        }else{
            if (customerDetails) {
                getCustomerDependents(customerDetails.id)
            }
        }
    }, [isOpen])

    useEffect(() => {
        getCustomerDependents(customerId)
    }, [customerId])

    useEffect(() => {
        if (customerDetails) {
            getCustomerDependents(customerDetails.id)
        }
    }, [customerDetails])


    return (
        <>
            {(isOpen) &&
                <div className="tw-modal ">
                    <div className=" modal-content tw-min-h-[400px] modal-lg tw-px-6">
                        <div className=" tw-flex  py-1">
                            <div className=" tw-flex-grow tw-flex tw-flex-col tw-justify-center">
                                <p className="tw-text-lg tw-font-semibold">{customerDetails.name}'s dependent</p>
                            </div>
                            <div>
                                <FontAwesomeIcon onClick={() => onClose()} className=" tw-cursor-pointer" size="lg" icon={faClose} />
                            </div>
                        </div>
                        {
                            showDependentsForm ?
                                <NewDependentForm
                                    customerDetails ={customerDetails}
                                    dependentRecordAdded={(value) => dependentRecordSelected(value)}
                                    dependentsCount={customerDependents.length} /> :
                                <ListDependents
                                    dependentRecordSelected={(value) => dependentRecordSelected(value)}
                                    customerDependents={customerDependents}
                                    setShowDependentsForm={setShowDependentsForm} />
                        }
                        {
                            isLoading &&
                            <AppSpinner />
                        }
                    </div>
                </div>
            }

        </>
    )
}

export default DependentsModal;

const NewDependentForm = (
    {
        dependentsCount,
        dependentRecordAdded,
        customerDetails
    }
) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        nin: "",
        tin: "",
        address: "",
        photo: null,
        policyTerms: null,
    });

    const [photoFile, setPhotoFile] = useState(null);
    const { uploadFile } = useUploadFileRequest()
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const navigate = useNavigate()
    const { authClaims } = useAuth();

    const saveCustomer = async (data) => {
        const dependentNumber = dependentsCount + 1;
        data.dependentNumber = customerDetails.referenceNumber+"/" + dependentNumber;
        data.customerId = parseInt(customerDetails.id);
        try {
            const response = await apiRequest({
                method: 'post',
                url: BASE_URL + "/api/dependents/create",
                data: data,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setIsSaving(false);
            dependentRecordAdded(response.data)
            logAction(
                SUCCESS_LOG,
                "dependent creation",
                `Successfully created dependent: ${formData.name} by ${authentication.currentUser.displayName}`
            );
        } catch (error) {
            setIsSaving(false);
            // throw new Error(error);
        }
    }

    const handleSubmit = async (e) => {
        setIsSaving(true)
        e.preventDefault();
        let data = { ...formData }
        try {
            if (photoFile != null) {
                const photoUrl = await uploadFile(photoFile);
                data = { ...data, photo: photoUrl }
            }
            await saveCustomer(data);
        } catch (error) {
            setIsSaving(false)
            logAction(
                ERROR_LOG,
                "dependent creation",
                `Failed to create dependent: ${formData.name} by ${authentication.currentUser.displayName}`,
                error.message
            );
            toast.error(`Error saving dependent: ${error.message}`, { position: "top-center" });
        }
    }


    const setSelectedTerm = (selectedValue) => {
        const selectedObject = JSON.parse(selectedValue);
        setFormData({ ...formData, policyTerms: selectedObject })
    }


    return (
        <>
            <div className=" bg-red ">
                <Form className="px-0" name="form" onSubmit={handleSubmit}>
                    <div className=" tw-flex c-sm:tw-flex-col c-md:tw-flex-col c-lg:tw-flex-col tw-gap-9">
                        <div className=" tw-w-3/5 c-sm:tw-w-full c-md:tw-w-full c-lg:tw-w-full">

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Names  <span className=" tw-text-red-600">*</span></Form.Label>
                                <Form.Control
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    id="name"
                                    type="text"
                                    required
                                    placeholder="Full names"
                                />
                            </Form.Group>

                            <div className=" tw-grid tw-grid-cols-2 tw-gap-6">
                                <Form.Group className="mb-3 tw-w-full">
                                    <Form.Label htmlFor="category">Email </Form.Label>
                                    <Form.Control
                                        value={formData.email}
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                        id="email"
                                        type="email"
                                        placeholder="Enter email"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 tw-w-full">
                                    <Form.Label htmlFor="category">Phone  </Form.Label>
                                    <Form.Control
                                        value={formData.phone}
                                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                        type="text"
                                        id="phone"
                                        placeholder="Enter phone"
                                    />
                                </Form.Group>
                            </div>

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">DOB  <span className=" tw-text-red-600">*</span></Form.Label>
                                <Form.Control
                                    value={formData.dob}
                                    onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                                    type="date"
                                    required
                                    placeholder="Full names"
                                />

                            </Form.Group>

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Gender  <span className=" tw-text-red-600">*</span> </Form.Label>
                                <div className="tw-flex tw-gap-6">
                                    <div className="tw-flex tw-items-center ">
                                        <input
                                            checked={formData.gender == 'male'}
                                            onChange={(e) => setFormData({ ...formData, gender: 'male' })}
                                            id="gender-radio-1" type="radio" value="male" name="default-radio" className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-focus:ring-blue-500" />
                                        <label htmlFor="gender-radio-1" className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900 ">Male</label>
                                    </div>
                                    <div className="tw-flex tw-items-center ">
                                        <input
                                            checked={formData.gender == 'female'}
                                            onChange={(e) => setFormData({ ...formData, gender: 'female' })}
                                            id="gender-radio-2" type="radio" value="female" name="default-radio" className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-focus:ring-blue-500" />
                                        <label htmlFor="gender-radio-2" className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900 ">Female</label>
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">NIN Number </Form.Label>
                                <Form.Control
                                    value={formData.nin}
                                    onChange={(e) => setFormData({ ...formData, nin: e.target.value })}
                                    type="text"
                                    placeholder="Enter NIN"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Residence</Form.Label>
                                <Form.Control
                                    value={formData.address}
                                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                    type="text"
                                    placeholder="Enter your address"
                                />
                            </Form.Group>

                        </div>
                        <div className=" tw-flex-grow">
                            <>
                                <Form.Label htmlFor="upload">Upload Profile photo</Form.Label>
                                <Upload setLogo={(file) => setPhotoFile(file)} />
                            </>
                        </div>
                    </div>
                    <div className="mb-3 tw-mt-4 tw-w-full tw-flex tw-gap-6">
                        {/* <button  type="submit"
                                    className="btn btn-dark cta submitcta">
                                    Submit
                                </button> */}
                        <AppButton buttonType={"submit"} callBackFun={() => { }} showLoader={isSaving}
                            spinnerClass={"tw-inline tw-w-3 tw-h-3 tw-mr-2 tw-white tw-animate-spin tw-fill-black"}
                            className="btn btn-dark cta submitcta  " text="Save Changes" />
                    </div>
                </Form>

            </div>

        </>
    )
}


const ListDependents = (
    {
        dependentRecordSelected,
        setShowDependentsForm,
        customerDependents
    }
) => {
    return (
        <>

            <div className=" tw-flex tw-justify-start tw-mt-4 tw-border-t pt-4">
                <div className="tw-w-1/2" id="search">

                </div>
                <div className=" tw-w-1/2 tw-flex tw-justify-end">
                    <button onClick={() => setShowDependentsForm(true)} className="btn cta ">New Dependent</button>
                </div>
            </div>
            <Table hover striped responsive className="mt-4 tw-mb-6" id="myTable">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Gender</th>
                        <th>Phone No.</th>
                        <th>NIN</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        customerDependents.map((item, key) =>
                            <tr key={key}>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.gender}</td>
                                <td>{item.phone}</td>
                                <td>{item.nin}</td>
                                <td>
                                    <span
                                        onClick={() => dependentRecordSelected(item)}
                                        className=" tw-text-blue-700 tw-cursor-pointer" >choose</span>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            {
                customerDependents.length == 0 &&
                <p className=" tw-text-center">No Customer Dependents Yet!</p>
            }
        </>

    )
}
import { faPaperclip, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomLoader from 'components/CustomLoader'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import useUploadFileRequest from 'hooks/uploadFileRequest';
import useApiRequest from 'hooks/apiRequest';
import { BASE_URL } from 'helpers/constants';
import useAuth from 'contexts/Auth';
import { authentication } from 'helpers/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { convertSnakeCaseToTitle, generateCode, getFilenameFromURL } from 'helpers/helpfulUtilities';
import { ERROR_LOG, SUCCESS_LOG, logAction } from 'helpers/utils/logsHelper';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterOptions, UseFormRegisterReturn, useForm } from "react-hook-form";
import AppButton from 'components/AppButton';
import useUserRequest from 'hooks/requests/useUserRequest';
import useOrganisationsRequest from 'hooks/requests/useOrganisationsRequest';
import PasswordGenerator from 'components/PasswordGenerator';
import { now } from 'moment';

export default function NewUser() {
    const [isSaving, setIsSaving] = useState(false)
    const { uploadFile } = useUploadFileRequest()
    const apiRequest = useApiRequest();
    const { authClaims, currentUser } = useAuth();
    const [photoAttachement, setphoto] = useState(null)
    const navigate = useNavigate()
    const { userId, usertype } = useParams();
    const { fetchUserDetails, userDetails, isLoading } = useUserRequest()
    useEffect(() => {
        fetchUserDetails(userId)
    }, [userId])
    // console.log(userId)


    useEffect(() => {
        if (userDetails) {
            reset({
                organisation: userDetails.userMetadata.organisation,
                email: userDetails.email,
                name: userDetails.displayName,
                dob: "",
                gender: userDetails.userMetadata.gender,
                phone: userDetails.userMetadata.phone,
                address: userDetails.userMetadata.address,
                licenseNo: userDetails.userMetadata.licenseNo,
                NIN: userDetails.userMetadata.NIN,
                tinNumber: userDetails.userMetadata.tinNumber,
                photo: userDetails.userMetadata.photo,
                user_role: userDetails.userMetadata.user_role,
                password: "",
                supervisor: userDetails.userMetadata.supervisor,
                agent_handles: userDetails.userMetadata.agent_handles,
            })

        }
    }, [userDetails])
    let schema = null;
    if (userId) {
        schema = yup.object({
            organisation: yup.string().when('user_role', {
                is: userRole => userRole !== 'admin',
                then: yup.string().required("Organisation is required"),
            }),
            name: yup.string().required("Name is required"),
            email: yup.string().required("Email is required"),
            gender: yup.string().required("Gender is required"),
            phone: yup.string().required("Phone is required"),
        });
    } else {
        schema = yup.object({
            organisation: yup.string().when('user_role', {
                is: userRole => userRole !== 'admin',
                then: yup.string().required("Organisation is required"),
            }),
            name: yup.string().required("Name is required"),
            email: yup.string().required("Email is required"),
            gender: yup.string().required("Gender is required"),
            phone: yup.string().required("Phone is required"),
            password: yup.string().required("Password is required"),
        });
    }
    const form = useForm({
        defaultValues: {
            organisation: "",
            email: "",
            name: "",
            dob: "",
            gender: "",
            phone: "",
            address: "",
            licenseNo: "",
            NIN: "",
            tinNumber: "",
            photo: "",
            user_role: usertype,
            password: "",
            supervisor: null,
            agent_handles: {
                life: false,
                childcare: false,
                medical: false
            },
        },
        resolver: yupResolver(schema),
    });
    const { register, handleSubmit, formState, watch, control, reset, setValue } = form;
    const { errors } = formState;
    const { fetchOrganisations, isLoading: isLoadingOrganisations, organisations } = useOrganisationsRequest()
    const watchPassword = watch("password")
    useEffect(() => {
        fetchOrganisations()
    }, [])

    const onSubmit = async (formData) => {
        let data = { ...formData }
        if (!userId) {
            data = {
                ...data,
                createdAt: now(),
                addedOn: `${new Date().toISOString().slice(0, 10)} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
            }
        }

        setIsSaving(true);
        try {
            if (photoAttachement != null) {
                const fileUrl = await uploadFile(photoAttachement);
                data = { ...data, photo: fileUrl }
            }
            await saveUserDetails(data);
        } catch (error) {
            setIsSaving(false)
            logAction(
                ERROR_LOG,
                "user creation",
                `Failed to create user: ${data.name} by ${authentication.currentUser.displayName}`,
                error.message
            );
            toast.error(`Error saving user: ${error.message}`, { position: "top-center" });
        }
    };


    const saveUserDetails = async (data) => {
        let url = BASE_URL + "/api/user/create"
        if (userId) {
            url = BASE_URL + "/api/user/update/" + userId
        }
        try {
            const response = await apiRequest({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            toast.success(`successfully ${userId ? 'updated' : 'created'} user`, { position: "top-center" });
            logAction(
                SUCCESS_LOG,
                "user creation",
                `Successfully ${userId ? 'updated' : 'created'} user: ${data.name} by ${authentication.currentUser.displayName}`
            );
            navigate(authClaims.superadmin ? `/superadmin/${usertype}s` : `/admin/${usertype}s`);
        } catch (error) {
            console.log(error)
            if (error.response.data) {
                toast.error(`Error saving user: ${error.response.data.message}`, { position: "top-center" });
            } else {
                throw new Error(error);
            }
        } finally {
            setIsSaving(false);
        }
    }



    return (
        <div className="components">
            <header className="tw-mx-5">
                <div className="nk-block-between">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            {userId ? "Update" : "Add"} <span className=' tw-capitalize'>{usertype}</span>
                        </h1>
                        <p className="tw-text-sm tw-text-gray-500"> {userId ? "UPDATE" : "ADD A NEW"} USER</p>
                    </div>
                </div>
            </header>
            <ToastContainer />

            {(isLoading || isLoadingOrganisations) && (
                <div className="loader-wrapper">
                    <CustomLoader message={"Please wait..."} />
                </div>
            )}
            <Form className='px-0 py-0' onSubmit={handleSubmit(onSubmit)} noValidate>

                <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 tw-mt-0 mb-5 shadow-sm tw-relative px-4 py-4">

                    <div className='tw-w-3/5 c-sm:tw-w-full c-md:tw-w-full c-lg:tw-w-full '>

                        {(usertype !== "admin") &&
                            <Form.Group className="mb-3" >
                                <Form.Label htmlFor='organisation'>Organisation <span className=' tw-text-red-600'>*</span> </Form.Label>
                                <Form.Select   {...register("organisation")}>
                                    <option value="">--Select Organisation--</option>
                                    {organisations &&
                                        organisations.length > 0 &&
                                        organisations.map((organisation, index) => (
                                            <option value={organisation.id} key={index}>{organisation?.name}</option>
                                        ))}
                                </Form.Select>
                                <p className="tw-text-xs tw-text-red-600">{errors.organisation?.message}</p>
                            </Form.Group>
                        }

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="name">Names  <span className=' tw-text-red-600'>*</span> </Form.Label>
                            <Form.Control
                                {...register("name")}
                                type="text"
                                required
                                placeholder="Names"
                            />
                            <p className="tw-text-xs tw-text-red-600">{errors.name?.message}</p>
                        </Form.Group>


                        <div className='tw-grid tw-grid-cols-2 tw-gap-3 mb-3 tw-w-full'>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="email">Email   <span className=' tw-text-red-600'>*</span> </Form.Label>
                                <Form.Control
                                    {...register("email")}
                                    type="text"
                                    required
                                    disabled={userId ? true : false}
                                    placeholder="Email"
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.email?.message}</p>
                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="phone">Phone  <span className=' tw-text-red-600'>*</span> </Form.Label>
                                <Form.Control
                                    {...register("phone")}
                                    type="text"
                                    required
                                    placeholder="Phone"
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.phone?.message}</p>
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3" >
                            <Form.Label htmlFor='gender'>Gender <span className=' tw-text-red-600'>*</span> </Form.Label>
                            <Form.Select   {...register("gender")}>
                                <option value="">--Select gender--</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </Form.Select>
                            <p className="tw-text-xs tw-text-red-600">{errors.gender?.message}</p>

                        </Form.Group>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="address">Address</Form.Label>
                            <Form.Control
                                {...register("address")}
                                type="text"
                                required
                                placeholder="Address"
                            />
                        </Form.Group>

                        <div className='tw-grid tw-grid-cols-2 tw-gap-3 mb-3 tw-w-full'>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="NIN">NIN</Form.Label>
                                <Form.Control
                                    {...register("NIN")}
                                    type="text"
                                    required
                                    placeholder="NIN"
                                />
                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="tinNumber">TIN Number</Form.Label>
                                <Form.Control
                                    {...register("tinNumber")}
                                    type="text"
                                    required
                                    placeholder="TIN"
                                />
                            </Form.Group>
                        </div>

                        {usertype === "agent" && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="agentcan">Agent Can?</Form.Label>
                                </Form.Group>
                                < Form.Group className="mb-3" controlId="medical">
                                    <Form.Check
                                        type="checkbox"
                                        label="Handles Medical"
                                        id="handle_medical"
                                        {...register("agent_handles.medical")}
                                    />
                                </Form.Group>

                                < Form.Group className="mb-3" controlId="life">
                                    <Form.Check
                                        type="checkbox"
                                        label="Handles Life"
                                        id="handle_life"
                                        {...register("agent_handles.life")}
                                    />
                                </Form.Group>

                                < Form.Group className="mb-3" controlId="childcare">
                                    <Form.Check
                                        type="checkbox"
                                        label="Handles Childcare"
                                        id="handle_childcare"
                                        {...register("agent_handles.childcare")}
                                    />
                                </Form.Group>
                            </>
                        )}

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor='photo'>Upload Profile photo</Form.Label>
                            <Form.Control id='photo' type="file" onChange={(event) => {
                                setphoto(event.target.files[0])
                            }} />

                        </Form.Group>
                        {
                            (!userId) &&
                            <Form.Group className="mb-3 mt-3">
                                <PasswordGenerator
                                    password={watchPassword}
                                    setPassword={(password) => {
                                        setValue("password", password, {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                            shouldTouch: true,
                                        })
                                    }}
                                />
                                <p className="tw-text-sm tw-text-red-600">{errors.password?.message}</p>

                            </Form.Group>
                        }

                        <div className=' mt-4 tw-relative tw-flex tw-justify-end '>
                            <AppButton buttonType={"submit"} callBackFun={() => { }} showLoader={isSaving}
                                spinnerClass={"tw-inline tw-w-3 tw-h-3 tw-mr-2 tw-white tw-animate-spin tw-fill-black"}
                                className="btn btn-dark cta submitcta  " text="Save User" />
                            {/* <input type="submit" value="Submit User" className="btn cta" /> */}
                        </div>
                    </div>
                </div>


            </Form>
        </div>
    )
}




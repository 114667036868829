import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomLoader from "components/CustomLoader";
import Loader from "components/Loader";
import Upload from "components/uploader/Upload"
import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import { authentication } from "helpers/firebase";
import { getFileNameFromUrl, getFilenameFromURL } from "helpers/helpfulUtilities";
import { ERROR_LOG, SUCCESS_LOG, logAction } from "helpers/utils/logsHelper";
import useApiRequest from "hooks/apiRequest";
import useCustomersRequest from "hooks/requests/useCustomersRequest";
import useUploadFileRequest from "hooks/uploadFileRequest";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"

const AddCustomer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        nin: "",
        tin: "",
        address: "",
        licenseNo: "",
        photo: null,
        fingerPrint: null,
        nextOfKin: "",
        nextOfKinPhone: "",
        nextOfKinEmail: "",
        nextOfKinNin: ""
    });

    const [fingerPrintFile, setFingerPrintFile] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const { uploadFile } = useUploadFileRequest()
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const [addPolicy, setAddPolicy] = useState(false)
    const navigate = useNavigate()
    const { authClaims } = useAuth();
    const { customerId } = useParams();
    const { getCustomerDetails, loadingDetails, customerDetails } = useCustomersRequest()

    useEffect(() => {
        getCustomerDetails(customerId)
    }, [customerId])

    useEffect(() => {
        if (customerDetails != null) {
            setFormData(customerDetails)
        }
    }, [customerDetails])


    const saveCustomer = async (data) => {
        let url = BASE_URL + "/api/customers/create"
        if (customerId) {
            url = BASE_URL + "/api/customers/update/" + customerId
        }
        try {
            const response = await apiRequest({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setIsLoading(false);
            toast.success(`successfully ${customerId ? 'updated' : 'created'} Customer`, { position: "top-center" });
            logAction(
                SUCCESS_LOG,
                "customer creation",
                `Successfully ${customerId ? 'updated' : 'created'} customer: ${formData.name} by ${authentication.currentUser.displayName}`
            );

            if (addPolicy) {
                navigate(authClaims.supervisor ? `/supervisor/new-policy/${response.data.id}` :
                    `/agent/new-policy/${response.data.id}`
                );
            } else {
                
                if (authClaims.supervisor) {
                    navigate("/supervisor/clients");
                }else if(authClaims.agent){
                    navigate("/agent/clients");
                }else if(authClaims.admin){
                    navigate("/admin/customers");
                }else if(authClaims.superadmin){
                    navigate("/superadmin/customers");
                }
            }

        } catch (error) {
            console.log(error)
            setIsLoading(false);
            if (error.response.data) {
                toast.error(`Error saving customer: ${error.response.data.message}`, { position: "top-center" });
            } else {
                throw new Error(error);
            }
        }
    }

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        let data = { ...formData }
        try {
            if (fingerPrintFile != null) {
                const fingerPrintUrl = await uploadFile(fingerPrintFile);
                data = { ...data, fingerPrint: fingerPrintUrl }
            }
            if (photoFile != null) {
                const photoUrl = await uploadFile(photoFile);
                data = { ...data, photo: photoUrl }
            }
            await saveCustomer(data);
        } catch (error) {
            setIsLoading(false)
            logAction(
                ERROR_LOG,
                "customer creation",
                `Failed to create customer: ${formData.name} by ${authentication.currentUser.displayName}`,
                error.message
            );
            toast.error(`Error saving customer: ${error.message}`, { position: "top-center" });
        }
    }

    return (
        <div className="components">
            <header className="tw-mx-5">
                <div className="nk-block-between">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            Add Customer
                        </h1>
                        {/* <p className="tw-text-sm tw-text-gray-500">ADD A NEW POLICY</p> */}
                    </div>
                </div>
            </header>
            <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 tw-mt-0 mb-5 shadow-sm tw-relative">
                {(isLoading || loadingDetails) && (
                    <div className="loader-wrapper">
                        <CustomLoader message={"Please wait..."} />
                    </div>
                )}

                <Form name="form" onSubmit={handleSubmit}>
                    <div className="tw-w-3/5 c-sm:tw-w-full c-md:tw-w-full c-lg:tw-w-full">
                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">Names  <span className=" tw-text-red-600">*</span></Form.Label>
                            <Form.Control
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                id="name"
                                type="text"
                                required
                                placeholder="Full names"
                            />
                        </Form.Group>

                        <div className=" tw-grid tw-grid-cols-2 tw-gap-6">
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Email </Form.Label>
                                <Form.Control
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    id="email"
                                    type="email"
                                    placeholder="Enter email"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Phone <span className=" tw-text-red-600">*</span> </Form.Label>
                                <Form.Control
                                    value={formData.phone}
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                    type="text"
                                    id="phone"
                                    required
                                    placeholder="Enter phone"
                                />
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">DOB  <span className=" tw-text-red-600">*</span></Form.Label>
                            <Form.Control
                                value={formData.dob}
                                onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                                type="date"
                                required
                                placeholder="Full names"
                            />

                        </Form.Group>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">Gender  <span className=" tw-text-red-600">*</span> </Form.Label>
                            <div className="tw-flex tw-gap-6">
                                <div className="tw-flex tw-items-center ">
                                    <input
                                        checked={formData.gender == 'male'}
                                        onChange={(e) => setFormData({ ...formData, gender: 'male' })}
                                        id="gender-radio-1" type="radio" value="male" name="default-radio" className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-focus:ring-blue-500" />
                                    <label htmlFor="gender-radio-1" className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900 ">Male</label>
                                </div>
                                <div className="tw-flex tw-items-center ">
                                    <input
                                        checked={formData.gender == 'female'}
                                        onChange={(e) => setFormData({ ...formData, gender: 'female' })}
                                        id="gender-radio-2" type="radio" value="female" name="default-radio" className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-focus:ring-blue-500" />
                                    <label htmlFor="gender-radio-2" className="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900 ">Female</label>
                                </div>
                            </div>
                        </Form.Group>


                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">NIN Number <span className=" tw-text-red-600">*</span> </Form.Label>
                            <Form.Control
                                value={formData.nin}
                                onChange={(e) => setFormData({ ...formData, nin: e.target.value })}
                                type="text"
                                required
                                placeholder="Enter NIN"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">Residence</Form.Label>
                            <Form.Control
                                value={formData.address}
                                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                type="text"
                                placeholder="Enter your address"
                            />
                        </Form.Group>

                        {/* <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">License No.</Form.Label>
                            <Form.Control
                                value={formData.licenseNo}
                                onChange={(e) => setFormData({ ...formData, licenseNo: e.target.value })}
                                type="text"
                                placeholder="license No."
                            />
                        </Form.Group> */}

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category"> Next of Kin  </Form.Label>
                            <Form.Control
                                value={formData.nextOfKin}
                                onChange={(e) => setFormData({ ...formData, nextOfKin: e.target.value })}
                                type="text"
                                placeholder="Full names"
                            />
                        </Form.Group>

                        <div className=" tw-grid tw-grid-cols-2 tw-gap-6">
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Next of Kin Phone </Form.Label>
                                <Form.Control
                                    value={formData.nextOfKinPhone}
                                    onChange={(e) => setFormData({ ...formData, nextOfKinPhone: e.target.value })}
                                    type="text"
                                    placeholder="Next of Kin Phone"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category">Next of Kin Email </Form.Label>
                                <Form.Control
                                    value={formData.nextOfKinEmail}
                                    onChange={(e) => setFormData({ ...formData, nextOfKinEmail: e.target.value })}
                                    type="text"
                                    placeholder="Next of Kin Email"
                                />
                            </Form.Group>
                        </div>


                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category"> Next of Kin National ID</Form.Label>
                            <Form.Control
                                value={formData.nextOfKinNin}
                                onChange={(e) => setFormData({ ...formData, nextOfKinNin: e.target.value })}
                                type="text"
                                placeholder="National Id"
                            />
                        </Form.Group>

                        {/* <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">Upload Fingerprint </Form.Label>
                            <Form.Control
                                onChange={(e) => setFingerPrintFile(e.target.files[0])}
                                type="file"
                            />
                            <Form.Label htmlFor="category">
                                {
                                    customerDetails &&
                                    <p className=" mt-2 tw-text-blue-700  ">
                                        <FontAwesomeIcon icon={faPaperclip} /> &nbsp;
                                        <a className="tw-underline" target="_blank" href={customerDetails.fingerPrint}>{getFilenameFromURL(customerDetails.fingerPrint)}</a>
                                    </p>
                                }
                            </Form.Label>
                        </Form.Group> */}

                        <>
                            <Form.Label htmlFor="upload">Upload Profile photo</Form.Label>
                            <Upload currentFileUrl={customerDetails ? customerDetails.photo : null} setLogo={(file) => setPhotoFile(file)} />
                        </>

                        <div className="mb-3 tw-mt-14 tw-w-full tw-flex tw-gap-6 tw-justify-end">
                            <button onClick={() => setAddPolicy(true)} type="submit" className=" btn btn-dark cta submitcta">
                                Submit & Add Policy
                            </button>
                            <button onClick={() => setAddPolicy(false)} type="submit"
                                className="tw-bg-gray-300 hover:tw-bg-gray-400 tw-px-5  tw-rounded-lg ">
                                Submit
                            </button>
                            {/* <input
                                type="submit"
                                value="Submit"
                                className="  tw-bg-gray-300 hover:tw-bg-gray-400 tw-px-5  tw-rounded-lg "
                            /> */}
                            {/* tw-bg-gray-200 px-3 py-2 tw-rounded-lg */}
                        </div>
                    </div>
                </Form>

            </div>
        </div>
    )
}


export default AddCustomer;
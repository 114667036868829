import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import { useState } from "react";

const useTransactionsRequest = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null)
  const apiRequest = useApiRequest()
  const [isSaving, setIsSaving] = useState(false)
  const { currentUser } = useAuth();

  const fetchTransactionDetails = async (transactionId) => {
    setIsFetching(true);
    try {
      const response = await apiRequest({
        method: 'get',
        url: `${BASE_URL}/api/policies/transaction/details/${transactionId}`,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      setTransactionDetails(response.data)
      setIsFetching(false)
      return response;
    } catch (error) {
      setIsFetching(false)
    }
  }

  const registerTransaction = async (data) => {
    setIsSaving(true);
    try {
      const response = await apiRequest({
        method: 'post',
        url: BASE_URL + "/api/policies/make-payment",
        data: data,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      setIsSaving(false);
      return response;
    } catch (error) {
      setIsSaving(false)
    }
  }

  return {
    fetchTransactionDetails,
    transactionDetails,
    isFetching,
    registerTransaction,
    isSaving
  }
}

export default useTransactionsRequest;

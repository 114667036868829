export const CUSTOMER_PERMISSIONS = {
    ADD: 'add_customer',
    VIEW: 'view_customer',
    UPDATE: 'update_customer',
    DELETE: 'delete_customer',
}

export const AGENTS_PERMISSIONS = {
    ADD: 'add_agent',
    VIEW: 'view_agent',
    UPDATE: 'update_agent',
    DELETE: 'delete_agent',
}

export const MEDICAL_POLICY_PERMISSIONS = {
    ADD: 'add_medical_policy',
    VIEW: 'view_medical_policy',
    UPDATE: 'update_medical_policy',
    DELETE: 'delete_medical_policy',
}

export const LIFE_POLICY_PERMISSIONS = {
    ADD: 'add_life_policy',
    VIEW: 'view_life_policy',
    UPDATE: 'update_life_policy',
    DELETE: 'delete_life_policy',
}

export const CHILDCARE_POLICY_PERMISSIONS = {
    ADD: 'add_childcare_policy',
    VIEW: 'view_childcare_policy',
    UPDATE: 'update_childcare_policy',
    DELETE: 'delete_childcare_policy',
}

export const MTP_POLICY_PERMISSIONS = {
    ADD: 'add_mtp_policy',
    VIEW: 'view_mtp_policy',
    UPDATE: 'update_mtp_policy',
    DELETE: 'delete_mtp_policy',
}

export const MEDICAL_CAIMS_PERMISSIONS = {
    ADD: 'add_medical_claim',
    VIEW: 'view_medical_claim',
    UPDATE: 'update_medical_claim',
    DELETE: 'delete_medical_claim',
}

export const LIFE_CAIMS_PERMISSIONS = {
    ADD: 'add_life_claim',
    VIEW: 'view_life_claim',
    UPDATE: 'update_life_claim',
    DELETE: 'delete_life_claim',
}

export const CHILDCARE_CAIMS_PERMISSIONS = {
    ADD: 'add_childcare_claim',
    VIEW: 'view_childcare_claim',
    UPDATE: 'update_childcare_claim',
    DELETE: 'delete_childcare_claim',
}

export const MTP_CAIMS_PERMISSIONS = {
    ADD: 'add_mtp_claim',
    VIEW: 'view_mtp_claim',
    UPDATE: 'update_mtp_claim',
    DELETE: 'delete_mtp_claim',
}


export const APP_PERMISSIONS = {
    CUSTOMER_PERMISSIONS: Object.values(CUSTOMER_PERMISSIONS),
    AGENTS_PERMISSIONS: Object.values(AGENTS_PERMISSIONS),
    MEDICAL_POLICY_PERMISSIONS: Object.values(MEDICAL_POLICY_PERMISSIONS),
    LIFE_POLICY_PERMISSIONS: Object.values(LIFE_POLICY_PERMISSIONS),
    CHILDCARE_POLICY_PERMISSIONS: Object.values(CHILDCARE_POLICY_PERMISSIONS),
    MTP_POLICY_PERMISSIONS: Object.values(MTP_POLICY_PERMISSIONS),
    MEDICAL_CAIMS_PERMISSIONS: Object.values(MEDICAL_CAIMS_PERMISSIONS),
    LIFE_CAIMS_PERMISSIONS: Object.values(LIFE_CAIMS_PERMISSIONS),
    CHILDCARE_CAIMS_PERMISSIONS: Object.values(CHILDCARE_CAIMS_PERMISSIONS),
    MTP_CAIMS_PERMISSIONS: Object.values(MTP_CAIMS_PERMISSIONS),
}
import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import { useState } from "react";

const useCustomersRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [customerDetails, setCustomerDetails] = useState(null);
    const { authClaims } = useAuth();
    const [hasMore, setHasMore] = useState(false)
    const [nextCursor, setNextCursor] = useState(undefined)
    const [customers, setCustomers] = useState([])
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const [loadingMore, setLoadingMore] = useState(true)
    const [timeoutRef, setTimeoutRef] = useState(null);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1)

    const loadMore = async () => {
        setLoadingMore(true);
        setCurrentPage(currentPage + 1)
        fetchCustomers(currentPage + 1)
    }

    const searchCustomers = (value) => {
        setCurrentPage(1)
        setCustomers([]);
        setNextCursor(undefined)

        setSearch(value)
        // Clear the previous timeout
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        // Set a new timeout
        const newTimeout = setTimeout(() => {
            // Here, you can call your API with the search value (value)

            fetchCustomers(1, value)
        }, 500);

        // Store the timeout reference
        setTimeoutRef(newTimeout);
    }


    const fetchCustomers = async (page = 1, query = "") => {
        if (page == 1) {
            setCurrentPage(1)
            setNextCursor(undefined)
        }
        setIsLoading(true);
        await apiRequest({
            method: 'get',
            url: `${BASE_URL}/api/customers?search=${query}&nextCursor=${(nextCursor && page > 1) ? nextCursor : ''}`,
            headers: {
                Authorization: `Bearer ${currentUser.accessToken}`,
            },
        }).then((response) => {
            if (page == 1) {
                setCustomers(response.data.items);
            } else {
                setCustomers([...customers, ...response.data.items]);
            }
            setNextCursor(response.data.nextCursor)
            console.log(response.data.totalPages)
            console.log(page)
            if (response.data.totalPages <= page) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }
            setIsLoading(false)
            setLoadingMore(false)
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)
            setLoadingMore(false)
        });
    }


    const getCustomerDetails = async (customerId) => {
        setLoadingDetails(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/customers/details/${customerId}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setCustomerDetails(response.data)

            setLoadingDetails(false)
        } catch (error) {
            setLoadingDetails(false)
        }
    }

    return {
        customers,
        isLoading,
        fetchCustomers,
        hasMore,
        nextCursor,
        loadingMore,
        loadMore,
        searchCustomers,
        getCustomerDetails,
        loadingDetails,
        customerDetails,
        setCustomerDetails
    }
}

export default useCustomersRequest;
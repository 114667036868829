import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import { useState } from "react";

const useInsuranceProductsRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([])
    const [productDetails, setProductDetails] = useState(null)

    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const fetchProducts = async () => {
        setIsLoading(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/insurance-products`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setProducts(response.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const fetchProductDetails = async (productId) => {
        setIsLoading(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/insurance-products/details/${productId}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setProductDetails(response.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return {
        products,
        isLoading,
        fetchProducts,
        fetchProductDetails,
        productDetails
    }
}

export default useInsuranceProductsRequest;
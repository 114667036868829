import { useEffect, useState } from "react";
import { MdDownload } from "react-icons/md";
import SearchBar from "../components/searchBar/SearchBar";
import Header from "../components/header/Header";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../helpers/firebase";
import { Table, Form } from "react-bootstrap";
import Pagination from "../helpers/Pagination";
import { currencyFormatter } from "../helpers/currency.format";
import Loader from "../components/Loader";
import { ImFilesEmpty } from "react-icons/im";
import { httpsCallable } from "firebase/functions";
import { authentication, functions } from "../helpers/firebase";
import { generateReport } from "../helpers/generateReport";
import useAuth from "../contexts/Auth";
import useMediaQuery from "../hooks/useMediaQuery";
import { convertStringToDate } from "../helpers/helpfulUtilities";
import { FaSortDown, FaSortUp } from "react-icons/fa";

import "../styles/ctas.css";

function Reports({ parent_container }) {
  useEffect(() => {
    document.title = "Reports | Core Insurance Management";
    getPolicies();

    return () => getPolicies();
  }, []);

  // policies
  const [policies, setPolicies] = useState([]);
  const policyCollectionRef = collection(db, "policies");

  const { authClaims } = useAuth();

  const getPolicies = async () => {
    const data = await getDocs(policyCollectionRef);
    const policiesArray = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const listUsers = httpsCallable(functions, "listUsers");

    listUsers().then(({ data }) => {
      if (authClaims.agent) {
        const agentPolicies = policiesArray.filter(
          (policy) => policy.added_by_uid === authentication.currentUser.uid
        );
        agentPolicies.length === 0
          ? setPolicies(null)
          : setPolicies(agentPolicies);
      } else if (authClaims.supervisor) {
        const myAgents = data
          .filter((user) => user.role.agent === true)
          .filter(
            (agent) =>
              agent.meta.added_by_uid === authentication.currentUser.uid
          )
          .map((agentuid) => agentuid.uid);

        const usersUnderSupervisor = [
          ...myAgents,
          authentication.currentUser.uid,
        ];

        const supervisorPolicies = policiesArray.filter((policy) =>
          usersUnderSupervisor.includes(policy.added_by_uid)
        );
        supervisorPolicies.length === 0
          ? setPolicies(null)
          : setPolicies(supervisorPolicies);
      } else if (authClaims.admin) {
        const myAgents = data
          .filter((user) => user.role.agent === true)
          .filter(
            (agent) =>
              agent.meta.added_by_uid === authentication.currentUser.uid
          )
          .map((agentuid) => agentuid.uid);

        const mySupervisors = data
          .filter((user) => user.role.supervisor === true)
          .filter(
            (supervisor) =>
              supervisor.meta.added_by_uid === authentication.currentUser.uid
          )
          .map((supervisoruid) => supervisoruid.uid);

        const agentsUnderMySupervisors = data
          .filter((user) => user.role.agent === true)
          .filter((agent) => mySupervisors.includes(agent.meta.added_by_uid))
          .map((agentuid) => agentuid.uid);

        const usersUnderAdmin = [
          ...myAgents,
          ...agentsUnderMySupervisors,
          ...mySupervisors,
          authentication.currentUser.uid,
        ];

        const AdminPolicies = policiesArray.filter((policy) =>
          usersUnderAdmin.includes(policy.added_by_uid)
        );
        AdminPolicies.length === 0
          ? setPolicies(null)
          : setPolicies(AdminPolicies);
      } else if (authClaims.superadmin) {
        policiesArray.length === 0
          ? setPolicies(null)
          : setPolicies(policiesArray);
      }

      const myAgents = data
        .filter((user) => user.role.agent === true)
        .filter(
          (agent) => agent.meta.added_by_uid === authentication.currentUser.uid
        )
        .map((agentuid) => agentuid.uid);

      const mySupervisors = data
        .filter((user) => user.role.supervisor === true)
        .filter(
          (supervisor) =>
            supervisor.meta.added_by_uid === authentication.currentUser.uid
        )
        .map((supervisoruid) => supervisoruid.uid);

      const agentsUnderMySupervisors = data
        .filter((user) => user.role.agent === true)
        .filter((agent) => mySupervisors.includes(agent.meta.added_by_uid))
        .map((agentuid) => agentuid.uid);

      const usersUnderAdmin = [
        ...myAgents,
        ...agentsUnderMySupervisors,
        ...mySupervisors,
        authentication.currentUser.uid,
      ];

      const AdminPolicies = policiesArray.filter((policy) =>
        usersUnderAdmin.includes(policy.added_by_uid)
      );
      AdminPolicies.length === 0
        ? setPolicies(null)
        : setPolicies(AdminPolicies);
    });
  };

  // TODO: look for a better way to switch between categories
  const [switchCategory, setSwitchCategory] = useState("");
  // current month
  const currentMonth = new Date().getMonth();
  const monthOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  let today;
  if (new Date().getMonth() + 1 < 10) {
    today = `${new Date().getFullYear()}-0${new Date().getMonth() + 1
      }-${new Date().getDate()}`;
  } else {
    today = `${new Date().getFullYear()}-${new Date().getMonth() + 1
      }-${new Date().getDate()}`;
  }

  const [currentDay, setCurrentDay] = useState(null);

  // search by Name
  const [searchText, setSearchText] = useState("");
  const handleSearch = ({ target }) => setSearchText(target.value);
  const searchByName = (data) =>
    data
      .filter((row) => row.clientDetails)
      .filter(
        (row) =>
          row.clientDetails.name
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
      );

  let basicTotal = 0;
  let vatTotal = 0;
  let stumpDutyTotal = 0;
  let stickerFeeTotal = 0;
  let commissionTotal = 0;
  let trainingLevy = 0;

  let basicCurrentTotal = 0;
  let vatCurrentTotal = 0;
  let stumpDutyCurrentTotal = 0;
  let stickerFeeCurrentTotal = 0;
  let commissionCurrentTotal = 0;
  let trainingCurrentLevy = 0;

  !policies ||
    policies.map(
      (policy) =>
        !policy.stickersDetails ||
        (basicTotal += +policy.stickersDetails[0].totalPremium)
    ); // grand total for all policies
  !policies ||
    policies.map((policy) => !policy.stickersDetails || (vatTotal += 1080)); // grand total for all policies
  !policies ||
    policies.map(
      (policy) => !policy.stickersDetails || (stumpDutyTotal += 35000)
    ); // grand total for all policies
  !policies ||
    policies.map(
      (policy) => !policy.stickersDetails || (stickerFeeTotal += 6000)
    ); // grand total for all policies
  !policies ||
    policies.map(
      (policy) => !policy.stickersDetails || (commissionTotal += 2191)
    ); // grand total for all policies
  !policies ||
    policies.map(
      (policy) =>
        !policy.stickersDetails ||
        (trainingCurrentLevy += +policy.stickersDetails[0].trainingLevy)
    ); // grand total for all policies

  // {policy.stickersDetails && <td>{currencyFormatter(policy.stickersDetails[0].totalPremium)}</td>}

  // filter by range
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [switchStatus, setSwitchStatus] = useState(null);
  const [sortBasicAsc, setSortBasicAsc] = useState(false);
  const [sortBasicDes, setSortBasicDes] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [policiesPerPage] = useState(10);
  const indexOfLastPolicy = currentPage * policiesPerPage;
  const indexOfFirstPolicy = indexOfLastPolicy - policiesPerPage;
  const currentPolicies =
    !policies ||
    searchByName(
      policies.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );

  let shownPolicies =
    !policies ||
    currentPolicies
      .filter((policy) => !switchCategory || policy.category === switchCategory)
      .filter(
        (policy) =>
          (!currentDay && policy.policyStartDate !== undefined) ||
          policy.policyStartDate === currentDay
      )
      .filter(
        (policy) =>
          (!selectedMonth && policy.policyStartDate !== undefined) ||
          policy.policyStartDate.substring(5, 7) === selectedMonth
      )
      .filter(
        (policy) =>
          !selectedYear ||
          policy.policyStartDate.substring(0, 4) === selectedYear
      )
      .filter((policy) => !dateFrom || policy.policyStartDate >= dateFrom)
      .filter((policy) => !dateTo || policy.policyStartDate <= dateTo)
      .filter(
        (policy) =>
          !switchStatus || policy.stickersDetails[0].status === switchStatus
      )
      .sort(
        (a, b) =>
          convertStringToDate(b.createdAt) - convertStringToDate(a.createdAt)
      );

  if (sortBasicAsc) {
    shownPolicies = shownPolicies.sort(
      (a, b) =>
        b.stickersDetails[0].totalPremium - a.stickersDetails[0].totalPremium
    );
  }
  if (sortBasicDes) {
    shownPolicies = shownPolicies.sort(
      (a, b) =>
        a.stickersDetails[0].totalPremium - b.stickersDetails[0].totalPremium
    );
  }

  let paginatedShownPolicies =
    !policies || shownPolicies.slice(indexOfFirstPolicy, indexOfLastPolicy);

  const totalPagesNum =
    !policies || Math.ceil(shownPolicies.length / policiesPerPage);

  const isMobile = useMediaQuery("(max-width: 760px)");

  // console.log(isMobile)

  /* const sortByBasicPremium = () => {
      setShownPolicies2(shownPolicies.sort((a, b) => b.stickersDetails[0].totalPremium - a.stickersDetails[0].totalPremium))
  } */

  // console.log(shownPolicies)

  return (
    <div className="components">
      <div className="no-table-data tw-mt-20">
        <i>
          <ImFilesEmpty />
        </i>
        {/* <h4>No data yet</h4> */}
        <p>No Reports Yet</p>
      </div>
    </div>

  );
}

export default Reports;

import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppSpinner from "components/AppSpinner";
import CustomLoader from "components/CustomLoader";
import DropDown from "components/DropDown";
import SearchBar from "components/searchBar/SearchBar";
import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import useInsuranceProductsRequest from "hooks/requests/useInsuranceProductsRequest";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ImFileEmpty } from "react-icons/im";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { authentication, db } from "helpers/firebase";


const ListProducts = () => {
    const { isLoading, products, fetchProducts } = useInsuranceProductsRequest()
    const [searchText, setSearchText] = useState("")
    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = useState(false)
    const apiRequest = useApiRequest();
    const { authClaims, currentUser } = useAuth();
    const logCollectionRef = collection(db, "logs");


    useEffect(() => {
        fetchProducts()
    }, [])

    const handleSearch = () => {

    }
    const editProduct = (id) => {

    }
    const deleteProduct = async (productId, productName) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#132f50',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProductConfirmed(productId, productName)
            }
        })
    }

    const deleteProductConfirmed = async (productId, productName) => {
        setIsDeleting(true);
        try {
            const response = await apiRequest({
                method: 'post',
                url: BASE_URL + "/api/insurance-products/delete/" + productId,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            fetchProducts()
            toast.success(`successfully deleted product`, { position: "top-center" });
            addDoc(logCollectionRef, {
                timeCreated: `${new Date().toISOString().slice(0, 10)} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                type: 'product deleted',
                status: 'successful',
                message: `Successfully deleted product: ${productName} by ${authentication.currentUser.displayName}`
            })
        } catch (error) {
            toast.error(`unable to delete product`, { position: "top-center" });

        } finally {
            setIsDeleting(false);
        }
    }

    return (
        <div className="components">
            <header className="tw-mx-5 tw-flex">
                <div className="nk-block-between tw-flex-grow">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            Products
                        </h1>
                        <p className="tw-text-sm tw-text-gray-500">Manage Insurance Products</p>
                    </div>
                </div>
                <Link to="/admin/add-product">
                    <button className="btn cta m-2">New Product</button>
                </Link>
            </header>
            {isDeleting && (
                <div className="loader-wrapper">
                    <CustomLoader message={"Please wait..."} />
                </div>
            )}

            {
                isLoading === false && products.length == 0 ?
                    <div className="no-table-data">
                        <i>
                            <ImFileEmpty />
                        </i>
                        <h4>No data yet</h4>
                        <p>You have not added any product Yet</p>
                    </div>
                    :
                    <div className="componentsData shadow-sm table-card mb-3">
                        {isLoading && (
                            <div className="loader-wrapper">
                                <CustomLoader message={"Please wait..."} />
                            </div>
                        )}
                        <div className=" tw-flex tw-justify-end">
                            {/* <div className="tw-w-1/3" id="search">
                                <SearchBar
                                    placeholder={"Search Client by name"}
                                    value={searchText}
                                    handleSearch={handleSearch}
                                />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
                            </div> */}
                        </div>
                        <Table hover striped responsive className="mt-4" id="myTable">
                            <thead>
                                <tr>
                                    <th>Policy Name</th>
                                    <th>Type</th>
                                    {/* <th>Category</th> */}
                                    <th>Description</th>
                                    <th>Vat</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item.policyName}</td>
                                            <td>{item.policyType}</td>
                                            {/* <td>{item.policyCategory}</td> */}
                                            <td>{item.description}</td>
                                            <td>{item.VatPercentage ? `${item.VatPercentage}%` : "n/a"}</td>
                                            <td className="started">
                                                <DropDown items={[
                                                    { label: "Edit", onClick: () => navigate("/admin/edit-product/" + item.id) },
                                                    { label: "Delete", onClick: () => deleteProduct(item.id, item.policyName) }
                                                ]} />
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>

                        {
                            isLoading &&
                            <AppSpinner />
                        }


                    </div>
            }




        </div>
    )
}

export default ListProducts;
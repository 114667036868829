import { faPaperclip, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomLoader from 'components/CustomLoader'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useUploadFileRequest from 'hooks/uploadFileRequest';
import useApiRequest from 'hooks/apiRequest';
import { BASE_URL } from 'helpers/constants';
import useAuth from 'contexts/Auth';
import { authentication } from 'helpers/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { getFilenameFromURL } from 'helpers/helpfulUtilities';
import { ERROR_LOG, SUCCESS_LOG, logAction } from 'helpers/utils/logsHelper';
import useInsuranceProductsRequest from 'hooks/requests/useInsuranceProductsRequest';

export default function NewProduct() {
  const [isLoading, setIsLoading] = useState(false);
  const { uploadFile } = useUploadFileRequest()
  const apiRequest = useApiRequest();
  const { authClaims, currentUser } = useAuth();
  const navigate = useNavigate()
  const [coversCount, setCoversCount] = useState(1);
  const [policyCovers, setPolicyCovers] = useState([]);
  const [attachment, setAttachment] = useState(null)
  const [attachmentUrl, setAttachmentUrl] = useState(null)
  const { insuranceProductId } = useParams();
  const [initailProductFeeData, setInitailProductFeeData] = useState([])
  const [initailMedicalDetailsData, setInitailMedicalDetailsData] = useState([])

  const { fetchProductDetails, isLoading: IsLoadingProduct, productDetails } = useInsuranceProductsRequest()
  useEffect(() => {
    if (insuranceProductId) {
      fetchProductDetails(insuranceProductId)
    }
  }, [insuranceProductId])

  const [formData, setFormData] = useState({
    policyName: "",
    description: "",
    policyType: "",
    VatPercentage: 0,
    fees: [],
    medicalDetails: []
  });

  useEffect(() => {
    if (productDetails) {
      console.log(productDetails)
      setFormData({
        policyName: productDetails.policyName,
        description: productDetails.description,
        policyType: productDetails.policyType,
        VatPercentage: productDetails.VatPercentage,
        attachment: productDetails.attachment,
        fees: productDetails.ProductFee ?? [],
        medicalDetails: productDetails.MedicalDetailsTerm ?? []
      })

      if (productDetails.attachment) {
        setAttachmentUrl(productDetails.attachment)
      }
      if (productDetails.ProductCoverage) {
        setPolicyCovers(productDetails.ProductCoverage)
        setCoversCount(productDetails.ProductCoverage.length > 0 ? productDetails.ProductCoverage.length : 1)
      }
      if (productDetails.ProductFee) {
        setInitailProductFeeData(productDetails.ProductFee)
      }
      if (productDetails.MedicalDetailsTerm) {
        setInitailMedicalDetailsData(productDetails.MedicalDetailsTerm)
      }
    }
  }, [productDetails])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = { ...formData }
    data.policyCovers = policyCovers;
    try {
      if (attachment != null) {
        const fileUrl = await uploadFile(attachment);
        data = { ...data, attachment: fileUrl }
      }
      await saveProductDetails(data);
    } catch (error) {
      setIsLoading(false)
      logAction(
        ERROR_LOG,
        "product creation",
        `Failed to ${insuranceProductId ? 'update' : 'create'} product: ${formData.policyName} by ${authentication.currentUser.displayName}`,
        error.message
      );
      toast.error(`Error saving customer: ${error.message}`, { position: "top-center" });
    }

  }

  const saveProductDetails = async (data) => {

    setIsLoading(true);
    let url = BASE_URL + "/api/insurance-products/create"
    if (insuranceProductId) {
      url = BASE_URL + "/api/insurance-products/update/" + insuranceProductId
    }

    try {
      const response = await apiRequest({
        method: 'post',
        url: url,
        data: data,
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      setIsLoading(false);
      toast.success(`successfully ${insuranceProductId ? 'updated' : 'created'}  a Product`, { position: "top-center" });
      logAction(
        SUCCESS_LOG,
        "product creation",
        `Successfully ${insuranceProductId ? 'updated' : 'created'} a product ${data.policyName} for by ${authentication.currentUser.displayName}`
      );
      navigate("/admin/products/all");

    } catch (error) {
      setIsLoading(false)
      logAction(
        ERROR_LOG,
        "product creation",
        `Failed to ${insuranceProductId ? 'update' : 'create'} an Insurance Product ${data.policyName} by ${authentication.currentUser.displayName}`,
        error.message
      );
      toast.error(`Error saving an Insurance Product: ${error.message}`, { position: "top-center" });
    }
  }

  const handlePolicyCoverChange = (index, field, value) => {
    const newPolicyCovers = [...policyCovers];
    const policyCover = newPolicyCovers[index] || {};
    policyCover[field] = value;
    newPolicyCovers[index] = policyCover;
    setPolicyCovers(newPolicyCovers);
  };

  const handleRemovePolicyCover = (index) => {
    setCoversCount((coversCount == 1) ? 1 : coversCount - 1)
    if (policyCovers.length > 1) {
      const newPolicyCovers = policyCovers.filter((_, i) => i !== index);
      setPolicyCovers(newPolicyCovers);
    }
  };

  return (
    <div className="components">
      <header className="tw-mx-5">
        <div className="nk-block-between">
          <div className="header-txt">
            <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
              New Product
            </h1>
            <p className="tw-text-sm tw-text-gray-500">ADD A NEW INSURANCE PRODUCT</p>
          </div>
        </div>
      </header>
      <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 tw-mt-0 mb-5 shadow-sm tw-relative">
        {(isLoading || IsLoadingProduct) && (
          <div className="loader-wrapper">
            <CustomLoader message={"Please wait..."} />
          </div>
        )}


        <Form name="form" onSubmit={handleSubmit}>

          <div className='tw-w-3/5 c-sm:tw-w-full c-md:tw-w-full c-lg:tw-w-full '>
            <Form.Group className=" mb-3 tw-w-full">
              <Form.Label htmlFor="category">  Policy Type  </Form.Label>
              <Form.Select required
                value={formData.policyType}
                onChange={(e) => setFormData({ ...formData, policyType: e.target.value })}
                className=" tw-w-full " aria-label="" id='policy_type' >
                <option value="">Select</option>
                <option value="medical">Medical</option>
                <option value="life">Life</option>
                <option value="childcare">Childcare</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 tw-w-full">
              <Form.Label htmlFor="category">Policy Name  </Form.Label>
              <Form.Control
                value={formData.policyName}
                onChange={(e) => setFormData({ ...formData, policyName: e.target.value })}
                id="name"
                type="text"
                required
                placeholder="Enter policy name"
              />
            </Form.Group>


            <p>Policy Coverage</p>
            {
              Array.from(Array(coversCount).keys()).map((i) => (
                <div className="tw-flex tw-gap-3" key={i}>
                  <div className="tw-h-full tw-grid tw-grid-cols-3 tw-flex-grow tw-gap-3 tw-mt-3">
                    <Form.Group className="mb-3 tw-w-full">
                      <Form.Control
                        value={policyCovers[i]?.cover}
                        type="text"
                        id={`cover-${i}`}
                        placeholder="Cover"
                        required
                        onChange={(e) => handlePolicyCoverChange(i, 'cover', e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 tw-w-full">
                      <Form.Control
                        value={policyCovers[i]?.limit}
                        type="number"
                        id={`limit-${i}`}
                        placeholder="Limit"
                        required
                        onChange={(e) => handlePolicyCoverChange(i, 'limit', e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 tw-w-full">
                      <Form.Control
                        value={policyCovers[i]?.cost}
                        type="text"
                        id={`cost-${i}`}
                        placeholder="Cost"
                        required
                        onChange={(e) => handlePolicyCoverChange(i, 'cost', e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  {i === 0 ? (
                    <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                      <input
                        onClick={() => setCoversCount(coversCount + 1)} type="button" value="+" className="btn cta"
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                      <FontAwesomeIcon onClick={() => { handleRemovePolicyCover(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                      />
                    </Form.Group>
                  )}
                </div>
              ))
            }

            <Form.Group className="mb-3 tw-w-full">
              <Form.Label htmlFor="category">Description  </Form.Label>
              <Form.Control
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                id="name"
                as="textarea" rows={3}
                required
                placeholder="Enter  policy description"
              />
            </Form.Group>
            {
              formData.policyType == 'medical' &&
              <MedicalDetails initialValues={initailMedicalDetailsData} medicalDetailsSet={(value) => setFormData({ ...formData, medicalDetails: value })} />
            }

            <Form.Group className="mb-3 tw-w-full">
              <Form.Label htmlFor="category">VAT Percentage  </Form.Label>
              <Form.Control
                value={formData.VatPercentage}
                onChange={(e) => setFormData({ ...formData, VatPercentage: e.target.value })}
                id="name"
                type="number"
                required
                placeholder="Enter VAT Rate (%)"
              />
            </Form.Group>

            <FeesInputs initialValues={initailProductFeeData} feesRecordsSet={(value) => setFormData({ ...formData, fees: value })} />

            <Form.Group className="mb-3">
              <Form.Label htmlFor='wording'>Attach Wording</Form.Label>
              <Form.Control id='wording' type="file" onChange={(event) => {
                setAttachment(event.target.files[0])
              }} />
              {/* {progress} */}
              {
                attachmentUrl &&
                <p className=" text-lg mt-4 tw-text-lg  ">
                  <FontAwesomeIcon icon={faPaperclip} /> &nbsp;
                  <a className="tw-underline" target="_blank" href={attachmentUrl}>{getFilenameFromURL(attachmentUrl)}</a>
                </p>
              }

            </Form.Group>

            <input type="submit" value="Submit Product" className="btn cta mt-4" />
          </div>

        </Form>
      </div>

    </div>
  )
}


const MedicalDetails = ({ medicalDetailsSet, initialValues }) => {
  const [detailsCount, setDetailsCount] = useState(1);
  const [medicalDetails, setMedicalDetails] = useState([]);

  useEffect(() => {
    if (initialValues) {
      setDetailsCount(initialValues.length > 0 ? initialValues.length : 1)
      setMedicalDetails(initialValues)
    }
  }, [initialValues])

  useEffect(() => {
    medicalDetailsSet(medicalDetails)
  }, [medicalDetails])

  const handleMedicalDetailChange = (index, field, value) => {
    const newMedicalDetails = [...medicalDetails];
    const medicalDetail = newMedicalDetails[index] || {};
    medicalDetail[field] = value;
    newMedicalDetails[index] = medicalDetail;
    setMedicalDetails(newMedicalDetails);
  };

  const handleRemoveMedicalDetails = (index) => {
    setDetailsCount((detailsCount == 1) ? 1 : detailsCount - 1)
    if (medicalDetails.length > 1) {
      const newMedicalDetails = medicalDetails.filter((_, i) => i !== index);
      setMedicalDetails(newMedicalDetails);
    }
  };

  return (
    <>
      <p>Medical Details</p>
      {
        Array.from(Array(detailsCount).keys()).map((i) => (
          <div className="tw-flex tw-gap-3" key={i}>
            <div className="tw-h-full tw-grid tw-grid-cols-3 tw-flex-grow tw-gap-3 tw-mt-3">
              <Form.Group className="mb-3 tw-w-full">
                <Form.Control
                  value={medicalDetails[i]?.diagnosis}
                  type="text"
                  id={`diagnosis-${i}`}
                  placeholder="Diagnosis"
                  onChange={(e) => handleMedicalDetailChange(i, 'diagnosis', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 tw-w-full">
                <Form.Control
                  value={medicalDetails[i]?.unitCost}
                  type="text"
                  id={`cost-${i}`}
                  placeholder="Unit Cost"
                  onChange={(e) => handleMedicalDetailChange(i, 'unitCost', e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3 tw-w-full">
                <Form.Control
                  value={medicalDetails[i]?.limit}
                  type="number"
                  id={`limit-${i}`}
                  placeholder="Limit"
                  onChange={(e) => handleMedicalDetailChange(i, 'limit', e.target.value)}
                />
              </Form.Group>

            </div>
            {i === 0 ? (
              <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                <input
                  onClick={() => setDetailsCount(detailsCount + 1)} type="button" value="+" className="btn cta"
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                <FontAwesomeIcon onClick={() => { handleRemoveMedicalDetails(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                />
              </Form.Group>
            )}
          </div>
        ))
      }
    </>
  )
}



const FeesInputs = ({ feesRecordsSet, initialValues }) => {
  const [feesInputCount, setFeesInputCount] = useState(1);
  const [fees, setFees] = useState([]);

  useEffect(() => {
    if (initialValues) {
      setFeesInputCount(initialValues.length > 0 ? initialValues.length : 1)
      setFees(initialValues)
    }
  }, [initialValues])

  useEffect(() => {
    feesRecordsSet(fees)
  }, [fees])

  const handleFeeChange = (index, field, value) => {
    const newFees = [...fees];
    const fee = newFees[index] || {};
    fee[field] = value;
    newFees[index] = fee;
    setFees(newFees);
  };

  const handleRemoveFees = (index) => {
    setFeesInputCount((feesInputCount == 1) ? 1 : feesInputCount - 1)
    if (fees.length > 1) {
      const newFees = fees.filter((_, i) => i !== index);
      setFees(newFees);
    }
  };

  return (
    <>
      <p>Fees</p>
      {
        Array.from(Array(feesInputCount).keys()).map((i) => (
          <div className="tw-flex tw-gap-3" key={i}>
            <div className="tw-h-full tw-grid tw-grid-cols-2 tw-flex-grow tw-gap-3 tw-mt-3">
              <Form.Group className="mb-3 tw-w-full">
                <Form.Control
                  value={fees[i]?.title}
                  type="text"
                  id={`title-${i}`}
                  placeholder="Enter name of the fee"
                  onChange={(e) => handleFeeChange(i, 'title', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 tw-w-full">
                <Form.Control
                  value={fees[i]?.charge}
                  type="text"
                  id={`charge-${i}`}
                  placeholder="Charge"
                  onChange={(e) => handleFeeChange(i, 'charge', e.target.value)}
                />
              </Form.Group>

            </div>
            {i === 0 ? (
              <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                <input
                  onClick={() => setFeesInputCount(feesInputCount + 1)} type="button" value="+" className="btn cta"
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                <FontAwesomeIcon onClick={() => { handleRemoveFees(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                />
              </Form.Group>
            )}
          </div>
        ))
      }
    </>
  )
}
import { authentication } from "./firebase";
import { functions, db } from "./firebase";
import { httpsCallable } from "firebase/functions";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";

// convert a string date to a date.
export const convertStringToDate = (stringDate) => {
  return new Date(stringDate);
};

// convert a number to n hours and n minutes
export const timeConvert = (num) => {
  const totalhours = num / 60;
  const hours = Math.floor(totalhours);
  const minutes = Math.round((totalhours - hours) * 60);
  return hours + " hour(s) and " + minutes + " minute(s).";
};

// getting users.
export const getUsers = async (userType, role = "") => {
  const listUsers = httpsCallable(functions, "listUsers");
  return await listUsers()
    .then((results) => {
      const users = results.data.filter((user) => user.role[userType]);
      const myUsers = role
        ? users
        : users
          .filter(
            (client) =>
              client.meta.added_by_uid === authentication.currentUser.uid
          )
          .slice(0, 5);
      return myUsers.length === 0 ? null : myUsers;
    })
    .catch((err) => {
      return "failed";
    });
};

// multiple select users
export const handleAllCheck = (users, setDeleteArray) => {
  if (document.getElementById("onlyagent").checked === true) {
    Object.values(document.getElementsByClassName("agentCheckbox")).map(
      (checkbox) => (checkbox.checked = true)
    );
    setDeleteArray(
      users.map((supervisor) => [supervisor.uid, supervisor.name])
    );
  } else {
    Object.values(document.getElementsByClassName("agentCheckbox")).map(
      (checkbox) => (checkbox.checked = false)
    );
    setDeleteArray([]);
  }
};

// multiple select stickers
export const handleAllCheckStickers = (stickers, setDeleteArray) => {
  if (document.getElementById("onlyagent").checked === true) {
    Object.values(document.getElementsByClassName("agentCheckbox")).map(
      (checkbox) => (checkbox.checked = true)
    );
    setDeleteArray(
      stickers.map((sticker) => [sticker.id, sticker.clientDetails.name])
    );
  } else {
    Object.values(document.getElementsByClassName("agentCheckbox")).map(
      (checkbox) => (checkbox.checked = false)
    );
    setDeleteArray([]);
  }
};

export const getStickers = async (category, usersUnder) => {
  const policyCollectionRef = collection(db, "policies");
  const data = await getDocs(policyCollectionRef);
  const policiesArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  const mtpPolicies = policiesArray
    .filter((policy) => policy.category === category)
    .filter((policy) => usersUnder.includes(policy.added_by_uid))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return mtpPolicies;
};

export const getAllStickers = async (usersUnder) => {
  const policyCollectionRef = collection(db, "policies");
  const data = await getDocs(policyCollectionRef);
  const policiesArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  const mtpPolicies = policiesArray
    .filter((policy) => usersUnder.includes(policy.added_by_uid))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return mtpPolicies;
};

export const getAllClaims = async (usersUnder) => {
  const claimsCollectionRef = collection(db, "claims");
  const data = await getDocs(claimsCollectionRef);
  const claimsArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  const userClaims = claimsArray
    .filter((claim) => usersUnder.includes(claim.uid))
    .sort((a, b) => new Date(b.dateReported) - new Date(a.dateReported));
  return userClaims;
};

export const getSuperAdminStickers = async (category) => {
  const policyCollectionRef = collection(db, "policies");
  const data = await getDocs(policyCollectionRef);
  const policiesArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  const mtpPolicies = policiesArray
    .filter((policy) => policy.category === category)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return mtpPolicies;
};

// fetching superadmin claims
export const getAllSuperAdminStickers = async () => {
  const policyCollectionRef = collection(db, "policies");
  const data = await getDocs(policyCollectionRef);
  const policiesArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  const mtpPolicies = policiesArray.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  return mtpPolicies;
};

export const getAllSuperAdminClaims = async () => {
  const claimsCollectionRef = collection(db, "claims");
  const data = await getDocs(claimsCollectionRef);
  const claimsArray = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  const userClaims = claimsArray.sort(
    (a, b) => new Date(b.dateReported) - new Date(a.dateReported)
  );
  return userClaims;
};


export const genUUID = () => {
  let lut = []; for (let i = 0; i < 256; i++) { lut[i] = (i < 16 ? '0' : '') + (i).toString(16); }
  let d0 = Math.random() * 0xffffffff | 0;
  let d1 = Math.random() * 0xffffffff | 0;
  let d2 = Math.random() * 0xffffffff | 0;
  let d3 = Math.random() * 0xffffffff | 0;
  return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
    lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
    lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
    lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
}

export const getFilenameFromURL = (url) => {
  // Split the URL using '/' as the separator
  if (url) {
    // Match the filename using a regular expression
    const regex = /\/([^/?]+)\?/;
    const match = url.match(regex);

    if (match && match[1]) {
      // Decode the URI component to handle special characters
      const decodedFilename = decodeURIComponent(match[1]);
      return decodedFilename;
    }
  }
  return null;
}


export const generateCode = () => {
  // Get the current date in the format "DDMMYY"
  const currentDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }).replace(/\//g, ''); // Remove slashes if any

  // Generate a random 5-digit number
  const randomNum = Math.floor(Math.random() * 100000).toString().padStart(5, '0');

  // Combine the components to form the final code
  const code = `C${currentDate}${randomNum}`;
  return code;
}


export const getFileNameFromUrl = (url) => {
  if (url) {
    // Split the URL string by '/' to get an array of parts
    const parts = url.split('/');

    // Get the last part of the array, which contains the file name
    const lastPart = parts[parts.length - 1];

    // Split the last part by '_' to get an array of subparts
    const subparts = lastPart.split('_');

    // Get the first subpart (file name) and remove query parameters
    const fileName = subparts[1].split('?')[0];

    return fileName;
  }
  return null
}



export const convertSnakeCaseToTitle = (input) => {
  // Split the string by underscores and capitalize the first letter of each word
  const words = input.split('_');
  const titleWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Join the capitalized words with spaces
  const titleString = titleWords.join(' ');

  return titleString;
}


export const formatDate = (value, format) => {
  if (value) {
      return moment(value.toString()).format(format);
  }
  return ""
}

export const cleanAmount = (amount) => {
  return parseFloat(amount.replace(/,/g, ''))
}

import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppSpinner from "components/AppSpinner";
import CustomLoader from "components/CustomLoader";
import DropDown from "components/DropDown";
import DropdownButton from "components/DropdownButton";
import SearchBar from "components/searchBar/SearchBar";
import useAuth from "contexts/Auth";
import useCustomerPolicyRequest from "hooks/requests/useCustomerPolicyRequest";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ImFileEmpty } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import PolicyCard from "./PolicyCard";

const ListPolicy = () => {
    const { authClaims } = useAuth();
    const { isLoading, policies, fetchPolicies, loadingMore, hasMore, loadMore, searchPolicies } = useCustomerPolicyRequest()
    const [searchText, setSearchText] = useState("")
    const navigate = useNavigate()
    const [selectedPolicy, setSelectedPolicy] = useState(null)
    const [isPolicyCardOpen, setIsPolicyCardOpen] = useState(false)

    useEffect(() => {
        fetchPolicies()
    }, [])

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        searchPolicies(value);
    }
    const editPolicy = (id) => {
        navigate(authClaims.supervisor ? `/supervisor/update-policy/${id}` : `/agent/update-policy/${id}`);
    }

    const previewInsuraceCard = (item) => {
        setIsPolicyCardOpen(true)
        setSelectedPolicy(item)
    }

    const deletePolicy = () => {

    }

    const viewPolicyDetails = (id) => {
        navigate(authClaims.supervisor ? `/supervisor/policydetails/${id}` : `/agent/policydetails/${id}`);
    }


    return (
        <div className="components">
            <header className="tw-mx-5 tw-flex">
                <div className="nk-block-between tw-flex-grow">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            Policies
                        </h1>
                        <p className="tw-text-sm tw-text-gray-500">Manage Policies</p>
                    </div>
                </div>
                <Link to={
                    (authClaims.supervisor && "/supervisor/new-policy") ||
                    (authClaims.agent && "/agent/new-policy")
                }>
                    <button className="btn cta m-2">New Policy</button>
                </Link>

            </header>

            {
                loadingMore === false && policies.length == 0 && !searchText ?
                    <div className="no-table-data">
                        <i>
                            <ImFileEmpty />
                        </i>
                        <h4>No data yet</h4>
                        <p>You have not added any policy Yet</p>
                    </div>
                    :
                    <div className="componentsData shadow-sm table-card mb-3">
                        {isLoading && (
                            <div className="loader-wrapper">
                                <CustomLoader message={"Please wait..."} />
                            </div>
                        )}
                        <div className=" tw-flex tw-justify-end">
                            <div className="tw-w-1/3" id="search">
                                <SearchBar
                                    placeholder={"Search Client by name"}
                                    value={searchText}
                                    handleSearch={handleSearch}
                                />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
                            </div>
                        </div>
                        <Table hover striped responsive className="mt-4" id="myTable">
                            <thead>
                                <tr>
                                    <th>Customer Names</th>
                                    <th>Policy No.</th>
                                    <th>Policy Name</th>
                                    <th>Start Date</th>
                                    <th>Total Premium</th>
                                    <th>Total Limit</th>
                                    <th>Beneficiaries</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    policies.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item?.Customer?.name}</td>
                                            <td>{item.policyNumber ?? "N/A"}</td>
                                            <td>{item.policyName ?? "N/A"}</td>

                                            <td>{item.startDate}</td>
                                            <td>{getTotalPremium(item.PolicyCover).toLocaleString()}</td>
                                            <td>{getTotalLimit(item.PolicyCover).toLocaleString()}</td>
                                            <td>
                                                {
                                                    formatBeneficiaryNames(item.PolicyBeneficiary)
                                                }
                                            </td>
                                            <td className=" tw-justify-end tw-text-end">
                                                <span
                                                    onClick={() => viewPolicyDetails(item.id)}
                                                    className=" tw-text-blue-700 tw-cursor-pointer" >view details</span>
                                            </td>
                                            <td className="started">
                                                <DropDown items={[
                                                    { label: "Edit", onClick: () => editPolicy(item.id) },
                                                    { label: "Insurance Card", onClick: () => previewInsuraceCard(item) },

                                                    // { label: "Delete", onClick: deletePolicy(item.id) }
                                                ]} />
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>

                        {
                            loadingMore &&
                            <AppSpinner />
                        }
                        {
                            hasMore &&
                            <div className=" tw-flex tw-justify-center my-3">
                                <button onClick={() => loadMore()} className=" tw-bg-gray-100 tw-shadow hover:tw-bg-gray-200 tw-px-5  tw-rounded-lg py-2"> <FontAwesomeIcon icon={faRefresh} /> Load more</button>
                            </div>
                        }
                        <PolicyCard selectedPolicy={selectedPolicy} isOpen={isPolicyCardOpen} onClose={() => setIsPolicyCardOpen(false)} />


                    </div>
            }




        </div>
    )
}

export default ListPolicy;

export const formatBeneficiaryNames = (data) => {
    const beneficiaryNames = [];
    for (const beneficiary of data) {
        if (beneficiary.Customer) {
            beneficiaryNames.push(beneficiary?.Customer?.name);
        } else if (beneficiary.Dependent) {
            beneficiaryNames.push(beneficiary.Dependent.name);
        }
    }

    if (beneficiaryNames.length === 1) {
        return beneficiaryNames[0];
    } else if (beneficiaryNames.length === 2) {
        return beneficiaryNames.join(' and ');
    } else if (beneficiaryNames.length > 2) {
        const lastBeneficiary = beneficiaryNames.pop();
        return `${beneficiaryNames.join(', ')}, and ${lastBeneficiary}`;
    }
    return 'n/a';
}

export const getTotalPremium = (PolicyCover) => {
    let total = 0;
    for (const item of PolicyCover) {
        total += item.cost
    }
    return total
}

export const getTotalLimit = (PolicyCover) => {
    let total = 0;
    for (const item of PolicyCover) {
        total += item.limit
    }
    return total
}

import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomLoader from 'components/CustomLoader';
import useAuth from 'contexts/Auth';
import useCustomerClaimRequest from 'hooks/requests/useClaimsRequest';
import useCustomerPolicyRequest from 'hooks/requests/useCustomerPolicyRequest';
import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'

const ClaimDetail = () => {
  const { authClaims } = useAuth();
  const { loadingDetails: isLoading, claimDetails, getClaimDetails } = useCustomerClaimRequest()
  const { claimId } = useParams();

  useEffect(() => {
    getClaimDetails(claimId)
  }, [claimId])

  return (
    <>

      <div className="components">
        <header className="tw-mx-5 tw-flex">
          <div className="nk-block-between tw-flex-grow">
            <div className="header-txt">
              <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                Claim Details
              </h1>
              <p className="tw-text-sm tw-text-gray-500">Review details about claim</p>
            </div>
          </div>

        </header>
        {isLoading && (
          <div className="loader-wrapper">
            <CustomLoader message={"Please wait..."} />
          </div>
        )}
        {
          claimDetails &&
          <div>
            <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 px-4 py-4  mb-5 shadow-sm tw-relative">


              <div className=' tw-grid tw-grid-cols-3 c-sm:tw-grid-cols-1 c-md:tw-grid-cols-1 c-lg:tw-grid-cols-1'>
                <div className=' tw-flex tw-flex-col tw-gap-1'>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Customer</span>: {claimDetails?.Customer?.name ?? "N/A"}</p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Customer No.</span>: {claimDetails?.Customer?.referenceNumber ?? "N/A"}</p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>NIN</span>:  {claimDetails?.Customer?.nin ?? "N/A"}</p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Gender</span>:  <span className=' tw-capitalize'>{claimDetails?.Customer?.gender ?? "N/A"}</span></p>
                </div>
                <div className=' tw-flex tw-flex-col tw-gap-1'>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Phone</span>:
                    {claimDetails?.Customer?.phone ?? "N/A"}</p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Claim Amount</span>: {claimDetails.amountOfClaim.toLocaleString() ?? "N/A"}</p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Status</span>: <span className=' tw-capitalize'> {claimDetails.status}</span></p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Claim Date</span>: {claimDetails.dateOfClaim}</p>
                </div>
                <div className=' tw-flex tw-flex-col tw-gap-1'>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Policy No</span>: {claimDetails.Policy.policyNumber ?? "N/A"}</p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Policy Type</span>: {claimDetails.Policy.InsuranceProduct?.policyType ?? "N/A"}</p>
                  <p className=' tw-font-medium'> <span className=' tw-text-gray-500'>Policy Name</span>: {claimDetails.Policy.InsuranceProduct?.policyName ?? "N/A"}</p>
                </div>
              </div>
            </div>

            <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 px-4 py-4  mb-5 shadow-sm tw-relative">
              <p className=' tw-text-lg tw-font-semibold'>Claim Beneficiaries</p>
              <Table hover striped responsive className="mt-4" id="myTable">
                <thead>
                  <tr>
                    <th>Names</th>
                    <th>Ref</th>
                    <th>Gender</th>
                    <th>Dob</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    claimDetails.ClaimBeneficiary.map((item, key) =>
                      <tr key={key}>
                        <td>{item.Customer ? item?.Customer?.name : item.Dependent.name}</td>
                        <td>{item.Customer ? item?.Customer?.referenceNumber : item.Dependent.dependentNumber}</td>
                        <td>{item.Customer ? item?.Customer?.gender : item.Dependent.gender}</td>
                        <td>{item.Customer ? item?.Customer?.dob : item.Dependent.dob}</td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
            </div>


            <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 px-4 py-4  mb-5 shadow-sm tw-relative">
              <p className=' tw-text-lg tw-font-semibold'>More Details</p>

              <div className=' tw-border p-3 tw-flex tw-flex-col tw-gap-3'>
                <div className=''>
                  <p>Amount of Claim:</p>
                  <p className=' tw-font-semibold'>{claimDetails.amountOfClaim.toLocaleString() ?? "N/A"}</p>
                </div>
                <div className=''>
                  <p>Date of Claim:</p>
                  <p className=' tw-font-semibold'>{claimDetails.dateOfClaim ?? "N/A"}</p>
                </div>
                <div className=''>
                  <p>Status:</p>
                  <p className=' tw-font-semibold tw-capitalize'>{claimDetails.status ?? "N/A"}</p>
                </div>

                <div className=''>
                  <p>Description:</p>
                  <p className=' tw-font-semibold tw-capitalize'>{claimDetails.description ?? "N/A"}</p>
                </div>

                <div className=''>
                  <p>Diagnosis:</p>
                  {
                    claimDetails.MedicalClaimDiagnosis.map((item, key) =>
                      <p key={key} className=' tw-font-semibold'>{`${key + 1}. ${item.diagnosis}`}</p>
                    )
                  }
                  {/* <p className=' tw-font-semibold tw-capitalize'>{claimDetails.MedicalClaimDiagnosis.map((item) => item.diagnosis).join(',') ?? "N/A"}</p> */}
                  <p className=' tw-font-semibold'>{claimDetails.MedicalClaimDiagnosis.length == 0 && "N/A"}</p>
                </div>

                <div className=''>
                  <p>Treatments:</p>
                  {
                    claimDetails.TreatmentDetail.map((item, key) =>
                      <p key={key} className=' tw-font-semibold'>{`${key + 1}. Treatment: ${item.treatmentName??"n/a"}, Treating: ${item.treating??"n/a"}, Qty: ${item.quantity??"n/a"}, Unit Cost: ${item.unitCost??"n/a"}, Total: ${item.total??"n/a"}`}</p>
                    )
                  }
                  <p className=' tw-font-semibold'>{claimDetails.TreatmentDetail.length == 0 && "N/A"}</p>
                </div>


                <div className=''>
                  <p>File Attachments:</p>
                  {
                    claimDetails.ClaimAttachment.map((item, key) =>
                      <p className=" tw-underline">
                        <FontAwesomeIcon icon={faPaperclip} /> &nbsp;
                        <a className="" target="_blank" href={item.filePath}>{item.nameOfFile}</a>
                      </p>
                    )
                  }
                  <p className=' tw-font-semibold'>{claimDetails.ClaimAttachment.length == 0 && "N/A"}</p>

                </div>
              </div>



            </div>
          </div>
        }


      </div>
    </>
  )
}

export default ClaimDetail
import React, { useState, useEffect, useRef } from "react";

const DropDown = ({ items }) => {
    const [show, setShow] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShow(false);
            }
        };
        // Adding the event listener
        document.addEventListener("mousedown", handleClickOutside);
        // Cleanup: Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <button onClick={() => setShow(!show)} className="sharebtn">
                &#8942;
            </button>
            <ul
                ref={dropdownRef}
                id="mySharedown"
                className={`mydropdown-menu ${show && "show"}`}
            >
                {items.map((item, key) => (
                    <li
                        onClick={()=>item.onClick()}
                        key={key}>
                        <div className="actionDiv">{item.label}</div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default DropDown;

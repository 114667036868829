import useAuth from 'contexts/Auth';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function usePermissionsHelper() {
    const navigate = useNavigate()
    const [userType, setUserType] = useState(null)
    const { authClaims,currentOrganisation } = useAuth();
    useEffect(() => {
        let userType = null;
        switch (true) {
            case authClaims.superadmin:
                userType = "superadmin";
                break;
            case authClaims.admin:
                userType = "admin";
                break;
            case authClaims.agent:
                userType = "agent";
                break;
            case authClaims.supervisor:
                userType = "supervisor";
                break;
            default:
                userType = null;
                break;
        }
        setUserType(userType);
    }, [authClaims]);



    const userHasAnyOfThePermissions = (permissions) => {
        if (!permissions) {
            return true
        }
        if (permissions.length == 0) {
            return true
        }

        let foundPermission = false;
        if (currentOrganisation) {
            if (currentOrganisation.permissions) {
                for (const permission of permissions) {
                    if (currentOrganisation.permissions.includes(permission)) {
                        foundPermission = true;
                        break; // Exit the loop when a permission is found
                    }
                }
            }
        }

        return foundPermission;
    }

    const redirectIfHasNonOfPermissions = (permissions) => {
        let foundPermission = false;
        if (currentOrganisation) {
            if (currentOrganisation.permissions) {
                for (const permission of permissions) {
                    if (currentOrganisation.permissions.includes(permission)) {
                        foundPermission = true;
                        break; // Exit the loop when a permission is found
                    }
                }
            }
            if (!foundPermission) {
                navigate(`/${userType}/dashboard`)
            }
        }
    }

    return {
        userHasAnyOfThePermissions,
        redirectIfHasNonOfPermissions
    };
}

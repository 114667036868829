import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppSpinner from "components/AppSpinner";
import CustomLoader from "components/CustomLoader";
import DropDown from "components/DropDown";
import SearchBar from "components/searchBar/SearchBar";
import useAuth from "contexts/Auth";
import useCustomerClaimRequest from "hooks/requests/useClaimsRequest";
import { formatBeneficiaryNames } from "pages/ManagePolicies/ListPolicy";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ImFileEmpty } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";

const ListClaim = () => {
    const { authClaims } = useAuth();
    const { isLoading, claims, fetchClaims, loadingMore, hasMore, loadMore } = useCustomerClaimRequest()
    const [searchText, setSearchText] = useState("")
    const navigate = useNavigate()


    useEffect(() => {
        fetchClaims()
    }, [])

    const handleSearch = () => {

    }

    const editClaim = (id) => {
        navigate(authClaims.supervisor ? `/supervisor/update-claim/${id}` : `/agent/update-claim/${id}`);
    }
    
    const deleteClaim = () => {

    }

    const viewClaimDetails = (id) => {
        navigate(authClaims.supervisor ? `/supervisor/claim-details/${id}` : `/agent/claim-details/${id}`);
    }

    return (
        <div className="components">
            <header className="tw-mx-5 tw-flex">
                <div className="nk-block-between tw-flex-grow">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            Claims
                        </h1>
                        <p className="tw-text-sm tw-text-gray-500">Manage Claims</p>
                    </div>
                </div>
                
                <Link to={
                    (authClaims.supervisor && "/supervisor/new-claim") ||
                    (authClaims.agent && "/agent/new-claim")
                }>
                    <button className="btn cta m-2">New Claim</button>
                </Link>

            </header>

            {
                loadingMore === false && claims.length == 0 ?
                    <div className="no-table-data">
                        <i>
                            <ImFileEmpty />
                        </i>
                        <h4>No data yet</h4>
                        <p>You have not added any claim Yet</p>
                    </div>
                    :
                    <div className="componentsData shadow-sm table-card mb-3">
                        {isLoading && (
                            <div className="loader-wrapper">
                                <CustomLoader message={"Please wait..."} />
                            </div>
                        )}
                        <div className=" tw-flex tw-justify-end">
                            <div className="tw-w-1/3" id="search">
                                <SearchBar
                                    placeholder={"Search Client by name"}
                                    value={searchText}
                                    handleSearch={handleSearch}
                                />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
                            </div>
                        </div>
                        <Table hover striped responsive className="mt-4" id="myTable">
                            <thead>
                                <tr>
                                    <th>Customer</th>
                                    <th>Policy No.</th>
                                    <th>Date of claim</th>
                                    <th>Amount of Claim</th>
                                    <th>Claim Beneficiaries</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    claims.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item?.Customer?.name}</td>
                                            <td>{item.Policy.policyNumber??"N/A"}</td>
                                            <td>{item.dateOfClaim}</td>
                                            <td>{item.amountOfClaim.toLocaleString()}</td>
                                            <td>{formatBeneficiaryNames(item.ClaimBeneficiary)}</td>
                                            <td className=" tw-justify-end tw-text-end">
                                                <span
                                                    onClick={() => viewClaimDetails(item.id)}
                                                    className=" tw-text-blue-700 tw-cursor-pointer" >view details</span>
                                            </td>
                                            <td className="started">
                                                <DropDown items={[
                                                    { label: "Edit", onClick:()=> editClaim(item.id) },
                                                ]} />
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>

                        {
                            loadingMore &&
                            <AppSpinner />
                        }
                        {
                            hasMore &&
                            <div className=" tw-flex tw-justify-center my-3">
                                <button onClick={() => loadMore()} className=" tw-bg-gray-100 tw-shadow hover:tw-bg-gray-200 tw-px-5  tw-rounded-lg py-2"> <FontAwesomeIcon icon={faRefresh} /> Load more</button>
                            </div>
                        }

                    </div>
            }




        </div>
    )
}

export default ListClaim;
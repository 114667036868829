import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "../../assets/styles/addClients.css";
import Header from "../../components/header/Header";
import { authentication, db } from "../../helpers/firebase";
import { collection, addDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { useForm } from "../../hooks/useForm";
import Loader from "../../components/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Chat from "../../components/messenger/Chat";

// firebase storage..
import { storage } from "../../helpers/firebase";
import "../../styles/ctas.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faPaperclip, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom/dist";
import { now } from "moment";
import { genUUID, getFilenameFromURL } from "helpers/helpfulUtilities";

export default function AddPolicy({ parent_container }) {
  useEffect(() => {
    document.title = "Add Policy | Core Insurance Management";
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [termsCount, setTermsCount] = useState(1);
  const [attachment, setAttachment] = useState('')
  const [attachmentUrl, setAttachmentUrl] = useState(null)
  const [policyName, setPolicyName] = useState('')
  const [policyCategory, setPolicyCategory] = useState('')
  const [policyTerms, setPolicyTerms] = useState([]);
  const [progress, setProgress] = useState(0)
  const basePolicyCollectionRef = collection(db, 'policy_products')
  const logCollectionRef = collection(db, "logs");
  const navigate = useNavigate()
  const { policyId } = useParams();
  const [policyData, setPolicyData] = useState(null);

  useEffect(() => {
    if (policyId) {
      // Fetch the policy data using the policyId parameter
      const fetchPolicyData = async () => {
        try {
          const policyDocRef = doc(db, "policy_products", policyId);
          const policyDocSnap = await getDoc(policyDocRef);
          if (policyDocSnap.exists()) {
            const policyData = policyDocSnap.data();
            setPolicyData(policyData);

            // Update the form fields with the fetched policy data
            setPolicyName(policyData.policyName);
            setPolicyCategory(policyData.policyCategory);
            setPolicyTerms(policyData.policyTerms);
            setAttachmentUrl(policyData.attachment)
            setTermsCount(policyData.policyTerms.length)
          } else {
            // Policy not found
            console.log("Policy not found");
          }
        } catch (error) {
          console.log("Error fetching policy data:", error);
        }
      };

      // Call the fetchPolicyData function
      fetchPolicyData();
    }
  }, [policyId]);


  const handlePolicyTermChange = (index, field, value) => {
    const newPolicyTerms = [...policyTerms];
    const policyTerm = newPolicyTerms[index] || {};
    policyTerm[field] = value;
    newPolicyTerms[index] = policyTerm;
    setPolicyTerms(newPolicyTerms);
  };

  const handleRemovePolicyTerm = (index) => {
    setTermsCount((termsCount == 1) ? 1 : termsCount - 1)
    if (policyTerms.length > 1) {
      const newPolicyTerms = policyTerms.filter((_, i) => i !== index);
      setPolicyTerms(newPolicyTerms);
    }
  };

  const sendRecordsToDatabase = async (fields) => {
    if (policyId) {
      const policyDocRef = doc(db, "policy_products", policyId);
      await Promise.all([
        updateDoc(policyDocRef, fields),
        addDoc(logCollectionRef, {
          timeCreated: `${new Date().toISOString().slice(0, 10)} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
          type: 'policy updated',
          status: 'successful',
          message: `Successfully updated policy: ${fields.policyName} by ${authentication.currentUser.displayName}`
        })
      ]);
    }else{
      await Promise.all([
        addDoc(basePolicyCollectionRef, fields),
        addDoc(logCollectionRef, {
          timeCreated: `${new Date().toISOString().slice(0, 10)} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
          type: 'policy creation',
          status: 'successful',
          message: `Successfully created policy: ${fields.policyName} by ${authentication.currentUser.displayName}`
        })
      ]);
    }
    
    toast.success(`successfully ${policyId ? 'updated' : 'added'} ${fields.policyName} Policy`, { position: "top-center" });
    setIsLoading(false);
    navigate("/admin/policy/all");
  }

  const savePolicy = async (event) => {
    event.preventDefault();

    const fields = {
      createdBy: authentication.currentUser.uid,
      policyName,
      policyCategory,
      policyTerms,
      createdAt: now()
    };

    setIsLoading(true);
    try {
      if (attachment) {
        // const fileNameParts = selectedFile.name.split('.');
        // const fileExtension = fileNameParts[fileNameParts.length - 1];

        const storageRef = ref(storage, `uploads/${genUUID()}_${attachment.name}`);
        const uploadTask = uploadBytesResumable(storageRef, attachment);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
          },
          (error) => {
            console.log(error);
          },
          () => {
            // Upload completed successfully
            getDownloadURL(uploadTask.snapshot.ref)
              .then(async (downloadUrl) => {
                fields.attachment = downloadUrl;
                await sendRecordsToDatabase(fields)
              })
              .catch((error) => {
                console.log(error);
                toast.error(`Failed: couldn't ${policyId ? 'update' : 'add'} ${fields.policyName}`, { position: "top-center" });
                setIsLoading(false);
              });
          }
        );
      } else {
        if (!policyId ) {
          fields.attachment =null;
        }
        await sendRecordsToDatabase(fields)
      }

    } catch (error) {
      console.log(error);
      toast.error(`Failed: couldn't ${policyId ? 'update' : 'add'} ${fields.policyName}`, { position: "top-center" });

      await addDoc(logCollectionRef, {
        timeCreated: `${new Date().toISOString().slice(0, 10)} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
        type: `policy ${policyId ? 'update' : 'creation'} `,
        status: 'failed',
        message: `Failed to  ${policyId ? 'update' : 'add'} policy: ${fields.policyName} by ${authentication.currentUser.displayName}`
      });

      setIsLoading(false);
    }

  };


  return (
    <div /* className='components' */>
      <ToastContainer />
      <header className="tw-mx-5">
        <div className="nk-block-between">
          <div className="header-txt">
            <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
              {policyId ? 'Update Policy' : 'Add Policy'}
            </h1>
            {/* <p className="tw-text-sm tw-text-gray-500">ADD A NEW POLICY</p> */}
          </div>
        </div>
      </header>
      <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 tw-mt-0 mb-5 shadow-sm">
        {isLoading && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}

        <div id="addPolicyForm">
          <Form name="form2" onSubmit={savePolicy}>
            <div className="organisation-columns">
              <div
                style={{
                  padding: "1rem",
                }}
              >
                <Form.Group className="mb-3 tw-w-full ">
                  <Form.Label htmlFor="category">  Policy Name </Form.Label>
                  <Form.Select required value={policyName} onChange={(e) => setPolicyName(e.target.value)} className=" tw-w-full " aria-label="" id='policy_name' >
                    <option value="">Select</option>
                    <option value="medical">Medical</option>
                    <option value="life">Life</option>
                    <option value="childcare">Childcare</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3 tw-w-full ">
                  <Form.Label htmlFor="category">  Policy Category </Form.Label>
                  <Form.Select value={policyCategory} onChange={(e) => setPolicyCategory(e.target.value)} className=" tw-w-full " aria-label="" id='policy_category' >
                    <option value="">Select</option>
                    <option value="option 1">Option 1</option>
                    <option value="option 2">Option 2</option>
                    <option value="option 3">Option 3</option>
                  </Form.Select>
                </Form.Group>


                <p>Policy Terms</p>
                {
                  Array.from(Array(termsCount).keys()).map((i) => (
                    <div className="tw-flex tw-gap-3" key={i}>
                      <div className="tw-h-full tw-grid tw-grid-cols-3 tw-flex-grow tw-gap-3 tw-mt-3">
                        <Form.Group className="mb-3 tw-w-full">
                          <Form.Control
                            value={policyTerms[i]?.term}
                            type="text"
                            id={`term-${i}`}
                            placeholder="Term"
                            required
                            onChange={(e) => handlePolicyTermChange(i, 'term', e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 tw-w-full">
                          <Form.Control
                            value={policyTerms[i]?.limit}
                            type="number"
                            id={`limit-${i}`}
                            placeholder="Limit"
                            required
                            onChange={(e) => handlePolicyTermChange(i, 'limit', e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 tw-w-full">
                          <Form.Control
                            value={policyTerms[i]?.cost}
                            type="text"
                            id={`cost-${i}`}
                            placeholder="Cost"
                            required
                            onChange={(e) => handlePolicyTermChange(i, 'cost', e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      {i === 0 ? (
                        <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                          <input
                            onClick={() => setTermsCount(termsCount + 1)} type="button" value="+" className="btn cta"
                          />
                        </Form.Group>
                      ) : (
                        <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                          <FontAwesomeIcon onClick={() => { handleRemovePolicyTerm(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                          />
                        </Form.Group>
                      )}
                    </div>
                  ))
                }

                <Form.Group className="mb-3">
                  <Form.Label htmlFor='wording'>Attach Wording</Form.Label>
                  <Form.Control  id='wording' type="file" onChange={(event) => {
                    setAttachment(event.target.files[0])
                  }} />
                  {/* {progress} */}
                  {
                    attachmentUrl &&
                    <p className=" text-lg mt-4 tw-text-lg  ">
                      <FontAwesomeIcon icon={faPaperclip} /> &nbsp;
                      <a className="tw-underline" target="_blank" href={attachmentUrl}>{getFilenameFromURL(attachmentUrl)}</a>
                    </p>
                  }

                </Form.Group>
              </div>
            </div>
            <div id="submit">
              <input type="submit" value={policyId?'Update Policy':'Add Policy'} className="btn cta" />
            </div>
          </Form>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          position: "fixed",
          bottom: "0px",
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={
          parent_container ? "chat-container" : "expanded-menu-chat-container"
        }
      >
        <Chat />
      </div>
    </div>
  );
}

import { faPaperclip, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomLoader from 'components/CustomLoader'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import useUploadFileRequest from 'hooks/uploadFileRequest';
import useApiRequest from 'hooks/apiRequest';
import { BASE_URL } from 'helpers/constants';
import useAuth from 'contexts/Auth';
import { authentication } from 'helpers/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { convertSnakeCaseToTitle, generateCode, getFilenameFromURL } from 'helpers/helpfulUtilities';
import { ERROR_LOG, SUCCESS_LOG, logAction } from 'helpers/utils/logsHelper';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterOptions, UseFormRegisterReturn, useForm } from "react-hook-form";
import { APP_PERMISSIONS } from 'helpers/app-permissions';
import AppButton from 'components/AppButton';
import useOrganisationsRequest from 'hooks/requests/useOrganisationsRequest';

export default function NewOrganisation() {
    const [isSaving, setIsSaving] = useState(false)
    const { uploadFile } = useUploadFileRequest()
    const apiRequest = useApiRequest();
    const { authClaims, currentUser } = useAuth();
    const [logoAttachement, setLogo] = useState(null)
    const navigate = useNavigate()
    const { organisationId } = useParams();
    const { fetchOrganisationDetails, organisationDetails, isLoading } = useOrganisationsRequest()
    const [initialPermissions, setInitialPermissions] = useState([])
    const [initialTerms, setInitialTerms] = useState([])

    useEffect(() => {
        if (organisationId) {
            fetchOrganisationDetails(organisationId)
        }
    }, [organisationId])

    useEffect(() => {
        if (organisationDetails) {
            reset(organisationDetails)

            if (organisationDetails.organisationTerms) {
                setInitialTerms(organisationDetails.organisationTerms)
            }

            if (organisationDetails.permissions) {
                setInitialPermissions(organisationDetails.permissions)
            }
        }
    }, [organisationDetails])



    const schema = yup.object({
        name: yup.string().required("Name is required"),
        companyCode: yup.string().required("Company code is required"),
        category: yup.string().required("Category is required"),
        org_email: yup.string().required("Organisation email is required"),
        tel: yup.string().required("Telephone is required"),
        contactName: yup.string().required("Contact person is required"),
        contactPhoneNumber: yup.string().required("Contact person phone is required"),
    });

    const form = useForm({
        defaultValues: {
            name: "",
            companyCode: generateCode(),
            category: '',
            org_email: '',
            tel: '',
            address: '',
            logo: "",
            role: '',
            title: '',
            contactName: '',
            contactPhoneNumber: '',
            contact_email: '',
            medicalDetails: []
        },
        resolver: yupResolver(schema),
    });
    const { register, handleSubmit, formState, watch, setValue, control, reset } = form;
    const { errors } = formState;
    const [organisationPermissions, setOrganisationPermissions] = useState([])

    const onSubmit = async (formData) => {
        setIsSaving(true);
        let data = { ...formData, permissions: organisationPermissions }
        try {
            if (logoAttachement != null) {
                const fileUrl = await uploadFile(logoAttachement);
                data = { ...data, logo: fileUrl }
            }
            await saveOrganisationDetails(data);
        } catch (error) {
            setIsSaving(false)
            logAction(
                ERROR_LOG,
                "organisation creation",
                `Failed to create organisation: ${data.name} by ${authentication.currentUser.displayName}`,
                error.message
            );
            toast.error(`Error saving organisation: ${error.message}`, { position: "top-center" });
        }
    };


    const saveOrganisationDetails = async (data) => {
        let url = BASE_URL + "/api/organisations/create"
        if (organisationId) {
            url = BASE_URL + "/api/organisations/update/" + organisationId
        }
        try {
            const response = await apiRequest({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            toast.success(`successfully ${organisationId ? 'updated' : 'created'} organisation`, { position: "top-center" });
            logAction(
                SUCCESS_LOG,
                "organisation creation",
                `Successfully ${organisationId ? 'updated' : 'created'} organisation: ${data.name} by ${authentication.currentUser.displayName}`
            );
            navigate(authClaims.superadmin ? "/superadmin/organisations" : "/admin/organisations");
        } catch (error) {
            console.log(error)
            if (error.response.data) {
                toast.error(`Error saving organisation: ${error.response.data.message}`, { position: "top-center" });
            } else {
                throw new Error(error);
            }
        } finally {
            setIsSaving(false);
        }
    }


    return (
        <div className="components">
            <header className="tw-mx-5">
                <div className="nk-block-between">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            {organisationId ? "Update" : "Add"} Organisation
                        </h1>
                        <p className="tw-text-sm tw-text-gray-500"> {organisationId ? "UPDATE" : "ADD A NEW"} ORGANISATION</p>
                    </div>
                </div>
            </header>
            <ToastContainer />

            {isLoading && (
                <div className="loader-wrapper">
                    <CustomLoader message={"Please wait..."} />
                </div>
            )}
            <Form className='px-0 py-0' onSubmit={handleSubmit(onSubmit)} noValidate>

                <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 tw-mt-0 mb-5 shadow-sm tw-relative px-4 py-4">

                    <div className='tw-w-3/5 c-sm:tw-w-full c-md:tw-w-full c-lg:tw-w-full '>

                        <Form.Group className="mb-3" >
                            <Form.Label htmlFor='category'>Category </Form.Label>
                            <Form.Select   {...register("category")}>
                                <option value="">--Select Category--</option>
                                <option value="hosipital">Hosipital</option>
                                <option value="pharmacy">Pharmacy</option>
                                <option value="clinic">Clinic</option>
                                <option value="mtp">Bank</option>
                                <option value="comprehensive">Brokers</option>
                                <option value="windscreen">MTN</option>
                                <option value="windscreen">Shell</option>
                                <option value="windscreen">others</option>
                            </Form.Select>
                            <p className="tw-text-xs tw-text-red-600">{errors.category?.message}</p>

                        </Form.Group>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="name">Organisation Name  </Form.Label>
                            <Form.Control
                                {...register("name")}
                                type="text"
                                required
                                placeholder="Orgainisation Name"
                            />
                            <p className="tw-text-xs tw-text-red-600">{errors.name?.message}</p>
                        </Form.Group>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="companyCode">Company Code  </Form.Label>
                            <Form.Control
                                {...register("companyCode")}
                                type="text"
                                readOnly
                                required
                                placeholder="Company Code"
                            />
                            <p className="tw-text-xs tw-text-red-600">{errors.companyCode?.message}</p>
                        </Form.Group>
                        <div className='tw-grid tw-grid-cols-2 tw-gap-3 mb-3 tw-w-full'>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="org_email">Organisation Email  </Form.Label>
                                <Form.Control
                                    {...register("org_email")}
                                    type="text"
                                    required
                                    placeholder="Organisation Email"
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.org_email?.message}</p>
                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="tel">Organisation Telephone</Form.Label>
                                <Form.Control
                                    {...register("tel")}
                                    type="text"
                                    required
                                    placeholder="Organisation Telephone"
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.tel?.message}</p>
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="address">Address</Form.Label>
                            <Form.Control
                                {...register("address")}
                                type="text"
                                required
                                placeholder="Company Address"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label htmlFor='logo'>Upload Logo</Form.Label>
                            <Form.Control id='logo' type="file" onChange={(event) => {
                                setLogo(event.target.files[0])
                            }} />
                        </Form.Group>

                        <div className='tw-grid tw-grid-cols-2 tw-gap-3 mb-3 tw-w-full'>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="contactName">Contact Person</Form.Label>
                                <Form.Control
                                    {...register("contactName")}
                                    type="text"
                                    required
                                    placeholder="Contact Person"
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.contactName?.message}</p>
                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="title">Title</Form.Label>
                                <Form.Control
                                    {...register("title")}
                                    type="text"
                                    required
                                    placeholder="e.g Mr./Mrs./Ms."
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.title?.message}</p>
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="role">Role  </Form.Label>
                            <Form.Control
                                {...register("role")}
                                type="text"
                                required
                                placeholder="Contact person  role"
                            />
                            <p className="tw-text-xs tw-text-red-600">{errors.role?.message}</p>
                        </Form.Group>

                        <div className='tw-grid tw-grid-cols-2 tw-gap-3 mb-3 tw-w-full'>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="contactPhoneNumber">Contact Phone</Form.Label>
                                <Form.Control
                                    {...register("contactPhoneNumber")}
                                    type="text"
                                    required
                                    placeholder="Contact person phone"
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.contactPhoneNumber?.message}</p>
                            </Form.Group>
                            <Form.Group className=" tw-w-full">
                                <Form.Label htmlFor="contact_email">Contact Email</Form.Label>
                                <Form.Control
                                    {...register("contact_email")}
                                    type="text"
                                    required
                                    placeholder="Contact person email"
                                />
                                <p className="tw-text-xs tw-text-red-600">{errors.contact_email?.message}</p>
                            </Form.Group>
                        </div>
                        <OrganisationTerms initialValues={initialTerms} organisationTermsSet={(value) =>
                            setValue("organisationTerms", value, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                            })
                        } />

                    </div>
                </div>
                <div className='tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 tw-mt-3 mb-5 shadow-sm tw-relative p-4 '>
                    <Form.Group className=" tw-w-full">
                        <Form.Label className=' tw-font-medium tw-text-lg tw-text-gray-600' htmlFor="contact_email">Organisation Permissions</Form.Label>
                    </Form.Group>
                    <PermissionsSection setOrganisationPermissions={setOrganisationPermissions}
                        initialPermissions={initialPermissions} />
                </div>
                <div className='tw-mx-5 mb-5 tw-relative tw-flex tw-justify-end '>
                    <AppButton buttonType={"submit"} callBackFun={() => { }} showLoader={isSaving}
                        spinnerClass={"tw-inline tw-w-3 tw-h-3 tw-mr-2 tw-white tw-animate-spin tw-fill-black"}
                        className="btn btn-dark cta submitcta  " text="Save Organisation" />
                    {/* <input type="submit" value="Submit Organisation" className="btn cta" /> */}
                </div>
            </Form>
        </div>
    )
}


const PermissionsSection = ({ setOrganisationPermissions, initialPermissions }) => {
    const permissions = APP_PERMISSIONS
    const [groupPermissions, setGroupPermissions] = useState({});

    useEffect(() => {
        const groupPermissionsMap = {};
        Object.keys(permissions).forEach((group) => {
            groupPermissionsMap[group] = permissions[group].map((permission) =>
                initialPermissions.includes(permission) ? "true" : "false"
            );
        });
        setGroupPermissions(groupPermissionsMap);
    }, [permissions, initialPermissions])

    useEffect(() => {
        setOrganisationPermissions(extractSelectedPermissions(groupPermissions))
    }, [groupPermissions])

    function extractSelectedPermissions(selectedPermissionsData) {
        const selectedPermissions = [];

        for (const groupTitle in selectedPermissionsData) {
            const selectedPermissionsInGroup = selectedPermissionsData[groupTitle];
            const originalPermissionsInGroup = permissions[groupTitle];

            for (let i = 0; i < selectedPermissionsInGroup.length; i++) {
                if (selectedPermissionsInGroup[i] === "true") {
                    selectedPermissions.push(originalPermissionsInGroup[i]);
                }
            }
        }

        return selectedPermissions;
    }


    // Handler for toggling a group's permissions
    const toggleGroupPermissions = (group, checked) => {
        const updatedPermissions = { ...groupPermissions };
        updatedPermissions[group] = permissions[group].map(() => (checked ? "true" : "false"));
        setGroupPermissions(updatedPermissions);
    };

    // Handler for toggling a single permission
    const togglePermission = (group, permission, checked) => {
        const updatedPermissions = { ...groupPermissions };
        updatedPermissions[group][permissions[group].indexOf(permission)] = checked ? "true" : "false";
        setGroupPermissions(updatedPermissions);
    };

    return (
        <>
            <div className=' tw-grid tw-grid-cols-3'>
                {Object.keys(permissions).map((group) => (
                    <div key={group}>
                        <div className="tw-flex tw-items-center tw-mt-4 tw-mb-3 tw-gap-1">
                            < Form.Group className="tw-mb-3" controlId="medical">
                                <Form.Check
                                    id={`groupTitle_${group}`}
                                    type="checkbox"
                                    checked={groupPermissions[group] ? groupPermissions[group].every((value) => value === "true") : false}
                                    onChange={(e) => toggleGroupPermissions(group, e.target.checked)}
                                />
                            </Form.Group>

                            {groupPermissions[group] && groupPermissions[group].every((value) => value === "true")}
                            <Form.Label className=' tw-font-semibold tw-uppercase' htmlFor={`groupTitle_${group}`} >{convertSnakeCaseToTitle(group)}  </Form.Label>
                        </div>
                        {permissions[group].map((permission) => (
                            <div key={permission} className="tw-flex tw-items-center tw-mt-3 tw-gap-1">
                                <Form.Group className="tw-mb-3" controlId="medical">
                                    <Form.Check
                                        id={`permission_${permission}`}
                                        type="checkbox"
                                        checked={groupPermissions[group] ? groupPermissions[group][permissions[group].indexOf(permission)] === "true" : false}
                                        onChange={(e) => togglePermission(group, permission, e.target.checked)}
                                    />
                                </Form.Group>
                                <Form.Label htmlFor={`permission_${permission}`} >{permission} </Form.Label>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
}



const OrganisationTerms = ({ organisationTermsSet, initialValues }) => {
    const [termsCount, setDetailsCount] = useState(1);
    const [organisationTerms, setOrganisationTerms] = useState([]);

    useEffect(() => {
        if (initialValues) {
            setDetailsCount(initialValues.length > 0 ? initialValues.length : 1)
            setOrganisationTerms(initialValues)
        }
    }, [initialValues])

    useEffect(() => {
        organisationTermsSet(organisationTerms)
    }, [organisationTerms])

    const handleOrganisationTermChange = (index, field, value) => {
        const newOrganisationTerms = [...organisationTerms];
        const organisationTerm = newOrganisationTerms[index] || {};
        organisationTerm[field] = value;
        newOrganisationTerms[index] = organisationTerm;
        setOrganisationTerms(newOrganisationTerms);
    };

    const handleRemoveOrganisationTerms = (index) => {
        setDetailsCount((termsCount == 1) ? 1 : termsCount - 1)
        if (organisationTerms.length > 1) {
            const newOrganisationTerms = organisationTerms.filter((_, i) => i !== index);
            setOrganisationTerms(newOrganisationTerms);
        }
    };

    return (
        <>
            <p>Medical Details</p>
            {
                Array.from(Array(termsCount).keys()).map((i) => (
                    <div className="tw-flex tw-gap-3" key={i}>
                        <div className="tw-h-full tw-grid tw-grid-cols-3 tw-flex-grow tw-gap-3 tw-mt-3">
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Control
                                    value={organisationTerms[i]?.term}
                                    type="text"
                                    id={`term-${i}`}
                                    placeholder="Term"
                                    onChange={(e) => handleOrganisationTermChange(i, 'term', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Control
                                    value={organisationTerms[i]?.unitCost}
                                    type="text"
                                    id={`cost-${i}`}
                                    placeholder="Unit Cost"
                                    onChange={(e) => handleOrganisationTermChange(i, 'unitCost', e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Control
                                    value={organisationTerms[i]?.limit}
                                    type="number"
                                    id={`limit-${i}`}
                                    placeholder="Limit"
                                    onChange={(e) => handleOrganisationTermChange(i, 'limit', e.target.value)}
                                />
                            </Form.Group>

                        </div>
                        {i === 0 ? (
                            <Form.Group className="mb-3 tw-flex tw-flex-col tw-justify-end">
                                <input
                                    onClick={() => setDetailsCount(termsCount + 1)} type="button" value="+" className="btn cta"
                                />
                            </Form.Group>
                        ) : (
                            <Form.Group className="mb-3 pt-2 tw-flex tw-flex-col tw-justify-center">
                                <FontAwesomeIcon onClick={() => { handleRemoveOrganisationTerms(i) }} className="tw-cursor-pointer" icon={faTrashAlt}
                                />
                            </Form.Group>
                        )}
                    </div>
                ))
            }
        </>
    )
}
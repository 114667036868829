import CustomLoader from 'components/CustomLoader';
import useAuth from 'contexts/Auth';
import useCustomerPolicyRequest from 'hooks/requests/useCustomerPolicyRequest';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import PolicyCard from './PolicyCard';
import { getTotalLimit, getTotalPremium } from './ListPolicy';
import { formatDate } from 'helpers/helpfulUtilities';
import NewTransactionModal from './NewPayment';
import PaymentStatusModal from './PaymentStatusModal';

const PolicyDetail = () => {
  const { authClaims } = useAuth();
  const { loadingDetails: isLoading, policyDetails, getPolicyDetails } = useCustomerPolicyRequest()
  const { policyId } = useParams();
  const [activeTab, setActiveTab] = useState("details")
  const [isNewPaymentModalOpen, setIsNewPaymentModalOpen] = useState(false)
  const [showPaymentFailedModal, setShowPaymentFailedModal] = useState(false)
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false)


  useEffect(() => {
    getPolicyDetails(policyId)
  }, [policyId])

  const transactionComplete = (status) => {
    setIsNewPaymentModalOpen(false)

    getPolicyDetails(policyId)

    if (status == "successful") {
      setShowPaymentSuccessModal(true)
    }
    if (status == "failed") {
      setShowPaymentFailedModal(true)
    }
  }

  return (
    <>

      <div className="components">
        <header className="tw-mx-5 tw-flex">
          <div className="nk-block-between tw-flex-grow">
            <div className="header-txt">
              <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                Policy Details
              </h1>
              <p className="tw-text-sm tw-text-gray-500">Review details about policy</p>
            </div>
          </div>

        </header>
        {isLoading && (
          <div className="loader-wrapper">
            <CustomLoader message={"Please wait..."} />
          </div>
        )}
        {
          policyDetails &&
          <div>
            <DetailsTabs activeTab={activeTab} setActiveTab={(value) => setActiveTab(value)} />

            {activeTab == "details" && <AboutPolicy policyDetails={policyDetails} />}
            {activeTab == "beneficiaries" && <PolicyBeneficiary policyDetails={policyDetails} />}
            {activeTab == "schedules" && <PaymentSchduleSection setIsNewPaymentModalOpen={setIsNewPaymentModalOpen} policyDetails={policyDetails} />}
            {activeTab == "transactions" && <TransactionsSection setIsNewPaymentModalOpen={setIsNewPaymentModalOpen} policyDetails={policyDetails} />}

            <NewTransactionModal
              selectedPolicy={policyDetails}
              transactionCompleted={(status) => transactionComplete(status)}
              isOpen={isNewPaymentModalOpen}
              onClose={() => setIsNewPaymentModalOpen(false)}
            />
            <PaymentStatusModal isOpen={showPaymentFailedModal} onClose={() => setShowPaymentFailedModal(false)} status={"failed"} />
            <PaymentStatusModal isOpen={showPaymentSuccessModal} onClose={() => setShowPaymentSuccessModal(false)} status={"successful"} />

          </div>
        }


      </div>
    </>
  )
}


export default PolicyDetail;

function PaymentSchduleSection({ policyDetails, setIsNewPaymentModalOpen }) {

  const sortedPaymentSchedule = useMemo(() => {
    return policyDetails.PolicyPaymentSchedule.slice().sort((a, b) => {
      return new Date(a.periodEndDate) - new Date(b.periodEndDate);
    });
  }, [policyDetails.PolicyPaymentSchedule]);

  return (
    <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 px-4 py-4  mb-5 shadow-sm tw-relative">
      <div className=" tw-flex tw-gap-2">
        <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center">
          <h2 className="tw-text-lg tw-text-gray-700 tw-font-medium">Payment Schedules</h2>
        </div>
        <div className="tw-flex tw-gap-2  sm:tw-mt-2 md:tw-mt-2">
          <div className="tw-flex tw-flex-col tw-justify-center">
            <button onClick={() => setIsNewPaymentModalOpen(true)} className="btn cta">New Payment</button>
          </div>
        </div>
      </div>
      <Table hover striped responsive className="mt-4" id="myTable">
        <thead>
          <tr>
            <th>Period Start</th>
            <th>Period End</th>
            <th>Amount Due</th>
            <th>Amount Paid</th>
            <th>Balance</th>
            {/* <th>Status</th> */}
          </tr>
        </thead>
        <tbody>
          {
            sortedPaymentSchedule.map((item, key) =>
              <tr key={key}>
                <td>{formatDate(item.periodStartDate, 'YYYY-MM-DD')}</td>
                <td>{formatDate(item.periodEndDate, 'YYYY-MM-DD')}</td>
                <td>{item.amountDue.toLocaleString()}</td>
                <td>{item.amountPaid.toLocaleString()}</td>
                <td>{item.balance.toLocaleString()}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </div>
  )
}

function TransactionsSection({ policyDetails, setIsNewPaymentModalOpen }) {

  const sortedTransactions = useMemo(() => {
    return policyDetails.Transaction.slice().sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
  }, [policyDetails.Transaction]);



  function getStatusBadge(status) {
    switch (status) {
      case 'successful':
        return 'tw-badge-success';
      case 'failed':
        return 'tw-badge-failed';
      case 'pending':
        return 'tw-badge-pending';
      default:
        return '';
    }
  }


  return (
    <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 px-4 py-4  mb-5 shadow-sm tw-relative">
      <div className=" tw-flex tw-gap-2">
        <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center">
          <h2 className="tw-text-lg tw-text-gray-700 tw-font-medium">Transactions</h2>
        </div>
        <div className="tw-flex tw-gap-2  sm:tw-mt-2 md:tw-mt-2">
          <div className="tw-flex tw-flex-col tw-justify-center">
            <button onClick={() => setIsNewPaymentModalOpen(true)} className="btn cta">New Payment</button>
          </div>
        </div>
      </div>
      <Table hover striped responsive className="mt-4" id="myTable">

        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Phone</th>
            <th>Status</th>
            {/* <th>Status</th> */}
          </tr>
        </thead>
        <tbody>
          {
            sortedTransactions.map((item, key) =>
              <tr key={key}>
                <td>{formatDate(item.createdAt, 'YYYY-MM-DD')}</td>
                <td>{item.amount.toLocaleString()}</td>
                <td>{item.msisdn}</td>
                <td>
                  <span className={getStatusBadge(item.status)}>
                    {item.status}
                  </span>
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </div>
  )
}

function PolicyBeneficiary({ policyDetails }) {
  const [isPolicyCardOpen, setIsPolicyCardOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)

  return (
    <>
      {
        policyDetails.PolicyBeneficiary.map((item) =>
          <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 p-0  mb-5 shadow-sm tw-relative">

            <div className="tw-py-3 tw-px-4 tw-flex tw-gap-2 tw-border-b">
              <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center">
                <h2 className="tw-text-lg tw-text-gray-700 tw-font-medium">{item.Customer ? item?.Customer?.name : item.Dependent.name}</h2>
              </div>
              <div className="tw-flex tw-gap-2  sm:tw-mt-2 md:tw-mt-2">
                <div className="tw-flex tw-flex-col tw-justify-center">
                  <button onClick={() => {
                    setIsPolicyCardOpen(true)
                    setSelectedMember(item.Customer ? item.Customer : item.Dependent)
                  }} className="btn cta">Preview Card</button>
                </div>
              </div>
            </div>

            <div className='tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-2 md:tw-grid-cols-2 tw-bg-gray-50'>
              <div className="tw-text-gray-600">Type:</div>
              <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">{item.Customer ? "Customer" : "Dependent"}</div>
            </div>
            <div className='tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-2 md:tw-grid-cols-2 '>
              <div className="tw-text-gray-600">Reference:</div>
              <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">{item.Customer ? item?.Customer?.referenceNumber : item.Dependent.dependentNumber}</div>
            </div>
            <div className='tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-2 md:tw-grid-cols-2 tw-bg-gray-50'>
              <div className="tw-text-gray-600">Gender:</div>
              <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">{item.Customer ? item?.Customer?.gender : item.Dependent.gender}</div>
            </div>

            <div className='tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-2 md:tw-grid-cols-2 '>
              <div className="tw-text-gray-600">DOB:</div>
              <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">{item.Customer ? item?.Customer?.dob : item.Dependent.dob}</div>
            </div>

            <div className='tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-2 md:tw-grid-cols-2 tw-bg-gray-50'>
              <div className="tw-text-gray-600">NIN:</div>
              <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">{item.Customer ? item?.Customer?.nin : item.Dependent.nin}</div>
            </div>
            <div className="tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-text-sm tw-rounded-b">
              <div className="tw-text-gray-600">
                Policy Covers
              </div>
              <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">
                <div className="tw-border tw-rounded-md tw-w-full">
                  {
                    item.PolicyCover.map((item, key) =>
                      <div key={key} className={`tw-flex tw-py-3 tw-gap-3 tw-px-3 ${key > 0 && 'tw-border-t'}`}>
                        <span className="tw-flex-grow">{item.cover}, Cost: {item.cost.toLocaleString()}, Limit:{item.limit.toLocaleString()}</span>
                      </div>
                    )
                  }
                </div>

              </div>
            </div>

          </div>
        )
      }

      <PolicyCard selectedPolicy={policyDetails} Member={selectedMember} isOpen={isPolicyCardOpen} onClose={() => setIsPolicyCardOpen(false)} />
    </>
  );
}


function AboutPolicy({ policyDetails }) {
  const [isPolicyCardOpen, setIsPolicyCardOpen] = useState(false)

  const details = [
    { label: 'Customer Name', value: policyDetails?.Customer?.name ?? "N/A" },
    { label: 'Policy Number', value: policyDetails.policyNumber },
    { label: 'Policy Name', value: policyDetails.policyName },
    { label: 'Policy Type', value: policyDetails.policyType },
    { label: 'Total Premium', value: `UGX ${getTotalPremium(policyDetails.PolicyCover).toLocaleString()}` },
    { label: 'Total Limit', value: `UGX ${getTotalLimit(policyDetails.PolicyCover).toLocaleString()}` },
    { label: 'Start Date', value: policyDetails.startDate },
    { label: 'Vat', value: `${policyDetails.vat}%` },
  ];

  return (
    <>
      <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 p-0  mb-5 shadow-sm tw-relative">
        <div className="tw-py-3 tw-px-4 tw-flex tw-gap-2 tw-border-b">
          <div className="tw-flex-grow tw-flex tw-flex-col tw-justify-center">
            <h2 className="tw-text-lg tw-text-gray-700 tw-font-medium">Policy Information</h2>
          </div>
          <div className="tw-flex tw-gap-2  sm:tw-mt-2 md:tw-mt-2">
            <div className="tw-flex tw-flex-col tw-justify-center">
              <button onClick={() => setIsPolicyCardOpen(true)} className="btn cta">Preview Card</button>
            </div>
          </div>
        </div>

        {details.map((item, index) => (
          <div className={`tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-2 md:tw-grid-cols-2  ${index % 2 === 0 ? 'tw-bg-gray-50' : ''}`} key={index}>
            <div className="tw-text-gray-600">{item.label}</div>
            <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">{item.value}</div>
          </div>
        ))}
        {/* <div className="tw-py-3 tw-px-4 tw-gap-2 tw-grid tw-grid-cols-3 sm:tw-grid-cols-1 md:tw-grid-cols-1 tw-text-sm tw-rounded-b">
        <div className="tw-text-gray-600">
          Company Borrower files
        </div>
        <div className="tw-col-span-2 sm:tw-col-span-1 md:tw-col-span-1">
          <div className="tw-border tw-rounded-md tw-w-full">
            {
              borrowerDetails.BorrowerFiles.map((item, key) =>
                <div key={key} className={`tw-flex tw-py-3 tw-gap-3 tw-px-3 ${key > 0 && 'tw-border-t'}`}>
                  <span className="tw-flex tw-flex-col tw-justify-center tw-text-amber-500"><FiPaperclip /></span>
                  <span className="tw-flex-grow">{item.fileName}</span>
                  <a href={item.filePath} target="blank" className="tw-text-amber-500 tw-font-semibold">view</a>
                </div>
              )
            }
          </div>
          {
            borrowerDetails.BorrowerFiles.length === 0 && "None"
          }
        </div>
      </div> */}
        <PolicyCard selectedPolicy={policyDetails} Member={policyDetails.Customer} isOpen={isPolicyCardOpen} onClose={() => setIsPolicyCardOpen(false)} />

      </div>

    </>
  );
}


function DetailsTabs({ activeTab, setActiveTab }) {
  return (
    <>
      <div className=' tw-border-b mb-4 mt-2'>
        <ul className="tw-flex tw-flex-wrap tw-text-sm tw-font-medium tw-text-center ">
          <li className="tw-mr-2 tw-ml-3">
            <span onClick={() => setActiveTab("details")} className={(activeTab === 'details') ? "tab-active" : "tab-inactive"}>Policy Details</span>
          </li>
          <li className="tw-mr-2">
            <span onClick={() => setActiveTab("schedules")} className={(activeTab === 'schedules') ? "tab-active" : "tab-inactive"} aria-current="page">Schedules
            </span>
          </li>
          <li className="tw-mr-2">
            <span onClick={() => setActiveTab("transactions")} className={(activeTab === 'transactions') ? "tab-active" : "tab-inactive"} aria-current="page">Transactions
            </span>
          </li>
          <li className="tw-mr-2">
            <span onClick={() => setActiveTab("beneficiaries")} className={(activeTab === 'beneficiaries') ? "tab-active" : "tab-inactive"} aria-current="page">Beneficiaries
            </span>
          </li>

          <li className="tw-mr-2">
            <span onClick={() => setActiveTab("customer")} className={(activeTab === 'customer') ? "tab-active" : "tab-inactive"} aria-current="page">Customer Details
            </span>
          </li>

        </ul>
      </div>
    </>
  );
}

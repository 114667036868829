import {
  MdAccountBalance,
  MdBusinessCenter,
  MdDirectionsCarFilled,
  MdAirlineSeatReclineExtra,
  MdAssessment,
  MdPeople,
  MdPerson,
  MdStickyNote2,
  MdDashboard,
  MdBusiness,
  MdPermContactCalendar,
  MdAssignmentInd,
  MdAssignment,
  MdBeachAccess,
  MdDataUsage,
  MdDns,
  MdSupervisedUserCircle,
} from "react-icons/md";
import { ImDatabase } from "react-icons/im";
import { RiCalendarTodoFill } from "react-icons/ri";
import { GrSettingsOption } from "react-icons/gr";
import { AGENTS_PERMISSIONS, CHILDCARE_CAIMS_PERMISSIONS, CHILDCARE_POLICY_PERMISSIONS, CUSTOMER_PERMISSIONS, LIFE_CAIMS_PERMISSIONS, LIFE_POLICY_PERMISSIONS, MEDICAL_CAIMS_PERMISSIONS, MEDICAL_POLICY_PERMISSIONS, MTP_CAIMS_PERMISSIONS, MTP_POLICY_PERMISSIONS } from "helpers/app-permissions";

const menuData = {
  SuperAdmin: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdDashboard />,
      link: "/superadmin/dashboard",
    },
    {
      number: 2,
      name: "Organisations",
      icon: <MdBusiness />,
      link: "/superadmin/organisations",
    },
    {
      number: 2,
      name: "Supervisors",
      icon: <MdSupervisedUserCircle />,
      link: "/superadmin/supervisors",
    },
    
    {
      number: 2,
      name: "Admins",
      icon: <MdPermContactCalendar />,
      link: "/superadmin/admins",
    },

    {
      number: 2,
      name: "Customers",
      icon: <MdPeople />,
      link: "/superadmin/customers",
    },
    {
      number: 3,
      name: "Policies",
      icon: <MdAssignment />,
      link: "/superadmin/policy/medical",
      subMenu: [
        {
          name: "Medical",
          link: "/superadmin/policy/medical",
        },
        {
          name: "Life",
          link: "/superadmin/policy/life",
        },
        {
          name: "Childcare",
          link: "/superadmin/policy/childcare",
        },
        {
          name: "MTP",
          link: "/superadmin/motor-third-party",
        },
        // {
        //   name: "Comprehensive",
        //   link: "/superadmin/comprehensive",
        // },
        // {
        //   name: "Fire",
        //   link: "/superadmin/windscreen",
        // },
        // {
        //   name: "Travel",
        //   link: "/admin/new-import",
        // },
        // {
        //   name: "Agriculture",
        //   link: "/admin/transit",
        // },
        // {
        //   name: "Fidelity guarantee",
        //   link: "/admin/transit",
        // },
        // {
        //   name: "Public liability",
        //   link: "/admin/transit",
        // },
        // {
        //   name: "New imports",
        //   link: "/superadmin/new-import",
        // },
        // {
        //   name: "Transit",
        //   link: "/superadmin/transit",
        // },
      ],
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdBeachAccess />,
      link: "/superadmin/claims",
    },
    {
      number: 5,
      name: "System Logs",
      icon: <MdDns />,
      link: "/superadmin/system-logs",
    },
  ],
  Admin: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdDashboard />,
      link: "/admin/dashboard",
    },
    {
      number: 2,
      name: "Organisations",
      icon: <MdBusiness />,
      link: "/admin/organisations",
    },

    // {
    //   number: 4,
    //   name: "Sticker No.",
    //   icon: <MdStickyNote2 />,
    //   link: "/admin/sticker-management",
    // },
    {
      number: 5,
      name: "Users",
      icon: <MdPeople />,
      link: "/admin/user-management",
      subMenu: [
        {
          name: "Agents",
          link: "/admin/agents",
        },
        {
          name: "Supervisor",
          link: "/admin/supervisors",
        },
        {
          name: "Log Trail",
          link: "/admin/view-log-trail",
        },
      ],
    },
    {
      number: 6,
      name: "Insurance Products",
      icon: <MdDirectionsCarFilled />,
      link: "/admin/products/all",
      subMenu: [
        {
          name: "Add Product",
          link: "/admin/add-product",
        },
        {
          name: "Medical",
          link: "/admin/products/medical",
        },
        {
          name: "Life",
          link: "/admin/products/life",
        },
        {
          name: "Childcare",
          link: "/admin/products/childcare",
        },

        // {
        //   name: "MTP",
        //   link: "/admin/motor-third-party",
        // },
      ],
    },
    {
      number: 3,
      name: "Customers",
      icon: <MdPerson />,
      link: "/admin/customers",
    },
    {
      number: 3,
      name: "Policies",
      icon: <MdAssignment />,
      link: "/admin/policy/medical",
      subMenu: [
        {
          name: "Medical",
          link: "/admin/policy/medical",
        },
        {
          name: "Life",
          link: "/admin/policy/life",
        },
        {
          name: "Childcare",
          link: "/admin/policy/childcare",
        },
        {
          name: "MTP",
          link: "/admin/motor-third-party",
        },
     
      ],
    },
    // {
    //   number: 6,
    //   name: "Policies",
    //   icon: <MdDirectionsCarFilled />,
    //   link: "/admin/policy/all",
    //   subMenu: [
    //     {
    //       name: "Add Policy",
    //       link: "/admin/add-policy",
    //     },
    //     {
    //       name: "Medical",
    //       link: "/admin/policy/medical",
    //     },
    //     {
    //       name: "Life",
    //       link: "/admin/policy/life",
    //     },
    //     {
    //       name: "Childcare",
    //       link: "/admin/policy/childcare",
    //     },

    //     // {
    //     //   name: "MTP",
    //     //   link: "/admin/motor-third-party",
    //     // },
    //   ],
    // },
    {
      number: 7,
      name: "Claims",
      icon: <MdBeachAccess />,
      link: "/admin/claims",
    },
    {
      number: 8,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/admin/reports",
    },
    {
      number: 5,
      name: "System Logs",
      icon: <MdDns />,
      link: "/superadmin/system-logs",
    },
  ],
  Supervisor: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdDashboard />,
      link: "/supervisor/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPermContactCalendar />,
      link: "/supervisor/clients",
      permissions: [...Object.values(CUSTOMER_PERMISSIONS)]
    },
    {
      number: 3,
      name: "Users",
      icon: <MdPeople />,
      link: "/supervisor/agents",
      permissions: [...Object.values(AGENTS_PERMISSIONS)],
      subMenu: [
        {
          name: "Agents",
          link: "/supervisor/agents",
        },
        {
          name: "Log Trail",
          link: "/supervisor/view-log-trail",
        },
      ],
    },

    {
      number: 4,
      name: "Policies",
      icon: <MdAssignment />,
      link: "/supervisor/policy/all",
      permissions: [...Object.values(MEDICAL_POLICY_PERMISSIONS), ...Object.values(LIFE_POLICY_PERMISSIONS),
      ...Object.values(CHILDCARE_POLICY_PERMISSIONS), ...Object.values(MTP_POLICY_PERMISSIONS)],
      subMenu: [
        {
          name: "Medical",
          link: "/supervisor/policy/medical",
          permissions: [...Object.values(MEDICAL_POLICY_PERMISSIONS)],
        },
        {
          name: "Life",
          link: "/supervisor/policy/life",
          permissions: [...Object.values(LIFE_POLICY_PERMISSIONS)],
        },
        {
          name: "Childcare",
          link: "/supervisor/policy/childcare",
          permissions: [...Object.values(CHILDCARE_POLICY_PERMISSIONS)],
        },
        {
          name: "MTP",
          link: "/supervisor/motor-third-party",
          permissions: [...Object.values(MTP_POLICY_PERMISSIONS)],
        },
        // {
        //   name: "Comprehensive",
        //   link: "/supervisor/comprehensive",
        // },
        // {
        //   name: "Fire",
        //   link: "/supervisor/windscreen",
        // },
        // {
        //   name: "Travel",
        //   link: "/supervisor/windscreen",
        // },
        // {
        //   name: "Personal accident",
        //   link: "/supervisor/windscreen",
        // },
        // {
        //   name: "GPA",
        //   link: "/supervisor/windscreen",
        // },
        // {
        //   name: "New imports",
        //   link: "/supervisor/new-import",
        // },
        // {
        //   name: "Transit",
        //   link: "/supervisor/transit",
        // },
      ],
    },

    {
      number: 4,
      name: "Claims",
      icon: <MdBeachAccess />,
      link: "/supervisor/claims",
      permissions: [...Object.values(MEDICAL_CAIMS_PERMISSIONS), ...Object.values(LIFE_CAIMS_PERMISSIONS),
      ...Object.values(CHILDCARE_CAIMS_PERMISSIONS), ...Object.values(MTP_CAIMS_PERMISSIONS)],
      subMenu: [
        {
          name: "Medical",
          link: "/supervisor/claims",
          permissions: [...Object.values(MEDICAL_CAIMS_PERMISSIONS)],
        },
        {
          name: "Life",
          link: "/supervisor/claims",
          permissions: [...Object.values(LIFE_CAIMS_PERMISSIONS)],
        },
        {
          name: "Childcare",
          link: "/supervisor/claims",
          permissions: [...Object.values(CHILDCARE_CAIMS_PERMISSIONS)],
        },
        {
          name: "MTP",
          link: "/supervisor/claims/motor-third-party",
          permissions: [...Object.values(MTP_CAIMS_PERMISSIONS)],
        },
      ],
    },
    // {
    //   number: 5,
    //   name: "Claims",
    //   icon: <MdBeachAccess />,
    //   link: "/supervisor/claims",
    // },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/supervisor/reports",
    },
  ],
  Agent: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
      permissions: [...Object.values(CUSTOMER_PERMISSIONS)]
    },
    {
      number: 3,
      name: "Policies",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/policy/all",
      permissions: [...Object.values(MEDICAL_POLICY_PERMISSIONS), ...Object.values(LIFE_POLICY_PERMISSIONS),
      ...Object.values(CHILDCARE_POLICY_PERMISSIONS), ...Object.values(MTP_POLICY_PERMISSIONS)],
      subMenu: [
        {
          name: "Medical",
          link: "/agent/policy/medical",
          permissions: [...Object.values(MEDICAL_POLICY_PERMISSIONS)],
        },
        {
          name: "Life",
          link: "/agent/policy/life",
          permissions: [...Object.values(LIFE_POLICY_PERMISSIONS)],
        },
        {
          name: "Childcare",
          link: "/agent/policy/childcare",
          permissions: [...Object.values(CHILDCARE_POLICY_PERMISSIONS)],
        },
        {
          name: "MTP",
          link: "/agent/motor-third-party",
          permissions: [...Object.values(MTP_POLICY_PERMISSIONS)],
        },
        // {
        //   name: "Comprehensive",
        //   link: "/agent/comprehensive",
        // },
        // {
        //   name: "Windscreen",
        //   link: "/agent/windscreen",
        // },
        // {
        //   name: "New imports",
        //   link: "/agent/new-import",
        // },
        // {
        //   name: "Transit",
        //   link: "/agent/transit",
        // },
      ],
    },
    // {
    //   number: 4,
    //   name: "Claims",
    //   icon: <MdAirlineSeatReclineExtra />,
    //   link: "/agent/claims",
    // },
    {
      number: 4,
      name: "Claims",
      icon: <MdBeachAccess />,
      link: "/agent/claims",
      permissions: [...Object.values(MEDICAL_CAIMS_PERMISSIONS), ...Object.values(LIFE_CAIMS_PERMISSIONS),
        ...Object.values(CHILDCARE_CAIMS_PERMISSIONS), ...Object.values(MTP_CAIMS_PERMISSIONS)],
      subMenu: [
        {
          name: "Medical",
          link: "/agent/claims",
          permissions: [...Object.values(MEDICAL_CAIMS_PERMISSIONS)],
        },
        {
          name: "Life",
          link: "/agent/claims",
          permissions: [...Object.values(LIFE_CAIMS_PERMISSIONS)],
        },
        {
          name: "Childcare",
          link: "/agent/claims",
          permissions: [...Object.values(CHILDCARE_CAIMS_PERMISSIONS)],
        },
        {
          name: "MTP",
          link: "/agent/claims/motor-third-party",
          permissions: [...Object.values(MTP_CAIMS_PERMISSIONS)],
        },
      ],
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
  Agent_mtp: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
    },
    {
      number: 3,
      name: "MTP",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/motor-third-party",
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdAirlineSeatReclineExtra />,
      link: "/agent/claims",
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
  Agent_comprehensive: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
    },
    {
      number: 3,
      name: "Comprehensive",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/comprehensive",
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdAirlineSeatReclineExtra />,
      link: "/agent/claims",
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
  Agent_windscreen: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
    },
    {
      number: 3,
      name: "MTP",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/motor-third-party",
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdAirlineSeatReclineExtra />,
      link: "/agent/claims",
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
  Agent_newImport: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
    },
    {
      number: 3,
      name: "New Import Policy",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/new-import",
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdAirlineSeatReclineExtra />,
      link: "/agent/claims",
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
  Agent_transit: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
    },
    {
      number: 3,
      name: "Transit Policy",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/transit",
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdAirlineSeatReclineExtra />,
      link: "/agent/claims",
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
  Agent_mtp_comprehensive: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
    },
    {
      number: 3,
      name: "Policies",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/motor-third-party",
      subMenu: [
        {
          name: "MTP",
          link: "/agent/motor-third-party",
        },
        {
          name: "Comprehensive",
          link: "/agent/comprehensive",
        },
      ],
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdAirlineSeatReclineExtra />,
      link: "/agent/claims",
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
  Agent_mtp_comprehensive_windscreen: [
    {
      number: 1,
      name: "Dashboard",
      icon: <MdAccountBalance />,
      link: "/agent/dashboard",
    },
    {
      number: 2,
      name: "Clients",
      icon: <MdPerson />,
      link: "/agent/clients",
    },
    {
      number: 3,
      name: "Policies",
      icon: <MdDirectionsCarFilled />,
      link: "/agent/motor-third-party",
      subMenu: [
        {
          name: "MTP",
          link: "/agent/motor-third-party",
        },
        {
          name: "Comprehensive",
          link: "/agent/comprehensive",
        },
        {
          name: "Windscreen",
          link: "/agent/windscreen",
        },
      ],
    },
    {
      number: 4,
      name: "Claims",
      icon: <MdAirlineSeatReclineExtra />,
      link: "/agent/claims",
    },
    {
      number: 5,
      name: "Log Trails",
      icon: <RiCalendarTodoFill />,
      link: "/agent/view-log-trail",
    },
    {
      number: 6,
      name: "Reports",
      icon: <MdAssessment />,
      link: "/agent/reports",
    },
  ],
};

export default menuData;

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { cleanAmount } from "helpers/helpfulUtilities";
import useTransactionsRequest from "hooks/requests/useTransactionsRequest";
import AppSpinner from "components/AppSpinner";
import AppButton from "components/AppButton";
import { Form, Table } from "react-bootstrap";

const NewTransactionModal = (props) => {
    const { fetchTransactionDetails, registerTransaction, isSaving } = useTransactionsRequest();
    const [isProcessing, setIsProcessing] = useState(false);
    const schema = yup.object({
        amount: yup.string().required("Amount is required."),
        msisdn: yup.string().required("Phone is required."),
    });
    const form = useForm({
        defaultValues: {
            amount: "",
            msisdn: ""
        },
        resolver: yupResolver(schema),
    });
    const { register, handleSubmit, formState, control } = form;
    const { errors } = formState;

    const onSubmit = async (data) => {
        const formData = { ...data, policyId: props.selectedPolicy.id, amount: cleanAmount(data.amount) };
        try {
            const res = await registerTransaction(formData);
            processingPayment(res.data.transactionRef);
        } catch (error) {
            console.log(error);
        }
    };

    const processingPayment = (transactionId) => {
        setIsProcessing(true);
        let intervalID = window.setInterval(() => {
            checkTransactionStatus({
                transactionId,
                callback: (transaction) => {
                    if (transaction.status === "successful" || transaction.status === "failed") {
                        clearInterval(intervalID);
                        props.transactionCompleted(transaction.status);
                        setIsProcessing(false);
                    }
                }
            });
        }, 15000);
    };

    const checkTransactionStatus = async ({ transactionId, callback }) => {
        try {
            const res = await fetchTransactionDetails(transactionId);
            callback(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {(props.isOpen) &&
                <div className="tw-modal ">
                    <div className="modal-content modal-md tw-px-6 ">
                        {
                            isProcessing ? <ProcessingPaymentSection /> :
                                <>
                                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                        <div className="">
                                            <div className="tw-flex tw-mb-5">
                                                <div className="tw-flex-grow">
                                                    <h1 className="tw-text-xl tw-font-bold tw-text-gray-800">New Payment</h1>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon onClick={() => props.onClose()} className="tw-cursor-pointer tw-font-medium" size="lg" icon={faClose} />
                                                </div>
                                            </div>
                                            <div className="tw-w-full">
                                                <Form.Group className="mb-3 tw-w-full">
                                                    <Form.Label htmlFor="msisdn">Mobile Money  <span className=" tw-text-red-600">*</span></Form.Label>
                                                    <Form.Control
                                                        {...register("msisdn")}
                                                        id="msisdn"
                                                        type="text"
                                                        required
                                                        placeholder="Phone"
                                                    />
                                                    <p className="tw-text-xs tw-text-red-600">{errors.msisdn?.message}</p>
                                                </Form.Group>

                                                <Form.Group className="mb-3 tw-w-full">
                                                    <Form.Label htmlFor="msisdn">Amount  <span className=" tw-text-red-600">*</span></Form.Label>
                                                    <Form.Control
                                                        {...register("amount")}
                                                        id="amount"
                                                        type="number"
                                                        required
                                                        placeholder="Amount"
                                                    />
                                                    <p className="tw-text-xs tw-text-red-600">{errors.amount?.message}</p>
                                                </Form.Group>

                                                {/* <div className="tw-w-full tw-mt-4">
                                                    <label htmlFor="" className="tw-form-label-1 ">Amount</label>
                                                    <Controller
                                                        control={control}
                                                        name="amount"
                                                        render={({ field: { onChange, name, value } }) => (
                                                            <NumericFormat
                                                                thousandSeparator=","
                                                                className="tw-form-input"
                                                                placeholder="Enter your pledge"
                                                                name={name}
                                                                value={value}
                                                                onChange={onChange}
                                                            />
                                                        )}
                                                    />
                                                    <p className="tw-text-xs tw-text-red-600">{errors.amount?.message}</p>
                                                </div> */}
                                            </div>

                                            <div className="tw-flex tw-justify-end tw-mt-5 tw-gap-2">
                                                <div>
                                                    <AppButton buttonType={"submit"} callBackFun={() => { }} showLoader={isSaving}
                                                        spinnerClass={"tw-inline tw-w-3 tw-h-3 tw-mr-2 tw-white tw-animate-spin tw-fill-black"}
                                                        className="btn btn-dark cta submitcta " text="Proceed" />
                                                </div>
                                                <div className=" tw-flex tw-flex-col tw-justify-center">
                                                    <button onClick={() => props.onClose()} type="button" className="tw-btn-secondary">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </>
                        }

                    </div>
                </div>
            }

        </>
    );
};

export default NewTransactionModal;

const ProcessingPaymentSection = () => {
    return (
        <>
            <div className="tw-pb-8">
                <AppSpinner />
                <p className="tw-text-center">Processing Payment.</p>
            </div>
        </>
    );
};

import { useState } from "react";
import { db as firestore } from "../../helpers/firebase";
import {
    getDoc,
    doc,
    query,
    collection,
    getDocs,
} from "firebase/firestore";

const useOrganisationsRequest = () => {
    const [organisations, setOrganisations] = useState([]);
    const [organisationDetails, setOrganisationDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchOrganisations = async (name = "all") => {
        setIsLoading(true);
        try {
            let request = query(collection(firestore, "organisations"));
            const querySnapshot = await getDocs(request);
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setOrganisations(data.sort((item) => item.createdAt));
            setIsLoading(false);
        } catch (error) {
            console.log("Error getting documents: ", error);
            setIsLoading(false);
        }
    };

    const fetchOrganisationDetails = async (organisationId) => {
        setIsLoading(true);
        try {
            const organisationDocRef = doc(firestore, "organisations", organisationId);
            const organisationDocSnapshot = await getDoc(organisationDocRef);

            if (organisationDocSnapshot.exists()) {
                const organisationData = {
                    id: organisationDocSnapshot.id,
                    ...organisationDocSnapshot.data(),
                };
                setOrganisationDetails(organisationData);
            } else {
                console.log("Organisation not found");
            }

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching organisation details: ", error);
            setIsLoading(false);
        }
    };

    return {
        organisations,
        isLoading,
        fetchOrganisations,
        organisationDetails,
        fetchOrganisationDetails,
    };
};

export default useOrganisationsRequest;

import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import {  useState } from "react";

const useCustomerClaimRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [claimDetails, setClaimDetails] = useState(null);
    const { authClaims } = useAuth();
    const [pageSize, setPageSize] = useState(10)
    const [hasMore, setHasMore] = useState(false)
    const [nextCursor, setNextCursor] = useState(undefined)
    const [claims, setClaims] = useState([])
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const [loadingMore, setLoadingMore] = useState(true)
    const [timeoutRef, setTimeoutRef] = useState(null);
    const [search, setSearch] = useState("");
    const [page,setPage] = useState(1)
    const loadMore = async () => {
        setLoadingMore(true);
        fetchClaims()
    }

    const searchClaims = (value) => {
        setClaims([]);
        setNextCursor(undefined)

        setSearch(value)
        // Clear the previous timeout
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        // Set a new timeout
        const newTimeout = setTimeout(() => {
            // Here, you can call your API with the search value (value)
            
            fetchClaims(value)
        }, 500);

        // Store the timeout reference
        setTimeoutRef(newTimeout);
    }

    const fetchClaims = async (query="") => {
        setLoadingMore(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/claims?search=${query}&nextCursor=${nextCursor ? nextCursor : ''}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            if (query) {
                setClaims(response.data.items);
                setHasMore(false)
            }else{
                setClaims([...claims, ...response.data.items]);
                setNextCursor(response.data.nextCursor)
                if (page === response.data.totalPages) {
                    setHasMore(false)
                }else{
                    setHasMore(true)
                }
                setPage(page+1)
            }
           
            setIsLoading(false);
            setLoadingMore(false)
        } catch (error) {
            setIsLoading(false);
            setLoadingMore(false)
        }
    }


    const getClaimDetails = async(claimId)=>{
        setLoadingDetails(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/claims/details/${claimId}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setClaimDetails(response.data)
           
            setLoadingDetails(false)
        } catch (error) {
            setLoadingDetails(false)
        }
    }

    return {
        claims, 
        isLoading, 
        fetchClaims, 
        hasMore, 
        nextCursor, 
        loadingMore, 
        loadMore, 
        searchClaims,
        getClaimDetails,
        loadingDetails,
        claimDetails
    }
}

export default useCustomerClaimRequest;
import { useEffect } from 'react';
const  Highcharts = require('highcharts');  

const MainChart =({series,categories,yAxisTitle="Total"})=>{

    useEffect(()=>{
        if (series.length>0 && categories.length>0) {
            createLine();
        }
    },[series,categories]);

    const createLine =()=>{
        Highcharts.chart('chart_container', { 
            chart: {
                type: 'line'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories:categories,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: yAxisTitle,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ''
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            colors: [
                '#a2ce72b0',
                '#132f5087',
                '#1e3a8a42',
            ],
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: series
        });
    }

    return (
        <div id='chart_container'></div>
    );
}

export default MainChart;
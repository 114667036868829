import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import { useEffect, useState } from "react";

const useCustomerPolicyRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [policyDetails, setPolicyDetails] = useState(null);
    const { authClaims } = useAuth();
    const [pageSize, setPageSize] = useState(10)
    const [hasMore, setHasMore] = useState(false)
    const [nextCursor, setNextCursor] = useState(undefined)
    const [policies, setPolicies] = useState([])
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const [loadingMore, setLoadingMore] = useState(false)
    const [timeoutRef, setTimeoutRef] = useState(null);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1)
    const loadMore = async () => {
        fetchPolicies()
    }
    const searchPolicies = (value, searchAll = false) => {
        setPolicies([]);
        setNextCursor(undefined)

        setSearch(value)
        // Clear the previous timeout
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        // Set a new timeout
        const newTimeout = setTimeout(() => {
            // Here, you can call your API with the search value (value)

            fetchPolicies(value, searchAll)
        }, 500);

        // Store the timeout reference
        setTimeoutRef(newTimeout);
    }

    const fetchPolicies = async (query = "", searchAll = false) => {
        console.log(query)
        setLoadingMore(true);
        let url = `${BASE_URL}/api/policies?search=${query}&nextCursor=${nextCursor ? nextCursor : ''}`;
        if (searchAll) {
            url = `${BASE_URL}/api/policies/all/query?search=${query}&nextCursor=${nextCursor ? nextCursor : ''}`;;
        }
        try {
            const response = await apiRequest({
                method: 'get',
                url: url,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            if (query) {
                setPolicies(response.data.items);
                setHasMore(false)
            } else {
                setPolicies([...policies, ...response.data.items]);
                setNextCursor(response.data.nextCursor)
                if (page === response.data.totalPages) {
                    setHasMore(false)
                } else {
                    setHasMore(true)
                }
                setPage(page + 1)
            }

            setIsLoading(false);
            setLoadingMore(false)
        } catch (error) {
            setIsLoading(false);
            setLoadingMore(false)
        }
    }


    const getPolicyDetails = async (policyId) => {
        setLoadingDetails(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/policies/details/${policyId}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setPolicyDetails(response.data)

            setLoadingDetails(false)
        } catch (error) {
            setLoadingDetails(false)
        }
    }

    const clearData = () => {
        setPolicies([])
        setHasMore(false)
        setNextCursor(undefined)
    }

    return {
        policies,
        isLoading,
        fetchPolicies,
        hasMore,
        nextCursor,
        loadingMore,
        loadMore,
        searchPolicies,
        getPolicyDetails,
        loadingDetails,
        policyDetails,
        clearData
    }
}

export default useCustomerPolicyRequest;
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppSpinner from "components/AppSpinner";
import CustomLoader from "components/CustomLoader";
import DropDown from "components/DropDown";
import SearchBar from "components/searchBar/SearchBar";
import useAuth from "contexts/Auth";
import { addDoc, collection } from "firebase/firestore";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import useCustomersRequest from "hooks/requests/useCustomersRequest";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ImFileEmpty } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { authentication, db } from "helpers/firebase";

const ListCustomersAdmin = () => {
    const [searchText, setSearchText] = useState("")
    const [isDeleting, setIsDeleting] = useState("")
    const { authClaims } = useAuth();
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const navigate = useNavigate()
    const logCollectionRef = collection(db, "logs");

    const { customers, loadingMore, isLoading: loadingCustomers, hasMore, loadMore, fetchCustomers, fetchFirstPage, searchCustomers } = useCustomersRequest()

    const handleSearch = (e) => {
        const newValue = e.target.value;
        setSearchText(newValue);
        // Call the handleSearch function after a delay
        searchCustomers(newValue);
    }

    const editCustomer = (id) => {
        navigate(authClaims.superadmin ? `/superadmin/update-clients/${id}` : `/admin/update-clients/${id}`);
    }



    const deleteCustomer = async (customerId, customerName) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You are about to delete a customer and all related policy and dependent data. You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#132f50',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCustomerConfirmed(customerId, customerName)
            }
        })
    }

    const deleteCustomerConfirmed = async (customerId, customerName) => {
        setIsDeleting(true);
        try {
            const response = await apiRequest({
                method: 'post',
                url: BASE_URL + "/api/customers/delete/" + customerId,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            fetchCustomers(1)
            toast.success(`successfully deleted customer`, { position: "top-center" });
            addDoc(logCollectionRef, {
                timeCreated: `${new Date().toISOString().slice(0, 10)} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
                type: 'customer deleted',
                status: 'successful',
                message: `Successfully deleted customer: ${customerName} by ${authentication.currentUser.displayName}`
            })
        } catch (error) {

        } finally {
            setIsDeleting(false);
        }
    }


    useEffect(() => {
        fetchCustomers()
    }, [])


    return (
        <div className="components">
            <header className="tw-mx-5 tw-flex">
                <div className="nk-block-between tw-flex-grow">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            Clients
                        </h1>
                        <p className="tw-text-sm tw-text-gray-500">Manage Customers</p>
                    </div>
                </div>

            </header>

            {
                loadingMore === false && customers.length == 0 && !searchText ?
                    <div className="no-table-data">
                        <i>
                            <ImFileEmpty />
                        </i>
                        <h4>No data yet</h4>
                        <p>You have not added any client Yet</p>
                    </div>
                    :
                    <div className="componentsData shadow-sm table-card mb-3">
                        {(loadingCustomers || isDeleting) && (
                            <div className="loader-wrapper">
                                <CustomLoader message={"Please wait..."} />
                            </div>
                        )}
                        <div className=" tw-flex tw-justify-end">
                            <div className="tw-w-1/3" id="search">
                                <SearchBar
                                    placeholder={"Search customers ...."}
                                    value={searchText}
                                    handleSearch={handleSearch}
                                />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
                            </div>
                        </div>
                        <Table hover striped responsive className="mt-4" id="myTable">
                            <thead>
                                <tr>
                                    <th>Customer No.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Gender</th>
                                    <th>Phone No.</th>
                                    <th>Address</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customers.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item.referenceNumber ? item.referenceNumber : "N/A"}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.gender}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.address}</td>
                                            <td className="started">
                                                <DropDown items={[
                                                    { label: "Edit", onClick: () => editCustomer(item.id) },
                                                    { label: "Delete", onClick: () => deleteCustomer(item.id, item.name) },
                                                ]} />
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>

                        {
                            loadingMore &&
                            <AppSpinner />
                        }
                        {
                            (hasMore && loadingMore === false) &&
                            <div className=" tw-flex tw-justify-center my-3">
                                <button onClick={() => loadMore()} className=" tw-bg-gray-100 tw-shadow hover:tw-bg-gray-200 tw-px-5  tw-rounded-lg py-2"> <FontAwesomeIcon icon={faRefresh} /> Load more</button>
                            </div>
                        }

                    </div>

            }

        </div>
    )
}
export default ListCustomersAdmin;
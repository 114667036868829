import { faClose, faRefresh, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppSpinner from "components/AppSpinner";
import CustomLoader from "components/CustomLoader";
import DropDown from "components/DropDown";
import Loader from "components/Loader";
import SearchBar from "components/searchBar/SearchBar";
import Upload from "components/uploader/Upload"
import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import { authentication } from "helpers/firebase";
import { ERROR_LOG, SUCCESS_LOG, logAction } from "helpers/utils/logsHelper";
import useApiRequest from "hooks/apiRequest";
import useCustomersRequest from "hooks/requests/useCustomersRequest";
import useUploadFileRequest from "hooks/uploadFileRequest";
import { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom";
import DependentsModal from "./DependentsModal";
import useAuthorizedUserDetails from "hooks/requests/useAuthorizedUserDetails";
import useInsuranceProductsRequest from "hooks/requests/useInsuranceProductsRequest";
import { toast } from "react-toastify";
import useCustomerPolicyRequest from "hooks/requests/useCustomerPolicyRequest";

const PRODUCT_TYPES = [
    "medical", "life", "childcare"
]

const NewPolicy = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [clientsModalOpen, setClientsModalOpen] = useState(false);
    const { getCustomerDetails, loadingDetails, customerDetails, setCustomerDetails } = useCustomersRequest()
    const { customerId } = useParams();
    const apiRequest = useApiRequest();
    const navigate = useNavigate()
    const { loadingUserDetails, userDetails, getUserDetails } = useAuthorizedUserDetails()
    const { authClaims, currentUser } = useAuth();
    const { isLoading: loadingPolicyProducts, products: policyProducts, fetchProducts } = useInsuranceProductsRequest()
    const [formData, setFormData] = useState({
        productType: "",
        startDate: "",
        insuranceProductId: null,
        policyCovers: [],
        beneficiaryType: ""
    });
    const [initialPolicyCovers, setInitialPolicyCovers] = useState([])

    const [selectedPolicyProduct, setSelectedPolicyProduct] = useState({});
    const [dependentsData, setDependentsData] = useState([])
    const { loadingDetails: loadingPolicyDetails, policyDetails, getPolicyDetails } = useCustomerPolicyRequest()
    const { policyId } = useParams();


    useEffect(() => {
        if (policyId) {
            getPolicyDetails(policyId);
        }
    }, [policyId])


    useEffect(() => {
        if (policyDetails) {
            setCustomerDetails(policyDetails.Customer)
            if (policyDetails.InsuranceProduct) {
                setSelectedPolicyProduct(policyDetails.InsuranceProduct)
            }
            const policyBeneficiaryCustomer = policyDetails.PolicyBeneficiary.filter((item) => item.customerId != null)
            const policyBeneficiaryDependents = policyDetails.PolicyBeneficiary.filter((item) => item.dependentId != null)
            let beneficiaryType = "";
            if (policyBeneficiaryCustomer.length > 0 && policyBeneficiaryDependents.length > 0) {
                beneficiaryType = "customerAndDependents"
            } else if (policyBeneficiaryCustomer.length > 0 && policyBeneficiaryDependents.length == 0) {
                beneficiaryType = "customerOnly"
            } else if (policyBeneficiaryCustomer.length == 0 && policyBeneficiaryDependents.length > 0) {
                beneficiaryType = "dependentsOnly"
            }

            let policyCovers = [];
            if (policyBeneficiaryCustomer.length > 0) {
                policyCovers = policyBeneficiaryCustomer[0].PolicyCover
                setInitialPolicyCovers(policyCovers.map((item) => item.ProductCoverage))
            }
            setFormData({
                ...formData,
                productType: policyDetails.InsuranceProduct && policyDetails.InsuranceProduct.policyType,
                startDate: policyDetails.startDate,
                insuranceProductId: policyDetails.insuranceProductId,
                policyCovers: policyCovers,
                beneficiaryType: beneficiaryType
            })

        }
    }, [policyDetails])

    useEffect(() => {
        if (formData.insuranceProductId) {
            const policyProduct = policyProducts.filter((item) => item.id == formData.insuranceProductId)
            if (policyProduct.length > 0) {
                setSelectedPolicyProduct(policyProduct[0])
            }
        } else {
            setSelectedPolicyProduct({})
        }
    }, [formData.insuranceProductId])

    useEffect(() => {
        getUserDetails(currentUser.uid)
    }, [currentUser])

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        if (customerId) {
            getCustomerDetails(customerId);
        }
    }, [customerId])


    const savePolicyDetails = async (data) => {
        // setIsLoading(true);
        // console.log(data)
        // return;
        let url = BASE_URL + "/api/policies/create";
        if (policyId) {
            url = BASE_URL + "/api/policies/update/" + policyId
        }
        setIsLoading(true);
        try {
            const response = await apiRequest({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setIsLoading(false);
            toast.success(`Successfully ${policyId ? 'updated' : 'created'} a Policy`, { position: "top-center" });
            logAction(
                SUCCESS_LOG,
                "policy creation",
                `Successfully ${policyId ? 'updated' : 'created'} policy for: ${customerDetails.name} by ${authentication.currentUser.displayName}`
            );

            if (authClaims.supervisor) {
                navigate("/supervisor/policy/all");
            } else if (authClaims.agent) {
                navigate("/agent/policy/all");
            } else if (authClaims.admin) {
                navigate("/admin/policy/all");
            } else if (authClaims.superadmin) {
                navigate("/superadmin/policy/all");
            }

        } catch (error) {
            setIsLoading(false)
            logAction(
                ERROR_LOG,
                "policy creation",
                `Failed to ${policyId ? 'update' : 'create'} policy: ${customerDetails.name} by ${authentication.currentUser.displayName}`,
                error.message
            );
            toast.error(`Error saving policy: ${error.message}`, { position: "top-center" });
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(selectedPolicyProduct)
        //validate dependents terms
        let invalidDependents = 0;
        for (let index = 0; index < dependentsData.length; index++) {
            const dependent = dependentsData[index];
            if (dependent.policyCovers.length == 0) {
                invalidDependents += 1;
            }
        }
        if (!formData.productType) {
            toast.error(`Please select a policy type`, { position: "top-center" });
            return;
        }

        if (!selectedPolicyProduct.id) {
            toast.error(`Please select a policy name`, { position: "top-center" });
            return;
        }
        if (formData.beneficiaryType == "customerAndDependents" || formData.beneficiaryType == "customerOnly") {
            if (formData.policyCovers.length == 0) {
                toast.error(`Please select the customer's policy cover`, { position: "top-center" });
                return;
            }
        }

        if (!formData.startDate) {
            toast.error(`Please select the start date`, { position: "top-center" });
            return;
        }

        if (formData.beneficiaryType == "customerAndDependents" || formData.beneficiaryType == "dependentsOnly") {
            if (invalidDependents > 0) {
                toast.error(`Each dependent must have at least one policy cover.`, { position: "top-center" });
                return;
            }
        }

        if (!customerDetails) {
            toast.error(`Please select a customer`, { position: "top-center" });
            return;
        }
        const data = {
            "customerId": !policyId ? parseInt(customerId) : policyDetails.customerId,
            "startDate": formData.startDate,
            "insuranceProductId": selectedPolicyProduct.id,
            "policyName": selectedPolicyProduct.policyName,
            "policyType": selectedPolicyProduct.policyType,
            "vat": selectedPolicyProduct.VatPercentage,
            "beneficiaryType": formData.beneficiaryType,
            "policyCovers": formData.policyCovers
        }
        const dependents = []
        for (let index = 0; index < dependentsData.length; index++) {
            const dependent = dependentsData[index];
            dependents.push({
                "dependentId": dependent.id,
                "insuranceProductId": selectedPolicyProduct.id,
                "policyName": selectedPolicyProduct.policyName,
                "policyType": selectedPolicyProduct.policyType,
                "vat": selectedPolicyProduct.VatPercentage,
                "policyCovers": dependent.policyCovers,
            })
        }
        data.dependents = dependents;
        await savePolicyDetails(data)
    }

    const onProductTypeChange = (e) => {
        setFormData({ ...formData, productType: e.target.value })
        if (policyId) {
            if (e.target.value != formData.productType) {
                // setFormData({ ...formData, policyCovers: [], insuranceProductId: null })
                setFormData({ ...formData, insuranceProductId: null })
                setSelectedPolicyProduct({})
            }
        }

    }

    return (
        <div className="components">
            <header className="tw-mx-5">
                <div className="nk-block-between">
                    <div className="header-txt">
                        <h1 className="tw-text-lg md:tw-text-4xl tw-font-bold">
                            {policyId ? 'Update Policy' : 'New Policy'}
                        </h1>
                        {/* <p className="tw-text-sm tw-text-gray-500">ADD A NEW POLICY</p> */}
                    </div>
                </div>
            </header>
            <div className=" tw-bg-white tw-shadow-lg tw-rounded-lg tw-mx-5 px-4 py-4  mb-5 shadow-sm tw-relative">
                {(isLoading || loadingPolicyDetails || loadingPolicyProducts) && (
                    <div className="loader-wrapper">
                        <CustomLoader message={"Please wait..."} />
                    </div>
                )}

                <div className=" mb-3">
                    {!policyId &&
                        <button
                            onClick={() => setClientsModalOpen(true)}
                            type="button" className="tw-bg-gray-100 tw-shadow hover:tw-bg-gray-200 tw-px-5  tw-rounded-lg py-2">
                            {loadingDetails ? 'Please wait.....' : " Get Insured Customer"}
                        </button>
                    }
                </div>
                <div className="tw-flex c-sm:tw-flex-col c-md:tw-flex-col c-lg:tw-flex-col  tw-gap-6 ">
                    <div className="tw-w-[55%] c-sm:tw-w-full c-md:tw-w-full c-lg:tw-w-full">
                        {/* <Form name="form" className="pt-0 px-0" onSubmit={handleSubmit}> */}
                        <div className="mb-3 tw-w-full tw-grid tw-grid-cols-2 tw-gap-3">
                            <Form.Group className=" ">
                                <Form.Label htmlFor="category"> Select Type </Form.Label>
                                <Form.Select
                                    value={formData.productType}
                                    onChange={(e) => onProductTypeChange(e)}
                                    required className=" tw-w-full tw-capitalize " aria-label="" id='policy_name' >
                                    <option value="">Select</option>
                                    {
                                        getUniquePolicyTypes(policyProducts)
                                            .map((item, key) => {
                                                if (authClaims.agent) {
                                                    if (userDetails.agent_handles) {
                                                        return userDetails.agent_handles[item] &&
                                                            <option key={key} value={item}>
                                                                {item}
                                                            </option>
                                                    }
                                                } else {
                                                    return <option key={key} value={item}>
                                                        {item}
                                                    </option>
                                                }
                                            })
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="">
                                <Form.Label htmlFor="category"> Select Policy</Form.Label>
                                <Form.Select
                                    value={formData.insuranceProductId ?? ""}
                                    onChange={(e) =>
                                        setFormData({ ...formData, insuranceProductId: e.target.value })
                                    }
                                    required className=" tw-w-full " aria-label="" id='policy_name' >
                                    <option value="">Select</option>
                                    {
                                        getPoliciesByType(policyProducts, formData.productType).map((item, key) =>
                                            <option key={key} value={item.id}>{item.policyName}</option>
                                        )
                                    }

                                </Form.Select>
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3 tw-w-full">
                            <Form.Label htmlFor="category">  Start Date </Form.Label>
                            <Form.Control
                                value={formData.startDate}
                                onChange={(e) =>
                                    setFormData({ ...formData, startDate: e.target.value })
                                }
                                type="date"
                                placeholder="Cost"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 tw-w-full ">
                            <Form.Label htmlFor="category">  Who are the beneficiaries? </Form.Label>
                            <Form.Select
                                value={formData.beneficiaryType}
                                onChange={(e) => setFormData({ ...formData, beneficiaryType: e.target.value })}
                                required className=" tw-w-full " aria-label="" id='policy_name' >
                                <option value="">Select</option>
                                <option value="customerAndDependents">Customer and Dependents</option>
                                <option value="customerOnly">Customer Only</option>
                                <option value="dependentsOnly">Dependents Only</option>
                            </Form.Select>
                        </Form.Group>

                        {
                            (formData.beneficiaryType == "customerAndDependents" || formData.beneficiaryType == "customerOnly") &&
                            <Form.Group className="mb-3 tw-w-full">
                                <Form.Label htmlFor="category" className=""> What the policy covers(customer)? </Form.Label>
                                <PolicyCoversSection
                                    initialValues={initialPolicyCovers}
                                    onSelectedCoversChange={(value) => setFormData({ ...formData, policyCovers: value })}
                                    componentId="customer_covers" selectedPolicyProduct={selectedPolicyProduct} />
                            </Form.Group>
                        }

                    </div>
                    <div className=" tw-flex-grow">
                        <div className="tw-min-h-[250px] tw-border tw-shadow-md tw-bg-opacity-70">
                            <div className=" tw-flex">
                                <div className="tw-flex tw-flex-col tw-gap-3 py-2 px-3 tw-flex-grow">

                                    <span> <span className=" tw-font-semibold">Names</span>: {customerDetails ? customerDetails.name : "N/A"}</span>
                                    <span> <span className=" tw-font-semibold">Customer No.</span>:  {customerDetails ? customerDetails.referenceNumber : "N/A"}</span>
                                    <span> <span className=" tw-font-semibold">Email</span>:  {customerDetails ? customerDetails.email : "N/A"}</span>
                                    <span> <span className=" tw-font-semibold">Phone</span>:  {customerDetails ? customerDetails.phone : "N/A"}</span>
                                    <span> <span className=" tw-font-semibold">NIN</span>:  {customerDetails ? customerDetails.nin : "N/A"}</span>
                                    <span> <span className=" tw-font-semibold">DOB</span>:  {customerDetails ? customerDetails.dob : "N/A"}</span>
                                    <span> <span className=" tw-font-semibold">Gender</span>:  {customerDetails ? customerDetails.gender : "N/A"}</span>
                                </div>
                                <div>
                                    {
                                        loadingDetails &&
                                        <AppSpinner TopClass={"tw-w-full tw-flex tw-justify-center tw-pt-2"} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            {
                                (formData.beneficiaryType == "customerAndDependents" || formData.beneficiaryType == "dependentsOnly") &&
                                <DependentsSection
                                    setDependentsData={(value) => setDependentsData(value)}
                                    productType={formData.productType}
                                    customerDetails={customerDetails}
                                    selectedPolicyProduct={selectedPolicyProduct}
                                    policyDetails={policyDetails}
                                />
                            }

                        </div>
                    </div>
                </div>

                <div className=" tw-flex tw-justify-end tw-mt-8">
                    <button className="btn btn-dark cta submitcta tw-px-10" onClick={handleSubmit}>Submit Policy</button>
                </div>

            </div>



            <ClientsModal onClose={() => setClientsModalOpen(false)} isOpen={clientsModalOpen} />
        </div>
    )
}


export default NewPolicy;


const DependentsSection = ({
    productType,
    customerDetails,
    selectedPolicyProduct,
    setDependentsData,
    policyDetails
}) => {
    const [showDependentsModal, setShowDependentsModal] = useState(false);
    const [customerDependents, setCustomerDependents] = useState([])
    const [selectedDependentsData, setSelectedDependentsData] = useState([])

    useEffect(() => {
        if (policyDetails) {
            const policyBeneficiaryDependents = policyDetails.PolicyBeneficiary.filter((item) => item.dependentId != null)
            if (policyBeneficiaryDependents.length > 0) {
                const dependents = policyBeneficiaryDependents.map((item) => {
                    const dependent = item.Dependent;
                    dependent.initialPolicyCovers = item.PolicyCover.map((item) => item.ProductCoverage)
                    return dependent;
                })
                setSelectedDependentsData(dependents)
                setCustomerDependents(dependents)
            }
        }

    }, [policyDetails])

    const addDependentRecord = () => {
        if (!productType) {
            toast.error(`Please choose a product`, { position: "top-center" });
            return
        }
        if (!customerDetails) {
            toast.error(`Please get the customer details to continue`, { position: "top-center" });
            return
        }
        setShowDependentsModal(true)
    }
    useEffect(() => {
        for (let index = 0; index < selectedDependentsData.length; index++) {
            setSelectedCovers([], index)
        }
    }, [productType])

    useEffect(() => {
        setDependentsData(selectedDependentsData)
    }, [selectedDependentsData])

    const setSelectedCovers = (selectedValues, index) => {
        setSelectedDependentsData((prevDependents) => {
            const updatedDependents = prevDependents.map((dependent, i) => {
                if (i === index) {
                    return {
                        ...dependent,
                        policyCovers: selectedValues,
                    };
                }
                return dependent;
            });

            return updatedDependents;
        });
    };

    const removeDependentByIndex = (index) => {
        setSelectedDependentsData((prevDependents) => {
            // Create a new array without the item at the specified index
            const updatedDependents = [...prevDependents.slice(0, index), ...prevDependents.slice(index + 1)];
            return updatedDependents;
        });

        setCustomerDependents((prevDependents) => {
            // Create a new array without the item at the specified index
            const updatedDependents = [...prevDependents.slice(0, index), ...prevDependents.slice(index + 1)];
            return updatedDependents;
        });
    };

    return (
        <>
            <div className=" tw-flex tw-justify-end">
                <button
                    onClick={() => addDependentRecord()}
                    type="button"
                    className="btn-green"> Add Dependent</button>

            </div>

            <div className=" tw-flex tw-flex-col tw-gap-4 mt-2">
                {
                    customerDependents.map((item, key) =>
                        <div key={key} className="tw-bg-gray-50 tw-border p-2 tw-flex">
                            <div className="tw-flex tw-flex-col tw-gap-1 py-2 px-3 tw-flex-grow">
                                <span> <span className=" tw-font-semibold">Names</span>: {item.name}</span>
                                <span> <span className=" tw-font-semibold">DOB</span>: {item.dob}</span>
                                <span> <span className=" tw-font-semibold">Gender</span>: {item.gender}</span>
                                <Form.Group className="mb-3 tw-w-full">
                                    <Form.Label htmlFor="category" className=" tw-font-semibold"> What the policy covers(dependent)? </Form.Label>
                                    <PolicyCoversSection
                                        initialValues={item.initialPolicyCovers}
                                        onSelectedCoversChange={(values) => setSelectedCovers(values, key)}
                                        componentId={"dependent_covers_" + key} selectedPolicyProduct={selectedPolicyProduct} />
                                </Form.Group>
                            </div>
                            <FontAwesomeIcon className=" tw-cursor-pointer tw-text-red-600" onClick={() => removeDependentByIndex(key)} icon={faTrashAlt} />
                        </div>
                    )
                }
            </div>

            <DependentsModal
                customerDetails={customerDetails}
                dependentRecordSelected={(value) => {
                    setSelectedDependentsData([...selectedDependentsData, value]);
                    setCustomerDependents([...customerDependents, { ...value, initialPolicyCovers: [] }])
                    setShowDependentsModal(false);
                }}
                isOpen={showDependentsModal}
                onClose={() => setShowDependentsModal(false)} />
        </>
    )
}


const PolicyCoversSection = ({ selectedPolicyProduct, componentId, onSelectedCoversChange, initialValues }) => {
    const [selectedCovers, setSelectedCovers] = useState([]);
    const [productCoverage, SetProductCoverage] = useState([])

    useEffect(() => {
        if (selectedPolicyProduct.ProductCoverage) {
            SetProductCoverage(selectedPolicyProduct.ProductCoverage)
        }

        if (selectedCovers.length > 0) {
            if (selectedCovers[0].insuranceProductId != selectedPolicyProduct.id) {
                setSelectedCovers([])
            }
        }
    }, [selectedPolicyProduct])


    // useEffect(() => {
    //     if (selectedPolicyProduct.ProductCoverage) {
    //         SetProductCoverage(selectedPolicyProduct.ProductCoverage)
    //     }
    //     setSelectedCovers(initialValues)

    // }, [initialValues, selectedPolicyProduct])

    useEffect(() => {
        setSelectedCovers(initialValues)
    }, [initialValues])

    useEffect(() => {
        onSelectedCoversChange(selectedCovers)
    }, [selectedCovers])

    const handleCheckboxChange = (item) => {
        const isSelected = selectedCovers.some((selectedItem) => selectedItem.id === item.id);
        if (isSelected) {
            setSelectedCovers((prevSelected) => prevSelected.filter((selectedItem) => selectedItem.id !== item.id));
        } else {
            setSelectedCovers((prevSelected) => [...prevSelected, item]);
        }
    };
    return (
        <>
            {

                <div className="tw-bg-gray-100  tw-bg-opacity-70  tw-rounded-md p-3 tw-flex tw-flex-col tw-gap-2">
                    {
                        productCoverage.map((item, key) =>
                            <div key={key} className="tw-flex tw-items-center tw-gap-2 ">
                                < Form.Group className="tw-mb-2" controlId="cover">
                                    <Form.Check
                                        id={`${componentId}_${key}`}
                                        type="checkbox"
                                        checked={selectedCovers.some((selectedItem) => selectedItem.id === item.id)}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                </Form.Group>
                                <Form.Label className=' tw-flex tw-flex-col tw-justify-center' htmlFor={`${componentId}_${key}`} >
                                    <p> {item.cover}, Cost:{item.cost.toLocaleString()}, Limit:{item.limit.toLocaleString()}</p>
                                </Form.Label>
                            </div>
                        )
                    }
                </div>
            }
        </>
    )
}




const ClientsModal = ({
    isOpen,
    onClose,
    inviteSent
}) => {
    const { customers, loadingMore, hasMore, loadMore, fetchCustomers, searchCustomers } = useCustomersRequest()
    const [searchText, setSearchText] = useState("")
    const navigate = useNavigate()
    const { authClaims } = useAuth();


    useEffect(() => {
        fetchCustomers()
    }, [])

    const onInputChange = (e) => {
        const newValue = e.target.value;
        setSearchText(newValue);
        // Call the handleSearch function after a delay
        searchCustomers(newValue);
    };

    return (
        <>
            {(isOpen) &&
                <div className="tw-modal">
                    <div className=" modal-content modal-lg tw-px-6">
                        <div className=" tw-flex  py-1">
                            <div className=" tw-flex-grow tw-flex tw-flex-col tw-justify-center">
                                <p className="tw-text-lg tw-font-semibold">Customer Details</p>
                            </div>
                            <div>
                                <FontAwesomeIcon onClick={() => onClose()} className=" tw-cursor-pointer" size="lg" icon={faClose} />
                            </div>
                        </div>
                        <div className=" tw-flex tw-justify-start tw-mt-4 tw-border-t pt-4">
                            <div className="tw-w-1/2" id="search">
                                <SearchBar
                                    placeholder={"Search by Name or NIN"}
                                    value={searchText}
                                    handleSearch={onInputChange}
                                />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
                            </div>
                            <div className=" tw-w-1/2 tw-flex tw-justify-end">
                                <Link to={
                                    (authClaims.supervisor && "/supervisor/add-clients") ||
                                    (authClaims.agent && "/agent/add-clients")
                                }>
                                    <button className="btn cta ">New Customer</button>
                                </Link>
                            </div>
                        </div>
                        <Table hover striped responsive className="mt-4" id="myTable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Customer REF</th>
                                    {/* <th>Email</th> */}
                                    <th>Gender</th>
                                    <th>Phone No.</th>
                                    <th>NIN</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customers.map((item, key) =>
                                        <tr key={key}>
                                            <td>{item.name}</td>
                                            <td>{item.referenceNumber ?? "N/A"}</td>
                                            {/* <td>{item.email}</td> */}
                                            <td>{item.gender}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.nin}</td>
                                            <td>
                                                <Link to={
                                                    (authClaims.supervisor && `/supervisor/new-policy/${item.id}`) ||
                                                    (authClaims.agent && `/agent/new-policy/${item.id}`)
                                                }>
                                                    <span
                                                        onClick={() => onClose()}
                                                        className=" tw-text-blue-700 tw-cursor-pointer" >choose</span>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>

                        {
                            loadingMore &&
                            <AppSpinner />
                        }
                        {
                            (hasMore && loadingMore === false) &&
                            <div className=" tw-flex tw-justify-center my-3">
                                <button onClick={() => loadMore()} className=" tw-bg-gray-100 tw-shadow hover:tw-bg-gray-200 tw-px-5  tw-rounded-lg py-2"> <FontAwesomeIcon icon={faRefresh} /> Load more</button>
                            </div>
                        }
                    </div>
                </div>
            }

        </>
    )
}


export const getUniquePolicyTypes = (data) => {
    const uniquePolicyNames = new Set();

    data.forEach(policy => {
        uniquePolicyNames.add(policy.policyType);
    });

    return Array.from(uniquePolicyNames);
}

export const getPoliciesByType = (policyProducts, type) => {
    return policyProducts.filter((item) => item.policyType == type)
}

import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import { useState } from "react";

const useUserRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userOrganisation, setUserOrganisation] = useState(null)
    const [userDetails, setUserDetails] = useState(null)

    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
   
    const fetchUserOrganisation = async () => {
        setIsLoading(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/user/organisation`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setUserOrganisation(response.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    
    const fetchUserDetails = async (uid) => {
        setIsLoading(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/user/details/${uid}`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setUserDetails(response.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return {
        userOrganisation,
        isLoading,
        fetchUserOrganisation,
        fetchUserDetails,
        userDetails
    }
}

export default useUserRequest;
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTimesCircle } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";


const PaymentStatusModal = (props) => {

    return (
        <>
            {(props.isOpen) &&
                <div className="tw-modal ">
                    <div className=" modal-content modal-md tw-px-6 ">
                        <div className=" tw-flex tw-justify-end">
                            <div>
                                <FontAwesomeIcon onClick={() => props.onClose()} className=" tw-cursor-pointer tw-font-medium" size="lg" icon={faClose} />
                            </div>
                        </div>
                        {
                            props.status == "successful" ?
                                <div className="tw-py-3">
                                    <div className=" tw-flex tw-justify-center">
                                        <span className=" tw-text-5xl tw-text-center tw-text-green-600"> <FiCheckCircle /></span>
                                    </div>
                                    <p className=" tw-text-center tw-text-xl tw-font-semibold">Payment Success</p>
                                </div> :
                                <div className="tw-py-3">
                                    <div className=" tw-flex tw-justify-center">
                                        <span className=" tw-text-5xl tw-text-center tw-text-red-600"> <FaTimesCircle /></span>
                                    </div>
                                    <p className=" tw-text-center tw-text-xl tw-font-semibold">Payment Failed</p>
                                </div>
                        }

                    </div>
                </div>
            }

        </>
    )
}

export default PaymentStatusModal;
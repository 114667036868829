import useAuth from "contexts/Auth";
import { BASE_URL } from "helpers/constants";
import useApiRequest from "hooks/apiRequest";
import { useState } from "react";

const useReportsRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { authClaims } = useAuth();
    const [products, setProducts] = useState([])
    const apiRequest = useApiRequest();
    const { currentUser } = useAuth();
    const [dashboardStats, setDashboardStats] = useState({})
    const fetchDashboardStats = async () => {
        setIsLoading(true);
        try {
            const response = await apiRequest({
                method: 'get',
                url: `${BASE_URL}/api/report/dashboard-stats`,
                headers: {
                    Authorization: `Bearer ${currentUser.accessToken}`,
                },
            });
            setDashboardStats(response.data)
            console.log(response.data)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    return {
        dashboardStats,
        isLoading,
        fetchDashboardStats
    }
}

export default useReportsRequest;
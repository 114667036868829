import { faClose, faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AppButton from "components/AppButton"
import AppSpinner from "components/AppSpinner"
import SearchBar from "components/searchBar/SearchBar"
import { BASE_URL } from "helpers/constants"
import { formatBeneficiaryNames, getTotalLimit, getTotalPremium } from "pages/ManagePolicies/ListPolicy"
import { Table } from "react-bootstrap"

const { default: useCustomerPolicyRequest } = require("hooks/requests/useCustomerPolicyRequest")
const { useEffect, useState } = require("react")

const PolicyModal = ({
    isOpen,
    onClose,
    policySelected
}) => {
    const [searchText, setSearchText] = useState("")
    const [lastSearchText, setLastSearchText] = useState("")

    const { policies, fetchPolicies, loadingMore: isSearching, hasMore, loadMore, clearData } = useCustomerPolicyRequest()
    const searchPolicies = () => {
        fetchPolicies(searchText, true)
        setLastSearchText(searchText)
    }

    const searchAgain = () => {
        setSearchText("")
        setLastSearchText("")
        clearData()
    }


    return (
        <>
            {(isOpen) &&
                <div className="tw-modal">
                    <div className={` modal-content tw-min-h-[300px] ${policies.length>0?'modal-xl':'modal-lg'}  tw-px-6`}>
                        <div className=" tw-flex  py-1">
                            <div className=" tw-flex-grow tw-flex tw-flex-col tw-justify-center">
                                <p className="tw-text-lg tw-font-semibold tw-uppercase">Find customer policies</p>
                            </div>
                            <div>
                                <FontAwesomeIcon onClick={() => onClose()} className=" tw-cursor-pointer" size="lg" icon={faClose} />
                            </div>
                        </div>

                        {policies.length == 0 &&
                            <div className=" tw-flex tw-justify- tw-mt-5">
                                <div className="tw-w-2/3 tw-flex tw-gap-2" id="search">
                                    <div className=" tw-flex-grow">
                                        <SearchBar
                                            placeholder={"Search by Customer No. or Policy No. "}
                                            value={searchText}
                                            handleSearch={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <AppButton buttonType={"button"} callBackFun={() => searchPolicies()} showLoader={isSearching}
                                            spinnerClass={"tw-inline tw-w-3 tw-h-3 tw-mr-2 tw-white tw-animate-spin tw-fill-black"}
                                            className="btn btn-dark cta submitcta px-4  " text="Search" />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !isSearching && policies.length == 0 && lastSearchText &&
                            <p className="mt-2 tw-text-sm tw-text-gray-600">No Search results matching "<b>{lastSearchText} </b>" </p>
                        }
                        {
                            policies.length > 0 &&
                            <div className=" tw-mt-3">
                                <p>{policies.length} Result{policies.length > 1 && 's'} matching the query "{searchText}" <span onClick={() => searchAgain()} className=" tw-font-bold tw-underline tw-cursor-pointer "> Search Again? </span> </p>
                                <Table hover striped responsive className="mt-2" id="myTable">
                                    <thead>
                                        <tr>
                                            <th>Customer Names</th>
                                            <th>Policy No.</th>
                                            <th>Policy Name</th>
                                            <th>Start Date</th>
                                            <th>Total Premium</th>
                                            <th>Total Limit</th>
                                            <th>Beneficiaries</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            policies.map((item, key) =>
                                                <tr key={key}>
                                                    <td>{item?.Customer?.name}</td>
                                                    <td>{item.policyNumber ?? "N/A"}</td>
                                                    <td>{item.policyName}</td>
                                                    <td>{item.startDate}</td>
                                                    <td>{getTotalPremium(item.PolicyCover).toLocaleString()}</td>
                                                    <td>{getTotalLimit(item.PolicyCover).toLocaleString()}</td>
                                                    <td>
                                                        {
                                                            formatBeneficiaryNames(item.PolicyBeneficiary)
                                                        }
                                                    </td>
                                                    <td>
                                                        <span
                                                            onClick={() => policySelected(item)}
                                                            className=" tw-text-blue-700 tw-cursor-pointer" >choose</span>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>

                                {
                                    isSearching &&
                                    <AppSpinner />
                                }
                                {
                                    hasMore &&
                                    <div className=" tw-flex tw-justify-center my-3">
                                        <button type="button" onClick={() => loadMore()} className=" tw-bg-gray-100 tw-shadow hover:tw-bg-gray-200 tw-px-5  tw-rounded-lg py-2"> <FontAwesomeIcon icon={faRefresh} /> Load more</button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            }

        </>
    )
}

export default PolicyModal
import html2canvas from 'html2canvas';
import { useRef } from 'react';
import logo from "../../assets/imgs/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const PolicyCard = ({
    isOpen,
    onClose,
    selectedPolicy,
    Member
}) => {
    const cardRef = useRef(null);

    const handleDownload = () => {
        if (cardRef.current) {
            html2canvas(cardRef.current).then((canvas) => {
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download =Member.name+'.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    };

    return (

        <>
            {
                (isOpen && selectedPolicy && Member) &&
                <div className="tw-modal">
                    <div className="modal-content modal-md tw-px-6 tw-pb-10">
                        <div className=" tw-flex tw-justify-end">
                            <div>
                                <FontAwesomeIcon onClick={() => onClose()} className=" tw-cursor-pointer" size="lg" icon={faClose} />
                            </div>
                        </div>
                        <div className='tw-flex tw-justify-center'>
                            <div>
                                <h2 className=' tw-text-xl tw-font-medium tw-text-center mb-2'>Generate Insurance Card</h2>
                                <div ref={cardRef} className="id-card tw-border tw-border-gray-200 tw-rounded-lg tw-bg-white tw-flex tw-flex-col">
                                    <div className=' tw-px-3 py-3'>
                                        <img src={logo} width={100} alt="CORE" />
                                    </div>
                                    <div className=''>
                                        <hr />
                                    </div>
                                    <div className=' tw-font-medium tw-flex tw-flex-col tw-gap-1 tw-px-3 py-2'>
                                        <p className=' tw-grid tw-grid-cols-3'><span>Member Name:</span> <span>{Member.name}</span></p>
                                        <p className=' tw-grid tw-grid-cols-3'><span>Member Number:</span> <span>{selectedPolicy.policyNumber}</span></p>
                                        <p className=' tw-grid tw-grid-cols-3'><span>Policy Name:</span> <span>{selectedPolicy.policyName}</span></p>
                                        <p className=' tw-grid tw-grid-cols-3'><span>Effective Date:</span> <span>{selectedPolicy.startDate}</span></p>
                                    </div>
                                    <div className=' tw-flex-grow tw-bg-[#84c347] tw-rounded-b-lg tw-text-white tw-px-3 tw-py-1'>
                                        <p className=''><span>Inquiry:</span> <span>0393252960</span></p>
                                        <p>For electronic use. Do not bend card, cut or pierce</p>
                                    </div>
                                </div>
                                <div className=' mt-4 tw-flex tw-justify-center'>
                                    <button className='btn cta' onClick={handleDownload}>Download Card</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    );
};

export default PolicyCard;